import { TActionCreator } from 'store/TActionCreator';

export const FIRE_RESET_PASSWORD = 'FIRE_RESET_PASSWORD';

export const fireResetPassword: TActionCreator<
  typeof FIRE_RESET_PASSWORD,
  { password: string; repeatPassword: string }
> = (payload) => ({
  type: FIRE_RESET_PASSWORD,
  payload,
});
