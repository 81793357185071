import { TUserState } from 'store/reducers/user/TUserState';
import { changePasswordError } from 'store/actions/user/changePasswordError';

const changePasswordErrorHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof changePasswordError>,
): TUserState => {
  return {
    ...state,
    changePasswordError: payload.error,
  };
};

export default changePasswordErrorHandler;
