import { TActionCreator } from 'store/TActionCreator';
import { TDialogue } from 'store/reducers/chat/TDialogue';

export const SUCCESS_LOAD_DIALOGUES = 'SUCCESS_LOAD_DIALOGUES';

export const successLoadDialogues: TActionCreator<
  typeof SUCCESS_LOAD_DIALOGUES,
  { dialogues: TDialogue[] }
> = (payload) => ({
  type: SUCCESS_LOAD_DIALOGUES,
  payload,
});
