import apiRequest from 'libs/apiRequest';

import cleanPayload from './cleaner/cleanPayload';
import { TPayload } from './types/TPayload';

const apiFetch = async <TRawOkayPayloadData, TOkayPayloadData>(
  cleaner: (data: TRawOkayPayloadData) => TOkayPayloadData,
  input: RequestInfo,
  init?: RequestInit,
): Promise<TPayload<TOkayPayloadData> | never> => {
  const response = await apiRequest<TRawOkayPayloadData>(input, init);

  return cleanPayload<TRawOkayPayloadData, TOkayPayloadData>(response, cleaner);
};

export default apiFetch;
