import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TSaveProfileParameters } from 'api/profile/saveProfile/types/TSaveProfileParameters';
import { format } from 'date-fns';

const saveProfileFetch = async ({
  profile,
}: TSaveProfileParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/users`);
  const body = new FormData();
  body.append('first_name', profile.firstName);
  if (profile.lastName) {
    body.append('last_name', profile.lastName);
  }
  if (profile.gender) {
    body.append('gender', profile.gender.toString());
  }
  if (profile.birthDate) {
    body.append('birth', format(profile.birthDate, 'dd-MM-yyyy'));
  }
  body.append('email', profile.email);
  body.append('additional_contact_name', profile.additionalContactName || '');
  body.append('additional_contact_phone', profile.additionalContactPhone || '');
  if (profile.newAvatar) {
    body.append('avatar', profile.newAvatar.data);
  }
  body.append('address_q', profile.address);
  body.append(
    'timezone',
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/Moscow',
  );
  body.append('_method', 'PUT');

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body,
  });
};

export default saveProfileFetch;
