import { TActionCreator } from 'store/TActionCreator';
import { TSitter } from 'store/reducers/client/TSitter';

export const SUCCESS_SEARCH_SITTER = 'SUCCESS_SEARCH_SITTER';

export const successSearchSitter: TActionCreator<
  typeof SUCCESS_SEARCH_SITTER,
  { list: TSitter[]; currentPage: number; totalPages: number }
> = (payload) => ({
  type: SUCCESS_SEARCH_SITTER,
  payload,
});
