import { TMainState } from 'store/reducers/main/TMainState';

const requestFailHandler = (state: TMainState): TMainState => {
  return {
    ...state,
    requestFailed: true,
  };
};

export default requestFailHandler;
