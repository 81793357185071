import { TUserState } from 'store/reducers/user/TUserState';
import { successCheckPhone } from 'store/actions/user/successCheckPhone';

const successCheckPhoneHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof successCheckPhone>,
): TUserState => {
  return {
    ...state,
    exists: payload.exists,
    loginError: null,
    correctResetCode: null,
    correctCode: null,
  };
};

export default successCheckPhoneHandler;
