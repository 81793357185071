import { TActionCreator } from 'store/TActionCreator';
import { TIdentity } from 'store/reducers/sitter/TIdentity';

export const SET_BECOME_SITTER_IDENTITY = 'SET_BECOME_SITTER_IDENTITY';

export const setBecomeSitterIdentity: TActionCreator<
  typeof SET_BECOME_SITTER_IDENTITY,
  { identity: TIdentity }
> = (payload) => ({
  type: SET_BECOME_SITTER_IDENTITY,
  payload,
});
