import { setCurrentDialogue } from 'store/actions/chat/setCurrentDialogue';

function setCurrentDialogueSaga({
  payload,
}: ReturnType<typeof setCurrentDialogue>): void {
  try {
    if (!payload.historyPush) {
      return;
    }

    if (payload.dialogueId) {
      payload.historyPush(`/cabinet/chat?dialogue=${payload.dialogueId}`);
    } else {
      payload.historyPush('/cabinet/chat');
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default setCurrentDialogueSaga;
