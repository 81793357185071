import { TUserState } from 'store/reducers/user/TUserState';

const fireResetPasswordHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    resetPasswordError: null,
  };
};

export default fireResetPasswordHandler;
