import { TClientState } from 'store/reducers/client/TClientState';
import { successSearchSitter } from 'store/actions/client/successSearchSitter';

const successSearchSitterHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successSearchSitter>,
): TClientState => ({
  ...state,
  sittersList: payload.list,
  searchCurrentPage: payload.currentPage,
  searchTotalPages: payload.totalPages,
});

export default successSearchSitterHandler;
