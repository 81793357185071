import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TSavePetPayload } from './types/TSavePetPayload';
import savePetCleaner from './savePetCleaner';
import { TSavePetParameters } from './types/TSavePetParameters';

const savePetFetch = async ({
  pet,
}: TSavePetParameters): Promise<TSavePetPayload | never> => {
  let url = new URL(`${API_URL}/pets`);
  if (pet.id) {
    url = new URL(`${API_URL}/pets/${pet.id}`);
  }
  const body = new FormData();
  body.append('type_id', pet.type.toString());
  body.append('name', pet.name);
  body.append('breed_id', pet.breed.toString());
  body.append('gender', pet.gender.toString());
  body.append('year', pet.year ? pet.year.toString() : '0');
  body.append('month', pet.month ? pet.month.toString() : '0');
  body.append('sterilized', pet.sterilized.toString());
  body.append('staying_home_alone', pet.stayingHomeAlone.toString());
  body.append('kids_friendly', pet.kidsFriendly.toString());
  body.append('dogs_friendly', pet.dogsFriendly.toString());
  body.append('cats_friendly', pet.catsFriendly.toString());
  body.append('size_id', pet.size.toString());
  body.append('info_for_walking', pet.infoForWalking);
  body.append('info_for_sitting', pet.infoForSitting);
  if (pet.id) {
    body.append('_method', 'PATCH');
    if (pet.avatarId) {
      body.append('avatar_id', pet.avatarId.toString());
    }
  }

  return apiFetch(savePetCleaner, url.toString(), {
    method: 'POST',
    body,
  });
};

export default savePetFetch;
