import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TSendRequestParameters } from './types/TSendRequestParameters';
import { TSendRequestPayload } from './types/TSendRequestPayload';

const sendRequestFetch = async ({
  userId,
  orderId,
}: TSendRequestParameters): Promise<TSendRequestPayload | never> => {
  const url = new URL(`${API_URL}/orders/requests`);
  const body: any = {
    user_id: userId,
    order_id: orderId,
    type: 1,
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default sendRequestFetch;
