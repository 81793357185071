import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadSitterPayload } from './types/TLoadSitterPayload';
import loadSitterCleaner from './loadSitterCleaner';
import { TLoadSitterParameters } from './types/TLoadSitterParameters';

const loadSitterFetch = async ({
  id,
}: TLoadSitterParameters): Promise<TLoadSitterPayload | never> => {
  const url = new URL(`${API_URL}/users/profile/${id}`);

  return apiFetch(loadSitterCleaner, url.toString());
};

export default loadSitterFetch;
