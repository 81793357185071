import { TActionCreator } from 'store/TActionCreator';

export const SET_BECOME_SITTER_MESSAGE_FROM_ADMIN =
  'SET_BECOME_SITTER_MESSAGE_FROM_ADMIN';

export const setBecomeSitterMessageFromAdmin: TActionCreator<
  typeof SET_BECOME_SITTER_MESSAGE_FROM_ADMIN,
  { message: string }
> = (payload) => ({
  type: SET_BECOME_SITTER_MESSAGE_FROM_ADMIN,
  payload,
});
