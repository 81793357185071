import { TChatState } from 'store/reducers/chat/TChatState';
import { EMessageStatus } from 'store/reducers/chat/TMessage';
import { successSendMessage } from 'store/actions/chat/successSendMessage';

const successSendMessageHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof successSendMessage>,
): TChatState => {
  const { dialogues } = state;
  const dialogueIndex = dialogues.findIndex(
    (dialogue) => payload.dialogueId === dialogue.id,
  );
  if (dialogueIndex === -1) {
    return state;
  }
  const dialogue = dialogues[dialogueIndex];

  const messageIndex = dialogue.messages.findIndex(
    (message) => message.date.getTime() === payload.date.getTime(),
  );
  if (messageIndex === -1) {
    return state;
  }
  const message = dialogue.messages[messageIndex];

  return {
    ...state,
    dialogues: [
      ...dialogues.slice(0, dialogueIndex),
      {
        ...dialogue,
        messages: [
          ...dialogue.messages.slice(0, messageIndex),
          {
            ...message,
            status: EMessageStatus.Sent,
            date: payload.realDate,
            id: payload.id,
          },
          ...dialogue.messages.slice(messageIndex + 1),
        ],
      },
      ...dialogues.slice(dialogueIndex + 1),
    ],
  };
};

export default successSendMessageHandler;
