import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TBecomeSitterServicesSaveParameters } from './types/TBecomeSitterServicesSaveParameters';

const becomeSitterServicesSaveFetch = async ({
  walker,
  sitter,
}: TBecomeSitterServicesSaveParameters): Promise<TPayload<unknown> | never> => {
  const url = new URL(`${API_URL}/worker/create`);
  const body = {
    sitter: Number(sitter),
    walker: Number(walker),
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default becomeSitterServicesSaveFetch;
