import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setBecomeSitterProfile } from 'store/actions/sitter/setBecomeSiiterProfile';

const setBecomeSitterProfileHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setBecomeSitterProfile>,
): TSitterState => {
  return {
    ...state,
    becomeSitterProfile: payload.profile,
  };
};

export default setBecomeSitterProfileHandler;
