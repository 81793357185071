import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';

import EResponseType from 'api/enums/EResponseType';
import { fireCancelSitterRequest } from 'store/actions/sitter/fireCancelSitterRequest';
import cancelRequestFetch from 'api/sitter/cancelRequest/cancelRequestFetch';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { successCancelSitterRequest } from 'store/actions/sitter/successCancelSitterRequest';

function* cancelSitterRequestSaga({
  payload,
}: ReturnType<typeof fireCancelSitterRequest>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: cancelRequestFetch,
      payload,
    });
    if (response.type === EResponseType.OK) {
      toast.info(getLang('SITTER_REQUEST_CANCEL_SUCCESS'));
      yield put(successCancelSitterRequest(payload));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default cancelSitterRequestSaga;
