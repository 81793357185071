import { TUserState } from 'store/reducers/user/TUserState';

const cancelForgotPasswordHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    forgotPassword: false,
  };
};

export default cancelForgotPasswordHandler;
