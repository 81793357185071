import { TClientState } from 'store/reducers/client/TClientState';
import { successLoadOrders } from 'store/actions/client/successLoadOrders';

const successLoadOrdersHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successLoadOrders>,
): TClientState => ({
  ...state,
  orders: payload.orders,
});

export default successLoadOrdersHandler;
