import { TAppState } from 'store/TAppState';
import { TDialogueInfo } from 'store/reducers/chat/TDialogueInfo';
import { EMessageStatus } from 'store/reducers/chat/TMessage';

const selectContacts = (state: TAppState): TDialogueInfo[] => {
  return Object.keys(state.chat.dialogues).map((dialogueId) => {
    const dialogue = state.chat.dialogues[Number(dialogueId)];
    const profileId = state.profile.profile?.id;
    const lastMessage =
      dialogue.messages.length > 0
        ? dialogue.messages[dialogue.messages.length - 1]
        : null;

    return {
      id: dialogue.id,
      name: dialogue.name,
      image: dialogue.image,
      type: dialogue.type,
      lastMessage,
      unreadMessagesCount: dialogue.messages.filter(
        (message) =>
          message.status === EMessageStatus.Sent &&
          message.ownerId !== profileId,
      ).length,
    };
  });
};

export default selectContacts;
