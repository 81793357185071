enum EBecomeSitterStep {
  Services = 'SERVICES',
  ServicesOptions = 'SERVICES_OPTIONS',
  Identity = 'IDENTITY',
  Moderation = 'MODERATION',
  ModerationFailed = 'MODERATION_FAILED',
  ModerationAgain = 'MODERATION_AGAIN',
  ModerationSuccess = 'MODERATION_SUCCESS',
  Profile = 'PROFILE',
  Test = 'TEST',
  Complete = 'COMPLETE',
}

export default EBecomeSitterStep;
