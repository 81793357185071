import { TClientState } from 'store/reducers/client/TClientState';
import { fireDeletePetPhoto } from 'store/actions/client/fireDeletePetPhoto';

const fireDeletePetPhotoHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof fireDeletePetPhoto>,
): TClientState => {
  if (!state.pets) {
    return state;
  }

  const petIndex = state.pets.findIndex((pet) => pet.id === payload.petId);
  const pet = state.pets[petIndex];

  return {
    ...state,
    pets: [
      ...state.pets.slice(0, petIndex),
      {
        ...pet,
        photos: pet.photos.filter((photo) => photo.id !== payload.id),
      },
      ...state.pets.slice(petIndex + 1),
    ],
  };
};

export default fireDeletePetPhotoHandler;
