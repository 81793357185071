// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./colors.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Avenir);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n}\n\nbody {\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n        \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n        sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n* {\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\nbody>#root {\n    width: 100%;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/styles/app.css"],"names":[],"mappings":"AAIA;;IAEI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;AAEA;IACI;;kBAEc;IACd,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["@import './colors.css';\n@import './fonts.css';\n@import url('https://fonts.googleapis.com/css?family=Avenir');\n\nhtml,\nbody {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n}\n\nbody {\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n        \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n        sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n* {\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\nbody>#root {\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
