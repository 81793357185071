import { EDialogueType } from 'store/reducers/chat/TDialogue';
import { EMessageStatus, TMessage } from 'store/reducers/chat/TMessage';
import { isBefore } from 'date-fns';
import { EOrderStatus, EOrderType, TOrder } from 'store/reducers/orders/TOrder';

import { TRawDialogue, TRawMessage, TRawOkayPayloadData, TRawOrder, } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

export const cleanMessage = (rawMessage: TRawMessage): TMessage => ({
  id: rawMessage.id.toString(),
  date: new Date(rawMessage.created_at),
  text: rawMessage.message,
  ownerId: rawMessage.owner_id,
  status: rawMessage.read ? EMessageStatus.Read : EMessageStatus.Sent,
  media: rawMessage.gallery.map((rawMedia) => ({
    name: rawMedia.src,
    type: rawMedia.type,
    preview: rawMedia.preview,
    data: rawMedia.src,
  })),
});

export const cleanOrder = (
  rawOrder: TRawOrder | null,
  userId: number,
  rawClient: TRawDialogue['client'],
  rawWorker: TRawDialogue['worker'],
): TOrder | undefined => {
  if (rawOrder === null) {
    return undefined;
  }
  let status = EOrderStatus.New;
  // eslint-disable-next-line default-case
  switch (rawOrder.status) {
    case 1:
      status = EOrderStatus.New;
      break;
    case 2:
      status = EOrderStatus.Cancelled;
      break;
    case 3:
      status = EOrderStatus.Approved;
      break;
    case 4:
      status = EOrderStatus.Paid;
      break;
    case 5:
      status = EOrderStatus.Finished;
      break;
  }
  const type =
    rawOrder.type === 'sitter' ? EOrderType.Sitter : EOrderType.Walker;
  const isClient = rawOrder.worker_id !== userId;

  return {
    id: rawOrder.id,
    price: rawOrder.amount,
    status,
    type,
    startDate:
      type === EOrderType.Sitter
        ? new Date(rawOrder.dates[0].start_at)
        : undefined,
    endDate:
      type === EOrderType.Sitter
        ? new Date(rawOrder.dates[0].end_at)
        : undefined,
    dates:
      type === EOrderType.Walker
        ? rawOrder.dates.map((rawDate) => new Date(rawDate.start_at))
        : undefined,
    isClient,
    phone: isClient ? rawWorker.phone : rawClient.phone,
  };
};

const loadDialoguesCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    dialogues: rawPayloadData.data.map((rawDialogue) => {
      let order: TOrder | undefined;
      let dialogueType = EDialogueType.Cancelled;
      if (rawDialogue.order) {
        if (rawDialogue.order.type === 'support') {
          dialogueType = EDialogueType.Admin;
        } else {
          order = cleanOrder(
            rawDialogue.order,
            rawDialogue.auth_user_id,
            rawDialogue.client,
            rawDialogue.worker,
          );
        }
      }

      if (order) {
        switch (order.status) {
          case EOrderStatus.Finished:
            dialogueType = EDialogueType.Completed;
            break;
          case EOrderStatus.Cancelled:
            dialogueType = EDialogueType.Cancelled;
            break;
          case EOrderStatus.New:
          case EOrderStatus.Approved:
          case EOrderStatus.Paid:
          default:
            dialogueType = EDialogueType.Active;
        }
      }

      const orders = rawDialogue.orders
        .map((rawOrder) =>
          cleanOrder(
            rawOrder,
            rawDialogue.auth_user_id,
            rawDialogue.client,
            rawDialogue.worker,
          ),
        )
        .filter((order) => order !== undefined);

      return {
        id: rawDialogue.id,
        name:
          rawDialogue.auth_user_type === 'client'
            ? rawDialogue.worker.name
            : rawDialogue.client.name,
        image:
          rawDialogue.auth_user_type === 'client'
            ? rawDialogue.worker.photo
            : rawDialogue.client.photo,
        type: dialogueType,
        messages: rawDialogue.messages.data.map(cleanMessage).sort((a, b) => {
          return isBefore(a.date, b.date) ? -1 : 1;
        }),
        order,
        orders: orders as TOrder[],
        totalMessages: rawDialogue.messages.meta.total,
      };
    }),
  };
};

export default loadDialoguesCleaner;
