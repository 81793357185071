import { TActionCreator } from 'store/TActionCreator';
import { Optional } from 'types/global';

export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export enum EChangePasswordError {
  InvalidOldPassword = 'invalidOldPassword',
  ShortPassword = 'shortPassword',
  SamePassword = 'samePassword'
}

export const changePasswordError: TActionCreator<
  typeof CHANGE_PASSWORD_ERROR,
  { error: Optional<EChangePasswordError> }
> = (payload) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload,
});
