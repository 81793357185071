import ru from './ru.json';
import en from './en.json';
import kz from './kz.json';
import { setLanguage as setLanguageAction } from 'store/actions/main/languageActions';
import { Dispatch } from 'redux';


type TLangPack = Record<string, string>;

let userLanguage = 'en-US';

const languages: Record<string, TLangPack> = {
  'ru-RU': ru,
  'en-US': en,
  'kk-KZ': kz,
};

const availableLanguages = [
  { code: 'en-US', name: 'English' },
  { code: 'ru-RU', name: 'Русский' },
  { code: 'kk-KZ', name: 'Қазақ' },
  // Добавьте другие языки по необходимости
];

const initLang = () => {
  const savedLang = localStorage.getItem('selectedLanguage');
  if (savedLang && languages[savedLang]) {
    userLanguage = savedLang;
  } else {
    const browserLang = navigator.language || navigator['language']; // для старых браузеров
    const matchedLang = Object.keys(languages).find(lang => browserLang.startsWith(lang.split('-')[0]));
    userLanguage = matchedLang || 'en-US'; // Замените 'en-US' на ваш язык по умолчанию, если он другой

    // Сохраняем определенный язык в localStorage, если он отличается от ранее сохраненного
    if (userLanguage !== savedLang) {
      localStorage.setItem('selectedLanguage', userLanguage);
    }
  }
};



const setLanguage = (dispatch: Dispatch, lang: string) => {
  dispatch(setLanguageAction({ language: lang }));
};


const getLang = (key: string, args?: string[]): string => {
  if (languages[userLanguage][key]) {
    let text = languages[userLanguage][key];
    if (args) {
      args.forEach((argValue, index) => {
        text = text.replace(`%arg${index + 1}%`, argValue);
      });
    }
    return text;
  }
  return key.toLowerCase().replace('_', ' ');
};

const getNumberLang = (
  value: number,
  numberLangs: [string, string, string],
): string => {
  if ((value >= 5 && value <= 20) || value % 10 >= 5 || value % 10 === 0) {
    return getLang(numberLangs[2], [value.toString()]);
  }
  if (value % 10 === 1) {
    return getLang(numberLangs[0], [value.toString()]);
  }
  return getLang(numberLangs[1], [value.toString()]);
};

export { initLang, availableLanguages, setLanguage, getLang, getNumberLang };
