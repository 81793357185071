import { TActionCreator } from 'store/TActionCreator';
import { TMessage } from 'store/reducers/chat/TMessage';

export const NEW_MESSAGE = 'NEW_MESSAGE';

export const newMessage: TActionCreator<
  typeof NEW_MESSAGE,
  { dialogueId: number; message: TMessage }
> = (payload) => ({
  type: NEW_MESSAGE,
  payload,
});
