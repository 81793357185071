import { TPet } from 'store/reducers/client/TPet';
import { TRawOkayPayloadData, TRawPet } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

export const cleanPet = (rawPet: TRawPet): TPet => ({
  id: rawPet.id,
  name: rawPet.name,
  year: rawPet.year,
  month: rawPet.month,
  gender: rawPet.gender,
  catsFriendly: rawPet.cats_friendly,
  dogsFriendly: rawPet.dogs_friendly,
  kidsFriendly: rawPet.kids_friendly,
  infoForSitting: rawPet.info_for_sitting,
  infoForWalking: rawPet.info_for_walking,
  stayingHomeAlone: rawPet.staying_home_alone,
  sterilized: rawPet.sterilized,
  vaccinated: rawPet.vaccinated,
  size: rawPet.size.id,
  type: rawPet.type.data.id,
  avatarId: rawPet.avatar.data.id,
  defaultAvatar: rawPet.avatar.data.path,
  breed: rawPet.breed.data.id,
  breedName: rawPet.breed.data.name,
  photos: rawPet.files.data.map((rawPhoto) => ({
    url: rawPhoto.path,
    id: rawPhoto.id,
  })),
});

const loadPetsCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return rawPayloadData.data.map(cleanPet);
};

export default loadPetsCleaner;
