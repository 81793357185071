import React, { FC } from 'react';

const Search: FC = () => (
	<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.407 19.753l-4.41-4.41a8.148 8.148 0 001.633-4.903c0-4.516-3.674-8.19-8.19-8.19s-8.19 3.674-8.19 8.19 3.674 8.19 8.19 8.19a8.148 8.148 0 004.902-1.633l4.41 4.41a1.171 1.171 0 001.655-1.654zM4.59 10.44a5.85 5.85 0 115.85 5.85 5.857 5.857 0 01-5.85-5.85z"
			fill="currentColor"
		/>
	</svg>
);

export default Search;
