import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_CANCEL_SITTER_REQUEST = 'SUCCESS_CANCEL_SITTER_REQUEST';

export const successCancelSitterRequest: TActionCreator<
  typeof SUCCESS_CANCEL_SITTER_REQUEST,
  { requestId: number }
> = (payload) => ({
  type: SUCCESS_CANCEL_SITTER_REQUEST,
  payload,
});
