import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const loadSitterProfileCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  const { data } = rawPayloadData;
  return {
    title: data.title,
    description: data.description,
    constantSupervision: Boolean(data.constant_supervision),
    allowBed: Boolean(data.pets_on_the_bed),
    houseType: data.type_of_housing === 1 ? 'house' : 'flat',
    hasChildren: Boolean(data.children_under_twelve_yo),
    hasCar: Boolean(data.has_car),
    careExperience: data.care_experience,
    canInjection: Boolean(data.give_injection),
    canMedicine: Boolean(data.give_medication),
    photos: data.photos.data.map((rawPhoto) => ({
      url: rawPhoto.path,
      id: rawPhoto.id,
    })),
  };
};

export default loadSitterProfileCleaner;
