import { TUserState } from 'store/reducers/user/TUserState';

const cancelResetPasswordHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    forgotPassword: false,
    correctResetCode: null,
  };
};

export default cancelResetPasswordHandler;
