import { ERole } from 'store/reducers/profile/TProfile';

import {
  TRawOkayPayloadData,
  TRawSitterSettings,
  TRawWalkingSettings,
} from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const getProfileCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  const { data } = rawPayloadData;
  const response: TOkayPayloadData = {
    profile: {
      id: data.id,
      avatar: data.avatar.data.path,
      firstName: data.first_name,
      lastName: data.last_name || '',
      email: data.email,
      gender: data.gender,
      address: data.address,
      birthDate: data.birth ? new Date(data.birth) : null,
      additionalContactName: data.additional_contact_name,
      additionalContactPhone: data.additional_contact_phone,
      roles: data.roles.map((rawRole) => {
        if (rawRole === 'sitter') {
          return ERole.Sitter;
        }
        return ERole.Walker;
      }),
    },
  };

  const sitterSettingsRaw = Array.isArray(data.sitting_settings.data)
    ? null
    : (data.sitting_settings.data as TRawSitterSettings);
  const walkingSettingsRaw = Array.isArray(data.walking_settings.data)
    ? null
    : (data.walking_settings.data as TRawWalkingSettings);
  if (sitterSettingsRaw || walkingSettingsRaw) {
    response.sitterSettings = {
      sitterPetTypes: sitterSettingsRaw
        ? sitterSettingsRaw.allowed_pet_types.data.map(
            (rawPetType) => rawPetType.id,
          )
        : null,
      sitterDogSizes: sitterSettingsRaw
        ? sitterSettingsRaw.allowed_dog_sizes.data.map(
            (rawDogSize) => rawDogSize.id,
          )
        : null,
      sitterCatSizes: sitterSettingsRaw
        ? sitterSettingsRaw.allowed_cat_sizes.data.map(
            (rawDogSize) => rawDogSize.id,
          )
        : null,
      sitterPrice: sitterSettingsRaw ? sitterSettingsRaw.price : null,
      sitterOrders: sitterSettingsRaw
        ? sitterSettingsRaw.count_of_orders
        : null,
      walkingPrice: walkingSettingsRaw ? walkingSettingsRaw.price : null,
      walkingDogSizes: walkingSettingsRaw
        ? walkingSettingsRaw.allowed_dog_sizes.data.map(
            (rawDogSize) => rawDogSize.id,
          )
        : null,
      ordersCount: sitterSettingsRaw ? sitterSettingsRaw.count_of_orders : null,
    };
  }
  return response;
};

export default getProfileCleaner;
