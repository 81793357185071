import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TLoadSitterProfilePayload } from './types/TLoadSitterProfilePayload';
import loadSitterProfileCleaner from './loadSitterProfileCleaner';

const loadSitterProfileFetch = async (): Promise<
  TLoadSitterProfilePayload | never
> => {
  const url = new URL(`${API_URL}/my/profile`);

  return apiFetch(loadSitterProfileCleaner, url.toString());
};

export default loadSitterProfileFetch;
