import { TRawOkayPayloadData } from 'api/chat/sendMessage/types/TRawOkayPayloadData';
import { TOkayPayloadData } from 'api/chat/sendMessage/types/TOkayPayloadData';
import { cleanMessage } from 'api/chat/loadDialogues/loadDialoguesCleaner';

const sendMessageCleaner = (
  rawPayload: TRawOkayPayloadData,
): TOkayPayloadData => {
  return cleanMessage(rawPayload);
};

export default sendMessageCleaner;
