export type TProfile = {
  id: number;
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: number | null;
  address: string;
  additionalContactName: string;
  additionalContactPhone: string;
  birthDate: Date | null;
  roles: ERole[];
};

export enum ERole {
  Sitter = 'sitter',
  Walker = 'walker',
}
