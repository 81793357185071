import { TAction } from 'store/TAction';

import { SET_IS_MOBILE_MENU_OPENED } from 'store/actions/main/setIsMobileMenuOpened';
import { SET_IS_MOBILE } from 'store/actions/main/setIsMobile';
import { SET_IS_TABLET } from 'store/actions/main/setIsTablet';
import { REQUEST_FAIL } from 'store/actions/main/requestFail';
import { SET_MOBILE_MENU_ENABLED } from 'store/actions/main/setMobileMenuEnabled';
import { SET_LANGUAGE } from 'store/actions/main/languageActions';


import { TMainState } from './TMainState';
import setIsMobileHandler from './handlers/setIsMobileHandler';
import setIsMobileMenuOpenedHandler from './handlers/setIsMobileMenuOpenedHandler';
import setIsTabletHandler from './handlers/setIsTabletHandler';
import requestFailHandler from './handlers/requestFailHandler';
import setMobileMenuEnabledHandler from './handlers/setMobileMenuEnabledHandler';
import languageReducer from './handlers/languageReducer';

export const mainInitialState: TMainState = {
  isMobile: null,
  isMobileMenuOpened: false,
  mobileMenuEnabled: true,
  isTablet: null,
  requestFailed: false,
  currentLanguage: 'en-US'
};

const mainReducer = (state = mainInitialState, action: TAction): TMainState => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return setIsMobileHandler(state, action);
    case SET_IS_MOBILE_MENU_OPENED:
      return setIsMobileMenuOpenedHandler(state, action);
    case SET_IS_TABLET:
      return setIsTabletHandler(state, action);
    case REQUEST_FAIL:
      return requestFailHandler(state);
    case SET_MOBILE_MENU_ENABLED:
      return setMobileMenuEnabledHandler(state, action);
    case SET_LANGUAGE:
      return languageReducer(state, action);
    default:
      return state;
  }
};

export default mainReducer;
