import { TUserState } from 'store/reducers/user/TUserState';
import { successCheckCode } from 'store/actions/user/successCheckCode';

const successCheckCodeHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof successCheckCode>,
): TUserState => {
  return {
    ...state,
    correctCode: payload.correctCode,
    registerError: null,
  };
};

export default successCheckCodeHandler;
