import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { fireBecomeSitterDeleteProfilePhoto } from 'store/actions/sitter/fireBecomeSitterDeleteProfilePhoto';

const fireDeleteBecomeSitterProfilePhotoHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof fireBecomeSitterDeleteProfilePhoto>,
): TSitterState => {
  if (!state.becomeSitterProfile || !state.becomeSitterProfile.uploadedPhotos) {
    return state;
  }

  return {
    ...state,
    becomeSitterProfile: {
      ...state.becomeSitterProfile,
      uploadedPhotos: state.becomeSitterProfile.uploadedPhotos.filter(
        (photo) => photo.id !== payload.id,
      ),
    },
  };
};

export default fireDeleteBecomeSitterProfilePhotoHandler;
