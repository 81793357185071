import { TMainState } from 'store/reducers/main/TMainState';
import { setMobileMenuEnabled } from 'store/actions/main/setMobileMenuEnabled';

const setMobileMenuEnabledHandler = (
  state: TMainState,
  { payload }: ReturnType<typeof setMobileMenuEnabled>,
): TMainState => {
  return {
    ...state,
    mobileMenuEnabled: payload.enabled,
  };
};

export default setMobileMenuEnabledHandler;
