import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { fireSitterProfileSave } from 'store/actions/sitter/fireSitterProfileSave';
import sitterProfileSaveFetch from 'api/sitter/sitterProfileSave/sitterProfileSaveFetch';
import selectProfile from 'store/selectors/profile/selectProfile';
import { successSitterProfileSave } from 'store/actions/sitter/successSitterProfileSave';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { setSitterProfile } from 'store/actions/sitter/setSitterProfile';

function* sitterProfileSaveSaga({
  payload,
}: ReturnType<typeof fireSitterProfileSave>): SagaIterator<void> {
  try {
    const profile = yield select(selectProfile);
    const response = yield call(fetchSaga, {
      fetcher: sitterProfileSaveFetch,
      payload: { ...payload.profile, address: profile.address },
    });
    yield put(successSitterProfileSave());
    if (response.type === EResponseType.OK) {
      yield put(setSitterProfile({ profile }));
      toast.success(getLang('SAVE_SUCCESS'));
    } else {
      toast.error(getLang('SAVE_ERROR'));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default sitterProfileSaveSaga;
