// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1u2fq {\n  font-family: \"Gilroy\", sans-serif;\n  margin: 0;\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/Text/Text.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,SAAS;EACT,UAAU;AACZ","sourcesContent":[".container {\n  font-family: \"Gilroy\", sans-serif;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1u2fq"
};
export default ___CSS_LOADER_EXPORT___;
