import { TClientState } from 'store/reducers/client/TClientState';
import { successLoadRequests } from 'store/actions/client/successLoadRequests';

const successLoadRequestsHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successLoadRequests>,
): TClientState => ({
  ...state,
  requests: payload.requests,
});

export default successLoadRequestsHandler;
