import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import loadOrdersFetch from 'api/client/loadOrders/loadOrdersFetch';
import { successLoadOrders } from 'store/actions/client/successLoadOrders';
import loadPetsFetch from 'api/client/loadPets/loadPetsFetch';
import { successLoadPets } from 'store/actions/client/successLoadPets';

function* loadOrdersSaga(): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: loadOrdersFetch,
    });
    const responsePets = yield call(fetchSaga, {
      fetcher: loadPetsFetch,
    });

    if (
      response.type === EResponseType.OK &&
      responsePets.type === EResponseType.OK
    ) {
      yield put(successLoadOrders({ orders: response.data }));
      yield put(successLoadPets({ pets: responsePets.data }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default loadOrdersSaga;
