import { TActionCreator } from 'store/TActionCreator';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const changePasswordSuccess: TActionCreator<
  typeof CHANGE_PASSWORD_SUCCESS,
  { success: boolean }
> = (payload) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});
