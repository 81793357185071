// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1VsOb {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 56px;\n  background-color: #ffffff;\n  color: var(--primary);\n  flex-shrink: 0;\n  box-sizing: border-box;\n  border-bottom: 1px solid var(--border-gray);\n}\n\n.logo_18fVd {\n  transform: scale(0.8);\n}\n\n.menu_2Ai-_ {\n  color: #000000;\n  position: absolute;\n  left: 16px;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/containers/Main/HeaderMobile.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;EACd,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,MAAM;EACN,SAAS;EACT,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 56px;\n  background-color: #ffffff;\n  color: var(--primary);\n  flex-shrink: 0;\n  box-sizing: border-box;\n  border-bottom: 1px solid var(--border-gray);\n}\n\n.logo {\n  transform: scale(0.8);\n}\n\n.menu {\n  color: #000000;\n  position: absolute;\n  left: 16px;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1VsOb",
	"logo": "logo_18fVd",
	"menu": "menu_2Ai-_"
};
export default ___CSS_LOADER_EXPORT___;
