import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadOrdersPayload } from './types/TLoadOrdersPayload';
import loadOrdersCleaner from './loadOrdersCleaner';

const loadOrdersFetch = async (): Promise<TLoadOrdersPayload | never> => {
  const url = new URL(
    `${API_URL}/orders/my/client?include=orderable,clientReview,workerReview,pets`,
  );

  return apiFetch(loadOrdersCleaner, url.toString());
};

export default loadOrdersFetch;
