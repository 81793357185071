import React, { FC, useEffect, ChangeEvent } from 'react';
import Logo from 'icons/Logo';
import Hamburger from 'icons/Hamburger';
import Button from 'components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobileMenuOpened } from 'store/actions/main/setIsMobileMenuOpened';
import selectIsMobileMenuOpened from 'store/selectors/main/selectIsMobileMenuOpened';
import { Optional } from 'types/global';
import selectCurrentDialogueId from 'store/selectors/chat/selectCurrentDialogueId';
import { setCurrentDialogue } from 'store/actions/chat/setCurrentDialogue';
import selectMobileMenuEnabled from 'store/selectors/main/selectMobileMenuEnabled';
import { useHistory } from 'react-router-dom';


import styles from './HeaderMobile.module.css';

const MENU_CLOSE_THRESHOLD_X = 50;
const MENU_OPEN_THRESHOLD_X = 50;
const CHAT_CLOSE_THRESHOLD_X = 50;

const HeaderMobile: FC = () => {
  const dispatch = useDispatch();
  const isMobileMenuOpened = useSelector(selectIsMobileMenuOpened);
  const currentDialogueId = useSelector(selectCurrentDialogueId);
  const mobileMenuEnabled = useSelector(selectMobileMenuEnabled);
  const history = useHistory();

  useEffect(() => {
    let touchStartX: Optional<number> = null;
    let touchStartY: Optional<number> = null;
    let isHorizontal: Optional<boolean> = null;

    const checkIsHorizontal = (touch: Touch) => {
      if (isHorizontal === null && touchStartX && touchStartY) {
        isHorizontal =
          Math.abs(touch.clientX - touchStartX) >
          Math.abs(touch.clientY - touchStartY);
      }
    };

    const onTouchStart = (event: TouchEvent) => {
      const firstTouch = event.touches[0];
      touchStartX = firstTouch.clientX;
      touchStartY = firstTouch.clientY;
    };

    const onTouchEnd = () => {
      touchStartX = null;
      touchStartY = null;
      isHorizontal = null;
    };

    const checkMenuClose = (event: TouchEvent) => {
      if (!mobileMenuEnabled) {
        return;
      }
      const firstTouch = event.touches[0];
      checkIsHorizontal(firstTouch);
      if (isHorizontal) {
        event.preventDefault();
        if (
          touchStartX &&
          touchStartX - firstTouch.clientX > MENU_CLOSE_THRESHOLD_X
        ) {
          dispatch(setIsMobileMenuOpened({ opened: false }));
        }
      }
    };

    const checkMenuOpen = (event: TouchEvent) => {
      if (!mobileMenuEnabled) {
        return;
      }
      const firstTouch = event.touches[0];
      checkIsHorizontal(firstTouch);
      if (isHorizontal) {
        event.preventDefault();
        if (
          touchStartX &&
          firstTouch.clientX - touchStartX > MENU_OPEN_THRESHOLD_X
        ) {
          dispatch(setIsMobileMenuOpened({ opened: true }));
        }
      }
    };

    const checkChatClose = (event: TouchEvent) => {
      if (!mobileMenuEnabled) {
        return;
      }
      const firstTouch = event.touches[0];
      checkIsHorizontal(firstTouch);
      if (isHorizontal) {
        event.preventDefault();
        if (
          touchStartX &&
          firstTouch.clientX - touchStartX > CHAT_CLOSE_THRESHOLD_X
        ) {
          dispatch(
            setCurrentDialogue({ dialogueId: null, historyPush: history.push }),
          );
        }
      }
    };

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchend', onTouchEnd);

    if (currentDialogueId) {
      window.addEventListener('touchmove', checkChatClose, { passive: false });
    } else if (isMobileMenuOpened) {
      window.addEventListener('touchmove', checkMenuClose, { passive: false });
    } else {
      window.addEventListener('touchmove', checkMenuOpen, { passive: false });
    }

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchend', onTouchEnd);
      window.removeEventListener('touchmove', checkMenuClose);
      window.removeEventListener('touchmove', checkMenuOpen);
      window.removeEventListener('touchmove', checkChatClose);
    };
  }, [
    dispatch,
    isMobileMenuOpened,
    currentDialogueId,
    mobileMenuEnabled,
    history,
  ]);

  const openMenuClick = () => {
    dispatch(setIsMobileMenuOpened({ opened: true }));
  };



  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <Button view="ghost" mode="secondary" onClick={openMenuClick}>
          <Hamburger />
        </Button>
      </div>
      <div className={styles.logo}>
        <Button view="ghost" href="/">
          <Logo />
        </Button>
      </div>
    </div>
  );
};

export default HeaderMobile;
