import { TUserState } from 'store/reducers/user/TUserState';
import { successInitial } from 'store/actions/main/successInitial';

const successInitialHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof successInitial>,
): TUserState => {
  return {
    ...state,
    loggedIn: payload.loggedIn,
    accessToken: payload.accessToken || null,
  };
};

export default successInitialHandler;
