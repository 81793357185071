import React from 'react';
import { getLang } from 'libs/langs/langs';
import Dog from 'icons/Dog';
import Cat from 'icons/Cat';
import Bird from 'icons/Bird';
import Rodent from 'icons/Rodent';
import Fish from 'icons/Fish';
import Dots from 'icons/Dots';
import PetMini from 'icons/PetMini';
import PetSmall from 'icons/PetSmall';
import PetMedium from 'icons/PetMedium';
import PetBig from 'icons/PetBig';
import PetHuge from 'icons/PetHuge';

export const PET_DOG_ID = 1;
export const PET_CAT_ID = 2;
export const petsList = [
  {
    id: PET_DOG_ID,
    name: getLang('DOG'),
    icon: <Dog />,
  },
  {
    id: PET_CAT_ID,
    name: getLang('CAT'),
    icon: <Cat />,
  },
  {
    id: 3,
    name: getLang('BIRD'),
    icon: <Bird />,
  },
  {
    id: 4,
    name: getLang('RODENT'),
    icon: <Rodent />,
  },
  {
    id: 5,
    name: getLang('FISH'),
    icon: <Fish />,
  },
  {
    id: 6,
    name: getLang('OTHER_PET'),
    icon: <Dots />,
  },
];

export const sizesList = [
  {
    id: 1,
    name: getLang('PET_MINI_NAME'),
    size: getLang('PET_MINI_SIZE'),
    icon: <PetMini />,
  },
  {
    id: 2,
    name: getLang('PET_SMALL_NAME'),
    size: getLang('PET_SMALL_SIZE'),
    icon: <PetSmall />,
  },
  {
    id: 3,
    name: getLang('PET_MEDIUM_NAME'),
    size: getLang('PET_MEDIUM_SIZE'),
    icon: <PetMedium />,
  },
  {
    id: 4,
    name: getLang('PET_BIG_NAME'),
    size: getLang('PET_BIG_SIZE'),
    icon: <PetBig />,
  },
  {
    id: 5,
    name: getLang('PET_HUGE_NAME'),
    size: getLang('PET_HUGE_SIZE'),
    icon: <PetHuge />,
  },
];
