import { TActionCreator } from 'store/TActionCreator';
import { TSitterSettings } from 'store/reducers/sitter/TSitterSettings';

export const SET_SITTER_SETTINGS = 'SET_SITTER_SETTINGS';

export const setSitterSettings: TActionCreator<
  typeof SET_SITTER_SETTINGS,
  { settings: TSitterSettings }
> = (payload) => ({
  type: SET_SITTER_SETTINGS,
  payload,
});
