import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TApproveRequestPayload } from './types/TApproveRequestPayload';
import { TApproveRequestParameters } from './types/TApproveRequestParameters';

const approveRequestFetch = async ({
  id,
}: TApproveRequestParameters): Promise<TApproveRequestPayload | never> => {
  const url = new URL(`${API_URL}/orders/requests/${id}/worker`);

  return apiFetch(() => null, url.toString());
};

export default approveRequestFetch;
