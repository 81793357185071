import { TSitterPet } from 'store/reducers/client/TSitterProfile';

import {
  TRawOkayPayloadData,
  TRawSitterPet,
} from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const cleanPet = (rawPet: TRawSitterPet): TSitterPet => ({
  id: rawPet.id,
  name: rawPet.name,
  type: rawPet.type_id,
  avatar: rawPet.avatar.path,
  breedName: rawPet.breed_name,
});

const loadSitterCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  const address = [];
  if (rawPayloadData.city) {
    address.push(rawPayloadData.city.name);
  }
  if (rawPayloadData.district) {
    address.push(rawPayloadData.district.name);
  }

  return {
    name: rawPayloadData.name,
    avatar: rawPayloadData.avatar.data.path,
    careExperience: rawPayloadData.care_experience,
    hasChildren: Boolean(rawPayloadData.children_under_twelve_yo),
    address: address.join(', '),
    constantSupervision: Boolean(rawPayloadData.constant_supervision),
    description: rawPayloadData.description,
    title: rawPayloadData.title,
    canInjection: Boolean(rawPayloadData.give_injection),
    canMedicine: Boolean(rawPayloadData.give_medication),
    addressPoint: [
      Number(rawPayloadData.latitude),
      Number(rawPayloadData.longitude),
    ],
    ordersCount: rawPayloadData.orders_count,
    allowBed: Boolean(rawPayloadData.pets_on_the_bed),
    registrationDate: new Date(rawPayloadData.registration_sitter_date),
    repeatOrdersCount: rawPayloadData.repeat_order_count,
    reviewsCount: rawPayloadData.reviews_count,
    userRating: rawPayloadData.user_rating,
    simultaneousOrders: rawPayloadData.simultaneous_orders,
    houseType: rawPayloadData.type_of_housing === 1 ? 'house' : 'flat',
    sittingPrice:
      rawPayloadData.sitting_settings.length > 0
        ? rawPayloadData.sitting_settings[0].price
        : 0,
    walkingPrice:
      rawPayloadData.walking_settings.length > 0
        ? rawPayloadData.walking_settings[0].price
        : 0,
    photos: rawPayloadData.photos.data.map((rawPhoto) => ({
      src: rawPhoto.path,
      thumb: rawPhoto.preview,
    })),
    age: rawPayloadData.age,
    hasCar: Boolean(rawPayloadData.has_car),
    petTypes:
      rawPayloadData.sitting_settings.length > 0 &&
      rawPayloadData.sitting_settings[0].allowed_pet_types
        ? rawPayloadData.sitting_settings[0].allowed_pet_types.map(
            (rawType) => rawType.id,
          )
        : [],
    dogSizes:
      rawPayloadData.sitting_settings.length > 0 &&
      rawPayloadData.sitting_settings[0].dog_sizes
        ? rawPayloadData.sitting_settings[0].dog_sizes.map(
            (rawType) => rawType.id,
          )
        : [],
    catSizes:
      rawPayloadData.sitting_settings.length > 0 &&
      rawPayloadData.sitting_settings[0].cat_sizes
        ? rawPayloadData.sitting_settings[0].cat_sizes.map(
            (rawType) => rawType.id,
          )
        : [],
    pets: rawPayloadData.pets.data.map(cleanPet),
  };
};

export default loadSitterCleaner;
