import React, { FC } from 'react';

const PetMini: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.996 6.523h-1.985C20.865 5.567 19.442 5 17.902 5s-2.963.567-4.108 1.523h-1.985A3.813 3.813 0 008 10.332v4.57a2.288 2.288 0 002.285 2.286h2.136c.105.153.214.302.328.447-1.985 2.578-3.085 5.76-3.213 8.92A2.29 2.29 0 008 28.716 2.288 2.288 0 0010.285 31h1.949c.985 0 1.856-.628 2.168-1.563l.752-2.255a5.278 5.278 0 005.497 0l.752 2.256A2.282 2.282 0 0023.57 31h1.948a2.288 2.288 0 002.286-2.285 2.29 2.29 0 00-1.537-2.16c-.127-3.16-1.227-6.342-3.212-8.92a7.65 7.65 0 00.327-.448h2.137a2.288 2.288 0 002.285-2.285v-4.57c0-2.1-1.709-3.809-3.809-3.809zm-.94 6.094c0 3.065-1.732 5.46-4.313 5.883v-1.444c.887-.314 1.583-1.16 1.583-2.154 0-1.26-1.164-2.402-2.424-2.402S15.5 13.642 15.5 14.902c0 .993.613 1.84 1.5 2.155V18.5c-2.58-.424-4.251-2.818-4.251-5.883C12.749 9.257 15.059 7 18 7c2.94 0 5.056 2.257 5.056 5.617zm-5.154 3.047a.763.763 0 11.002-1.525.763.763 0 01-.002 1.525zm-7.617-.164c-.42 0-.653-.178-.653-.598v-4.57c0-1.26.917-2.147 2.177-2.147l.612-.138a8.143 8.143 0 00-1.374 4.57c0 1.083.205 2.113.573 3.047l-1.335-.164zM25.5 28c.42 0 .5.295.5.715s-.58.587-1 .587h-1.43c-.328 0-.41-.035-.514-.346L22 25.692c-.176-.528-1.096-1.075-1.5-.692-.715.677-1.531 1-2.5 1-.969 0-1.785-.323-2.5-1-.404-.383-1.422.163-1.598.692l-1.153 3.156c-.104.312-.187.454-.515.454H10.5c-.42 0-.704-.167-.704-.587 0-.42.07-.615.49-.615s.916-.498.916-.918c0-3.026 1.024-5.79 2.798-8.182 2.392 1.972 5.311 2.018 7.76 0 1.774 2.391 2.74 5.156 2.74 8.182 0 .42.58.818 1 .818zm.5-13c0 .42-.06.5-.48.5l-1.335.164a8.297 8.297 0 00.573-3.047 8.143 8.143 0 00-1.375-4.57l.613.138c1.26 0 2.004.887 2.004 2.147V15z"
      fill="currentColor"
    />
  </svg>
);

export default PetMini;
