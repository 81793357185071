import { TActionCreator } from 'store/TActionCreator';

export const FIRE_LISTEN_TYPING = 'FIRE_LISTEN_TYPING';

export const fireListenTyping: TActionCreator<
  typeof FIRE_LISTEN_TYPING,
  { dialogueId: number }
> = (payload) => ({
  type: FIRE_LISTEN_TYPING,
  payload,
});
