import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { newMessage } from 'store/actions/chat/newMessage';
import selectContacts from 'store/selectors/chat/selectContacts';
import { TDialogue } from 'store/reducers/chat/TDialogue';
import { loadDialogues } from 'store/actions/chat/loadDialogues';
import selectCurrentDialogueId from 'store/selectors/chat/selectCurrentDialogueId';
import selectProfileId from 'store/selectors/profile/selectProfileId';
import { fireMessagesAsRead } from 'store/actions/chat/fireMessagesAsRead';

function* newMessageSaga({
  payload,
}: ReturnType<typeof newMessage>): SagaIterator<void> {
  try {
    const { dialogueId } = payload;

    const currentDialogueId = yield select(selectCurrentDialogueId);
    const profileId = yield select(selectProfileId);
    if (currentDialogueId && currentDialogueId === dialogueId && profileId) {
      yield put(fireMessagesAsRead({ dialogueId, profileId }));
    }

    const contacts = yield select(selectContacts);
    const dialogue = contacts.find(
      (contact: TDialogue) => contact.id === dialogueId,
    );
    if (!dialogue) {
      yield put(loadDialogues({ silent: true }));
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default newMessageSaga;
