import { TActionCreator } from 'store/TActionCreator';

export const START_WEB_SOCKET = 'START_WEB_SOCKET';

export const startWebSocket: TActionCreator<
  typeof START_WEB_SOCKET,
  { userId: number }
> = (payload) => ({
  type: START_WEB_SOCKET,
  payload,
});
