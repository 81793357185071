import React, { FC } from 'react';
import cn from 'classnames';

import styles from './Text.module.css';

export type TextProps = {
	className?: string;
	tag?: 'span' | 'div' | 'h1' | 'h2';
};

const Text: FC<TextProps> = ({
	children,
	className,
	tag: Component = 'span',
}) => (
	<Component className={cn(styles.container, className)}>{children}</Component>
);

export default Text;
