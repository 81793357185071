import { TAction } from 'store/TAction';

import { SUCCESS_SEARCH_SITTER } from 'store/actions/client/successSearchSitter';
import { SET_CURRENT_SEARCH } from 'store/actions/client/setCurrentSearch';
import { CLEAN_SITTERS_LIST } from 'store/actions/client/cleanSittersList';
import { SUCCESS_LOAD_SITTER_DATA } from 'store/actions/client/successLoadSitterData';
import { SUCCESS_LOAD_REVIEWS } from 'store/actions/client/successLoadReviews';
import { FIRE_LOAD_REVIEWS } from 'store/actions/client/fireLoadReviews';
import { SUCCESS_LOAD_PETS } from 'store/actions/client/successLoadPets';
import { SUCCESS_UPLOAD_PET_PHOTOS } from 'store/actions/client/successUploadPetPhotos';
import { FIRE_DELETE_PET_PHOTO } from 'store/actions/client/fireDeletePetPhoto';
import { SUCCESS_SAVE_PET } from 'store/actions/client/successSavePet';
import { FIRE_SAVE_PET } from 'store/actions/client/fireSavePet';
import { FIRE_DELETE_PET } from 'store/actions/client/fireDeletePet';
import { SUCCESS_DELETE_PET } from 'store/actions/client/successDeletePet';
import { SET_SELECTED_PET } from 'store/actions/client/setSelectedPet';
import { SUCCESS_LOAD_ORDERS } from 'store/actions/client/successLoadOrders';
import { SET_CURRENT_ORDER } from 'store/actions/client/setCurrentOrder';
import { FIRE_SAVE_ORDER } from 'store/actions/client/fireSaveOrder';
import { SUCCESS_SAVE_ORDER } from 'store/actions/client/successSaveOrder';
import { FIRE_SEND_REQUEST } from 'store/actions/client/fireSendRequest';
import { SUCCESS_SEND_REQUEST } from 'store/actions/client/successSendRequest';
import { SUCCESS_LOAD_REQUESTS } from 'store/actions/client/successLoadRequests';
import { SUCCESS_APPROVE_REQUEST } from 'store/actions/client/successApproveRequest';

import { TClientState } from './TClientState';
import successSearchSitterHandler from './handlers/successSearchSitterHandler';
import setCurrentSearchHandler from './handlers/setCurrentSearchHandler';
import cleanSittersListHandler from './handlers/cleanSittersListHandler';
import successLoadSitterDataHandler from './handlers/successLoadSitterDataHandler';
import successLoadReviewsHandler from './handlers/successLoadReviewsHandler';
import fireLoadReviewsHandler from './handlers/fireLoadReviewsHandler';
import successLoadPetsHandler from './handlers/successLoadPetsHandler';
import fireDeletePetPhotoHandler from './handlers/fireDeletePetPhotoHandler';
import successUploadPetPhotosHandler from './handlers/successUploadPetPhotosHandler';
import successSavePetHandler from './handlers/successSavePetHandler';
import fireSavePetHandler from './handlers/fireSavePetHandler';
import fireDeletePetHandler from './handlers/firePetDeleteHandler';
import successDeletePetHandler from './handlers/successDeletePetHandler';
import setSelectedPetHandler from './handlers/setSelectedPetHandler';
import successLoadOrdersHandler from './handlers/successLoadOrdersHandler';
import setCurrentOrderHandler from './handlers/setCurrentOrderHandler';
import fireSaveOrderHandler from './handlers/fireSaveOrderHandler';
import successSaveOrderHandler from './handlers/successSaveOrderHandler';
import fireSendRequestHandler from './handlers/fireSendRequestHandler';
import successSendRequestHandler from './handlers/successSendRequestHandler';
import successLoadRequestsHandler from './handlers/successLoadRequestsHandler';
import successApproveRequestHandler from './handlers/successApproveRequestHandler';

export const clientInitialState: TClientState = {
  sittersList: null,
  currentSearch: null,
  searchCurrentPage: null,
  searchTotalPages: null,
  sitterProfile: null,
  reviews: [],
  reviewsLoading: false,
  reviewsTotal: null,
  pets: null,
  uploadedPetPhotos: null,
  petSaving: false,
  petDeletingId: null,
  selectedPet: null,
  orders: null,
  currentOrder: null,
  orderSaving: false,
  requestSendingOrderId: null,
  requests: null,
};

const clientReducer = (
  state = clientInitialState,
  action: TAction,
): TClientState => {
  switch (action.type) {
    case SUCCESS_SEARCH_SITTER:
      return successSearchSitterHandler(state, action);
    case SET_CURRENT_SEARCH:
      return setCurrentSearchHandler(state, action);
    case CLEAN_SITTERS_LIST:
      return cleanSittersListHandler(state);
    case SUCCESS_LOAD_SITTER_DATA:
      return successLoadSitterDataHandler(state, action);
    case SUCCESS_LOAD_REVIEWS:
      return successLoadReviewsHandler(state, action);
    case FIRE_LOAD_REVIEWS:
      return fireLoadReviewsHandler(state);
    case SUCCESS_LOAD_PETS:
      return successLoadPetsHandler(state, action);
    case FIRE_DELETE_PET_PHOTO:
      return fireDeletePetPhotoHandler(state, action);
    case SUCCESS_UPLOAD_PET_PHOTOS:
      return successUploadPetPhotosHandler(state, action);
    case SUCCESS_SAVE_PET:
      return successSavePetHandler(state, action);
    case FIRE_SAVE_PET:
      return fireSavePetHandler(state);
    case FIRE_DELETE_PET:
      return fireDeletePetHandler(state, action);
    case SUCCESS_DELETE_PET:
      return successDeletePetHandler(state);
    case SET_SELECTED_PET:
      return setSelectedPetHandler(state, action);
    case SUCCESS_LOAD_ORDERS:
      return successLoadOrdersHandler(state, action);
    case SET_CURRENT_ORDER:
      return setCurrentOrderHandler(state, action);
    case FIRE_SAVE_ORDER:
      return fireSaveOrderHandler(state);
    case SUCCESS_SAVE_ORDER:
      return successSaveOrderHandler(state, action);
    case FIRE_SEND_REQUEST:
      return fireSendRequestHandler(state, action);
    case SUCCESS_SEND_REQUEST:
      return successSendRequestHandler(state, action);
    case SUCCESS_LOAD_REQUESTS:
      return successLoadRequestsHandler(state, action);
    case SUCCESS_APPROVE_REQUEST:
      return successApproveRequestHandler(state, action);
    default:
      return state;
  }
};

export default clientReducer;
