import { TChatState } from 'store/reducers/chat/TChatState';
import { setDialogueTyping } from 'store/actions/chat/setDialogueTyping';

const setDialogueTypingHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof setDialogueTyping>,
): TChatState => {
  return {
    ...state,
    dialogueTyping: payload.typing,
  };
};

export default setDialogueTypingHandler;
