import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TBecomeSitterServicesOptionsSaveParameters } from './types/TBecomeSitterServicesOptionsSaveParameters';

const becomeSitterServicesOptionsSaveFetch = async ({
  walker,
  sitter,
}: TBecomeSitterServicesOptionsSaveParameters): Promise<
  TPayload<unknown> | never
> => {
  const url = new URL(`${API_URL}/worker/create/setting`);
  const body: any = {};
  if (sitter) {
    body.worker_request_sitter_settings = {
      price: sitter.price,
      allowed_pet_types: sitter.petTypes,
    };
    if (sitter.dogsSizes) {
      body.worker_request_sitter_settings.allowed_dog_sizes = sitter.dogsSizes;
    }
    if (sitter.catsSizes) {
      body.worker_request_sitter_settings.allowed_cat_sizes = sitter.catsSizes;
    }
  }
  if (walker) {
    body.worker_request_walker_settings = {
      price: walker.price,
      allowed_dog_sizes: walker.dogsSizes,
    };
  }

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default becomeSitterServicesOptionsSaveFetch;
