import { TActionCreator } from 'store/TActionCreator';

export const FIRE_DELETE_PET = 'FIRE_DELETE_PET';

export const fireDeletePet: TActionCreator<
  typeof FIRE_DELETE_PET,
  { id: number }
> = (payload) => ({
  type: FIRE_DELETE_PET,
  payload,
});
