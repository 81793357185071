import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  ReactNode,
} from 'react';
import cn from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import LoadingPaws from 'components/LoadingPaws/LoadingPaws';

import styles from './Button.module.css';

export type ComponentProps = {
  size?: 's' | 'm' | 'l';
  view?: 'button' | 'ghost';
  mode?: 'primary' | 'secondary' | 'tertiary' | 'dark' | 'white' | 'error';
  leftAddon?: ReactNode;
  block?: boolean;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorButtonProps = ComponentProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;
type NativeButtonProps = ComponentProps &
  ButtonHTMLAttributes<HTMLButtonElement>;
export type ButtonProps = Partial<AnchorButtonProps | NativeButtonProps>;

const Button: FC<ButtonProps> = ({
  size = 's',
  view = 'button',
  leftAddon,
  children,
  className,
  block,
  href,
  mode = 'primary',
  loading,
  disabled,
  ...restProps
}) => {
  const componentProps = {
    disabled: disabled || loading,
    className: cn(
      styles.container,
      styles[view],
      styles[mode],
      styles[size],
      {
        [styles.loading]: loading,
        [styles.block]: block,
        [styles.disabled]: disabled,
      },
      className,
    ),
  };
  const componentContent = (
    <>
      {leftAddon && <div className={styles.leftAddon}>{leftAddon}</div>}
      {children}
      {loading && <LoadingPaws />}
    </>
  );

  if (href) {
    if (href.match(/^http/)) {
      return (
        <a
          {...componentProps}
          {...(restProps as AnchorHTMLAttributes<HTMLAnchorElement>)}
          href={href}
        >
          {componentContent}
        </a>
      );
    }
    const linkProps = {
      ...componentProps,
      ...restProps,
      to: href,
    };
    return <Link {...(linkProps as LinkProps)}>{componentContent}</Link>;
  }

  return (
    <button
      type="button"
      {...componentProps}
      {...(restProps as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {componentContent}
    </button>
  );
};

export default Button;
