import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setBecomeSitterServices } from 'store/actions/sitter/setBecomeSitterServices';

const setBecomeSitterServicesHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setBecomeSitterServices>,
): TSitterState => {
  return {
    ...state,
    becomeSitterServices: payload.services,
  };
};

export default setBecomeSitterServicesHandler;
