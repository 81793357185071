import { combineReducers } from 'redux';

import { TAppState } from './TAppState';
import userReducer from './reducers/user/userReducer';
import mainReducer from './reducers/main/mainReducer';
import chatReducer from './reducers/chat/chatReducer';
import sitterReducer from './reducers/sitter/sitterReducer';
import profileReducer from './reducers/profile/profileReducer';
import clientReducer from './reducers/client/clientReducer';

const rootReducer = combineReducers<TAppState>({
  user: userReducer,
  main: mainReducer,
  chat: chatReducer,
  sitter: sitterReducer,
  profile: profileReducer,
  client: clientReducer,
});

export default rootReducer;
