import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { setBecomeSitterStep } from 'store/actions/sitter/setBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';
import { fireBecomeSitterProfileSave } from 'store/actions/sitter/fireBecomeSitterProfileSave';
import becomeSitterProfileSaveFetch from 'api/sitter/becomeSitterProfileSave/becomeSitterProfileSaveFetch';

function* becomeSitterProfileSaveSaga({
  payload,
}: ReturnType<typeof fireBecomeSitterProfileSave>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: becomeSitterProfileSaveFetch,
      payload: { ...payload.profile, isUpdate: payload.isUpdate },
    });
    if (response.type === EResponseType.OK) {
      yield put(setBecomeSitterStep({ step: EBecomeSitterStep.Moderation }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default becomeSitterProfileSaveSaga;
