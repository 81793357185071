import React, { FC } from 'react';

const Paw: FC = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="transparent"
      strokeWidth="2"
      stroke="currentColor"
      d="M12.12 13c-2.42 0-5.81 3.48-6 6.71-.2 3.42 3.7 1.43 4.72.81a2.29 2.29 0 012.66.09c.6.49 5 2.81 4.59-.9-.33-3.4-3.28-6.64-5.97-6.71zM2.61 8.64a2.67 2.67 0 00.15 4.57c2 1.34 3.82-1 2.87-1.88-1.29-1.17-1.05-3.69-3.02-2.69zM6.16 4.63c-.29 1.32 1.15 5 3.2 3.76s1.73-4.39.74-5.56c-1.21-1.48-3.47-.39-3.94 1.8zM14.75 3.11c-.53.81-.95 4.09.59 5.26 1.78 1.36 4.35-2.37 2.93-4.88a2.09 2.09 0 00-3.52-.38zM21.66 8.69c-1.11-.17-1.42 1.49-2.85 2.87-1.61 1.55 2.54 2.6 3.59 1 .66-1.07.76-3.63-.74-3.87z"
    />
  </svg>
);

export default Paw;
