import { TClientState } from 'store/reducers/client/TClientState';
import { successUploadPetPhotos } from 'store/actions/client/successUploadPetPhotos';

const successUploadPetPhotosHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successUploadPetPhotos>,
): TClientState => {
  if (!state.pets) {
    return state;
  }

  const petIndex = state.pets.findIndex((pet) => pet.id === payload.petId);
  const pet = state.pets[petIndex];

  return {
    ...state,
    uploadedPetPhotos: payload.photos,
    pets: [
      ...state.pets.slice(0, petIndex),
      {
        ...pet,
        photos: [...pet.photos, ...payload.photos],
      },
      ...state.pets.slice(petIndex + 1),
    ],
  };
};

export default successUploadPetPhotosHandler;
