import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TSendCodeParameters } from './types/TSendCodeParameters';

const sendCodeFetch = async ({
  phone,
}: TSendCodeParameters): Promise<TPayload<unknown> | never> => {
  const url = new URL(`${API_URL}/code`);
  const body = {
    email: phone,
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default sendCodeFetch;
