import { takeLatest, takeEvery } from 'redux-saga/effects';

import { LOAD_DIALOGUES } from 'store/actions/chat/loadDialogues';
import { SEND_MESSAGE } from 'store/actions/chat/sendMessage';
import { FIRE_MESSAGES_AS_READ } from 'store/actions/chat/fireMessagesAsRead';
import { FIRE_LOAD_MORE_MESSAGES } from 'store/actions/chat/fireLoadMoreMessages';
import { NEW_MESSAGE } from 'store/actions/chat/newMessage';
import { SET_CURRENT_DIALOGUE } from 'store/actions/chat/setCurrentDialogue';

import loadDialoguesSaga from './loadDialoguesSaga';
import sendMessageSaga from './sendMessageSaga';
import messagesAsReadSaga from './messagesAsReadSaga';
import loadMoreMessagesSaga from './loadMoreMessagesSaga';
import newMessageSaga from './newMessageSaga';
import setCurrentDialogueSaga from './setCurrentDialogueSaga';

export default function* rootChatSaga() {
  yield takeLatest(LOAD_DIALOGUES, loadDialoguesSaga);
  yield takeEvery(SEND_MESSAGE, sendMessageSaga);
  yield takeLatest(FIRE_MESSAGES_AS_READ, messagesAsReadSaga);
  yield takeLatest(FIRE_LOAD_MORE_MESSAGES, loadMoreMessagesSaga);
  yield takeLatest(NEW_MESSAGE, newMessageSaga);
  yield takeLatest(SET_CURRENT_DIALOGUE, setCurrentDialogueSaga);
}
