import { TActionCreator } from 'store/TActionCreator';

export const FIRE_CANCEL_SITTER_REQUEST = 'FIRE_CANCEL_SITTER_REQUEST';

export const fireCancelSitterRequest: TActionCreator<
  typeof FIRE_CANCEL_SITTER_REQUEST,
  { requestId: number }
> = (payload) => ({
  type: FIRE_CANCEL_SITTER_REQUEST,
  payload,
});
