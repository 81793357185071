import React from 'react';
import { render } from 'react-dom';
import createMiddlewareSaga from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { initLang } from 'libs/langs/langs';

import rootReducer from './store/rootReducer';
import rootSaga from './store/rootSaga';
import App from './App';

const sagaMiddleware = createMiddlewareSaga();
const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  : (f: any) => f;

const store = createStore(
  rootReducer,
  compose(applyMiddleware(sagaMiddleware), reduxDevTools),
);

sagaMiddleware.run(rootSaga);

initLang();

Sentry.init({
  dsn:
    'https://a6522c18889840f1bcdca3d60e395535@o923577.ingest.sentry.io/5871028',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
