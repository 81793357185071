import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadPetsPayload } from './types/TLoadPetsPayload';
import loadPetsCleaner from './loadPetsCleaner';

const loadPetsFetch = async (): Promise<TLoadPetsPayload | never> => {
  const url = new URL(`${API_URL}/pets?include=type,files,avatar,breed`);

  return apiFetch(loadPetsCleaner, url.toString());
};

export default loadPetsFetch;
