import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import loadReviewsFetch from 'api/client/loadReviews/loadReviewsFetch';
import { successLoadReviews } from 'store/actions/client/successLoadReviews';
import selectReviews from 'store/selectors/client/selectReviews';
import { fireLoadReviews } from 'store/actions/client/fireLoadReviews';

function* loadReviewsSaga({
  payload,
}: ReturnType<typeof fireLoadReviews>): SagaIterator<void> {
  try {
    const reviews = yield select(selectReviews);
    const response = yield call(fetchSaga, {
      fetcher: loadReviewsFetch,
      payload: {
        id: payload.sitterId,
        page: Math.floor(reviews.length / 15) + 1,
      },
    });

    if (response.type === EResponseType.OK) {
      yield put(
        successLoadReviews({
          list: response.data.reviews,
          total: response.data.total,
        }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default loadReviewsSaga;
