import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_INITIAL = 'SUCCESS_INITIAL';

export const successInitial: TActionCreator<
  typeof SUCCESS_INITIAL,
  { loggedIn: boolean; accessToken?: string }
> = (payload) => ({
  type: SUCCESS_INITIAL,
  payload,
});
