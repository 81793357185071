import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import loadDialoguesFetch from 'api/chat/loadDialogues/loadDialoguesFetch';
import { successLoadDialogues } from 'store/actions/chat/successLoadDialogues';
import fetchSaga from 'store/sagas/fetchSaga';
import { loadDialogues } from 'store/actions/chat/loadDialogues';
import selectContacts from 'store/selectors/chat/selectContacts';
import { setMoreDialoguesLoading } from 'store/actions/chat/setMoreDialoguesLoading';

function* loadDialoguesSaga({
  payload,
}: ReturnType<typeof loadDialogues>): SagaIterator<void> {
  try {
    let page = 1;
    if (payload.more) {
      const dialogues = yield select(selectContacts);
      page = Math.floor(dialogues.length / 20) + 1;
      yield put(setMoreDialoguesLoading());
    }
    const response = yield call(fetchSaga, {
      fetcher: loadDialoguesFetch,
      payload: {
        page,
      },
    });
    yield put(successLoadDialogues({ dialogues: response.data.dialogues }));
    // eslint-disable-next-line no-empty
  } catch {}
}

export default loadDialoguesSaga;
