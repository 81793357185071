import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const loadReviewsCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    reviews: rawPayloadData.data.map((rawReview) => ({
      author: rawReview.user.short_name,
      text: rawReview.review,
      avatar: rawReview.avatar.path,
      rating: rawReview.rating,
      createdAt: new Date(rawReview.created_at),
    })),
    total: rawPayloadData.total,
  }
};

export default loadReviewsCleaner;
