import React, { FC } from 'react';

const PetHuge: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.048 7.186l-3.59-.598a5.25 5.25 0 00-4.27-3.245l-1.337-.149-2.11-.234a1.04 1.04 0 00-.116-.007 3.168 3.168 0 00-3.164 3.164V8.93c0 .38.068.747.192 1.085a4.104 4.104 0 01-.93 1.369L5.286.22A1.055 1.055 0 004.64 0H1.125a1.055 1.055 0 000 2.11H4.28l13.394 10.356c-.1.02-.202.037-.304.05l-8.636 1.08c-.313.038-.618.099-.915.18l-1.253-.724A4.562 4.562 0 014.29 9.388a1.669 1.669 0 00-1.293-1.526 1.668 1.668 0 00-1.839.8 8.097 8.097 0 00-.88 5.874 8.115 8.115 0 002.724 4.434 6.665 6.665 0 00-.12 1.253v4.509l-2.58 3.226c-.15.187-.232.42-.232.66v6.327C.07 35.528.543 36 1.125 36h4.219c.582 0 1.054-.472 1.054-1.055V30.85a6.082 6.082 0 004.22-5.807v-.413l1.762.801a23.748 23.748 0 004.565 1.541v7.973c0 .583.473 1.055 1.055 1.055H26.437c.583 0 1.055-.472 1.055-1.055V25.07a6.611 6.611 0 003.516-5.864V13.5h1.055a3.872 3.872 0 003.867-3.867V8.227c0-.516-.373-.956-.882-1.04zM22.57 6.117c0-.565.447-1.028 1.007-1.053l1.103.122V8.93a1.056 1.056 0 01-2.11 0V6.117zM2.441 10.978a6.665 6.665 0 003.07 3.9l.03.018a6.727 6.727 0 00-1.73 1.94 6.076 6.076 0 01-1.37-5.858zm16.614 16.374c.697.093 1.4.157 2.11.188v6.35h-2.11v-6.538zm6.99-3.933c-.4.16-.662.548-.662.98v9.492h-2.11v-7.383c0-.237-.079-.456-.211-.632v-.001l-.421-.563a7.43 7.43 0 01-1.477-4.43 1.055 1.055 0 00-2.11 0c0 1.558.394 3.104 1.124 4.474a21.509 21.509 0 01-6.925-1.845l-3.254-1.479a1.054 1.054 0 00-1.491.96v2.051a3.982 3.982 0 01-3.338 3.94 1.055 1.055 0 00-.88 1.04v3.868H2.18v-4.904L4.76 25.76c.15-.187.231-.419.231-.658v-4.879c0-2.3 1.721-4.25 4.004-4.535l8.635-1.08a6.196 6.196 0 001.988-.599 13.904 13.904 0 009.28 3.094v2.103c0 1.866-1.12 3.52-2.853 4.213zM33.82 9.633a1.76 1.76 0 01-1.758 1.758h-2.109c-.582 0-1.055.472-1.055 1.054v2.548a11.793 11.793 0 01-7.518-2.287c.284-.3.539-.63.759-.984.443.237.95.372 1.486.372a3.168 3.168 0 003.164-3.164V5.42l.166.019a3.128 3.128 0 012.678 2.34c.102.408.436.716.85.785l3.337.556v.513z"
      fill="currentColor"
    />
  </svg>
);

export default PetHuge;
