import { TClientState } from 'store/reducers/client/TClientState';
import { successLoadReviews } from 'store/actions/client/successLoadReviews';

const successLoadReviewsHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successLoadReviews>,
): TClientState => ({
  ...state,
  reviews: [...state.reviews, ...payload.list],
  reviewsTotal: payload.total,
  reviewsLoading: false,
});

export default successLoadReviewsHandler;
