import { TClientState } from 'store/reducers/client/TClientState';
import { successSaveOrder } from 'store/actions/client/successSaveOrder';

const successSaveOrderHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successSaveOrder>,
): TClientState => {
  return {
    ...state,
    orderSaving: false,
    orders: [payload.order, ...(state.orders || [])],
  };
};

export default successSaveOrderHandler;
