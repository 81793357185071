import React, { FC } from 'react';

const PetBig: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.004 9.984h-2.615a.766.766 0 01-.735-.607c-.307-1.298-1.404-2.205-2.668-2.205h-.29V5.414c0-.582-.447-1.055-.997-1.055-2.38 0-4.316 2.05-4.316 4.57a6.22 6.22 0 01-.64 2.759L8.743.244A.963.963 0 008.104 0H4.996C4.446 0 4 .472 4 1.055c0 .582.446 1.054.996 1.054h2.75l12.779 11.25c-1.002.96-2.331 1.547-3.787 1.547h-3.984c-1.982 0-3.79.804-5.149 2.12C3.931 17.229 1 20.458 1 24.398c0 .583.446 1.055.996 1.055s.996-.472.996-1.055c0-2.082 1.147-3.887 2.806-4.743a8.44 8.44 0 00-.68 3.337v11.953c0 .583.445 1.055.995 1.055h3.32c.516 0 .946-.416.993-.96l.59-6.88c1.137-.262 2.107-.999 2.716-2.004h8.65v8.79c0 .582.447 1.054.997 1.054h3.32c.523 0 .956-.428.994-.98l.643-9.542a12.157 12.157 0 002.016-6.705v-1.406c0-.194.148-.351.332-.351h.664c2.014 0 3.652-1.735 3.652-3.868V11.04c0-.582-.446-1.055-.996-1.055zM26.566 24.47a1.092 1.092 0 00-.196.557l-.598 8.865h-1.397V22.289c0-.582-.446-1.055-.996-1.055s-.996.473-.996 1.055v1.758H14.4c.008-.116.014-.233.014-.352V22.29c0-.582-.446-1.055-.996-1.055s-.996.473-.996 1.055v1.406c0 1.357-1.043 2.461-2.324 2.461-.52 0-.946.422-.992.96l-.582 6.775H7.109V22.992c0-3.295 2.533-5.976 5.645-5.976h3.984c2.118 0 4.036-.918 5.42-2.396l6.201 4.04v.113c0 2.04-.636 4.063-1.793 5.696zm6.442-11.32c0 .969-.745 1.757-1.66 1.757h-.664c-.93 0-1.734.582-2.106 1.42l-5.206-3.393a8.404 8.404 0 001.003-4.003c0-.98.544-1.828 1.328-2.224v1.52c0 .583.446 1.055.996 1.055h1.287c.349 0 .65.25.735.608.307 1.298 1.404 2.205 2.668 2.205h1.619v1.054z"
      fill="currentColor"
    />
  </svg>
);

export default PetBig;
