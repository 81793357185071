import { TActionCreator } from 'store/TActionCreator';

export const FIRE_UPLOAD_PET_PHOTOS = 'FIRE_UPLOAD_PET_PHOTOS';

export const fireUploadPetPhotos: TActionCreator<
  typeof FIRE_UPLOAD_PET_PHOTOS,
  { petId: number; photos: File[] }
> = (payload) => ({
  type: FIRE_UPLOAD_PET_PHOTOS,
  payload,
});
