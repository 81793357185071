import { TActionCreator } from 'store/TActionCreator';

export const FIRE_LOAD_REVIEWS = 'FIRE_LOAD_REVIEWS';

export const fireLoadReviews: TActionCreator<
  typeof FIRE_LOAD_REVIEWS,
  { sitterId: number }
> = (payload) => ({
  type: FIRE_LOAD_REVIEWS,
  payload,
});
