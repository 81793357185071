import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TGetProfilePayload } from 'api/profile/getProfile/types/TGetProfilePayload';

import getProfileCleaner from './getProfileCleaner';

const getProfileFetch = async (): Promise<TGetProfilePayload | never> => {
  const url = new URL(`${API_URL}/user/profile`);
  url.search = new URLSearchParams({
    include: 'avatar,files,sitting_settings,walking_settings',
  }).toString();

  return apiFetch(getProfileCleaner, url.toString());
};

export default getProfileFetch;
