import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { successCancelSitterRequest } from 'store/actions/sitter/successCancelSitterRequest';

const successCancelSitterRequestHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof successCancelSitterRequest>,
): TSitterState => {
  if (!state.requests) {
    return state;
  }

  return {
    ...state,
    requests: state.requests.filter(
      (request) => request.id !== payload.requestId,
    ),
  };
};

export default successCancelSitterRequestHandler;
