import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireBecomeSitterServicesSave } from 'store/actions/sitter/fireBecomeSitterServicesSave';
import becomeSitterServicesSaveFetch from 'api/sitter/becomeSitterServicesSave/becomeSitterServicesSaveFetch';
import { setBecomeSitterStep } from 'store/actions/sitter/setBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';
import EResponseType from 'api/enums/EResponseType';
import { setBecomeSitterServices } from 'store/actions/sitter/setBecomeSitterServices';
import EBecomeSitterServices from 'store/reducers/sitter/EBecomeSitterServices';

function* becomeSitterServicesSaveSaga({
  payload,
}: ReturnType<typeof fireBecomeSitterServicesSave>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: becomeSitterServicesSaveFetch,
      payload,
    });
    if (response.type === EResponseType.OK) {
      yield put(
        setBecomeSitterStep({ step: EBecomeSitterStep.ServicesOptions }),
      );
      const services = [];
      if (payload.sitter) {
        services.push(EBecomeSitterServices.Sitter);
      }
      if (payload.walker) {
        services.push(EBecomeSitterServices.Walker);
      }
      yield put(setBecomeSitterServices({ services }));
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default becomeSitterServicesSaveSaga;
