import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { fireCheckCode } from 'store/actions/user/fireCheckCode';
import checkCodeFetch from 'api/user/checkCode/checkCodeFetch';
import EResponseType from 'api/enums/EResponseType';
import { successCheckCode } from 'store/actions/user/successCheckCode';
import fetchSaga from 'store/sagas/fetchSaga';

function* checkCodeSaga({
  payload,
}: ReturnType<typeof fireCheckCode>): SagaIterator<void> {
  try {
    const phone: string = yield select((state) => state.user.checkPhone);
    const response = yield call(fetchSaga, {
      fetcher: checkCodeFetch,
      payload: { phone, code: payload.code },
    });
    if (response.type === EResponseType.OK) {
      yield put(successCheckCode({ correctCode: payload.code }));
    } else {
      yield put(successCheckCode({ correctCode: false }));
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default checkCodeSaga;
