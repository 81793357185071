import { TActionCreator } from 'store/TActionCreator';

export const SET_DIALOGUE_TYPING = 'SET_DIALOGUE_TYPING';

export const setDialogueTyping: TActionCreator<
  typeof SET_DIALOGUE_TYPING,
  { typing: boolean }
> = (payload) => ({
  type: SET_DIALOGUE_TYPING,
  payload,
});
