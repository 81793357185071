import { TClientState } from 'store/reducers/client/TClientState';
import { setCurrentOrder } from 'store/actions/client/setCurrentOrder';

const setCurrentOrderHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof setCurrentOrder>,
): TClientState => {
  return {
    ...state,
    currentOrder: payload.id,
  };
};

export default setCurrentOrderHandler;
