import { TClientState } from 'store/reducers/client/TClientState';
import { fireSendRequest } from 'store/actions/client/fireSendRequest';

const fireSendRequestHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof fireSendRequest>,
): TClientState => {
  return {
    ...state,
    requestSendingOrderId: payload.orderId,
  };
};

export default fireSendRequestHandler;
