import { TActionCreator } from 'store/TActionCreator';

export const FIRE_BECOME_SITTER_SERVICES_OPTIONS_SAVE =
  'FIRE_BECOME_SITTER_SERVICES_OPTIONS_SAVE';

export type TFireBecomeSitterServicesOptionsSavePayload = {
  sitter?: {
    price: number;
    petTypes: number[];
    dogsSizes?: number[];
    catsSizes?: number[];
  };
  walker?: {
    price: number;
    dogsSizes: number[];
  };
};

export const fireBecomeSitterServicesOptionsSave: TActionCreator<
  typeof FIRE_BECOME_SITTER_SERVICES_OPTIONS_SAVE,
  TFireBecomeSitterServicesOptionsSavePayload
> = (payload) => ({
  type: FIRE_BECOME_SITTER_SERVICES_OPTIONS_SAVE,
  payload,
});
