import { TActionCreator } from 'store/TActionCreator';
import { TMedia } from 'store/reducers/chat/TMedia';

export const SEND_MESSAGE = 'SEND_MESSAGE';

export const sendMessage: TActionCreator<
  typeof SEND_MESSAGE,
  {
    profileId: number;
    dialogueId: number;
    text: string;
    media: TMedia[];
    date: Date;
  }
> = (payload) => ({
  type: SEND_MESSAGE,
  payload,
});
