import { TActionCreator } from 'store/TActionCreator';

export const FIRE_REGISTER = 'FIRE_REGISTER';

export const fireRegister: TActionCreator<
  typeof FIRE_REGISTER,
  { password: string; repeatPassword: string; name: string; phone: string; address: string; }
> = (payload) => ({
  type: FIRE_REGISTER,
  payload,
});
