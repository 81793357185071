import { TAppState } from 'store/TAppState';
import { TMessage } from 'store/reducers/chat/TMessage';

const selectDialogueMessages = (state: TAppState): TMessage[] => {
  const { currentDialogueId } = state.chat;
  if (!currentDialogueId) {
    return [];
  }
  const dialogue = state.chat.dialogues.find(
    (dialogue) => currentDialogueId === dialogue.id,
  );
  if (!dialogue) {
    return [];
  }
  return dialogue.messages;
};

export default selectDialogueMessages;
