import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { sendMessage } from 'store/actions/chat/sendMessage';
import sendMessageFetch from 'api/chat/sendMessage/sendMessageFetch';
import { successSendMessage } from 'store/actions/chat/successSendMessage';
import fetchSaga from 'store/sagas/fetchSaga';
import { failSendMessage } from 'store/actions/chat/failSendMessage';

function* sendMessageSaga({
  payload,
}: ReturnType<typeof sendMessage>): SagaIterator<void> {
  try {
    const { text, media, date, dialogueId } = payload;
    const response = yield call(fetchSaga, {
      fetcher: sendMessageFetch,
      payload: { text, media, dialogueId },
    });
    if (response.error) {
      yield put(
        failSendMessage({
          dialogueId,
          date,
        }),
      );
    } else {
      const { id, date: realDate } = response.data;
      yield put(
        successSendMessage({
          dialogueId,
          date,
          realDate,
          id,
        }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default sendMessageSaga;
