import React, { FC } from 'react';

const PetMedium: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.041 11.771a1.659 1.659 0 011.823.387 15.191 15.191 0 004.855 3.379 6.321 6.321 0 012.84-.974l4.876-.443a6.352 6.352 0 005.422-4.187L21.13 7.9a3.671 3.671 0 01.456-3.345A3.671 3.671 0 0124.584 3h3.28c.83 0 1.643.285 2.29.803l2.33 1.864h1.649a1 1 0 011 1V9.84a3.076 3.076 0 01-2.107 2.916 2.772 2.772 0 00-1.717 3.605c.842 2.249.689 4.812-.422 7.032l-.42.843v3.602c1.163.413 2 1.525 2 2.829V32a1 1 0 01-1 1h-10a1 1 0 01-1-1v-5.447a28.92 28.92 0 01-4.902-.02l-.475 1.425a3.004 3.004 0 011.71 2.709V32a1 1 0 01-1 1H5.466a1 1 0 01-1-1v-4a1 1 0 01.072-.371l1.261-3.155v-2.627A5.81 5.81 0 011 16.133v-2.816c0-.683.41-1.29 1.042-1.546zM22.466 31h2v-5.055a28.84 28.84 0 01-2 .376V31zm-7.667-.333c0-.552-.448-1-1-1a1 1 0 01-.948-1.317l.69-2.07a28.84 28.84 0 01-2.087-.415l-.698 2.094a3.004 3.004 0 011.71 2.708V31H14.8v-.333zM6.466 31h4v-.333c0-.552-.448-1-1-1a1 1 0 01-.949-1.317l1.334-4a.999.999 0 01.706-.652v-.001a2.517 2.517 0 001.91-2.446v-.584a1 1 0 012 0v.584a4.512 4.512 0 01-1.11 2.969c3.678.664 7.466.554 11.11-.33v-2.557a1 1 0 012 0V31h4v-.333c0-.552-.45-1-1-1a1 1 0 01-1-1V24a1 1 0 01.105-.447l.527-1.054c.862-1.725.985-3.707.337-5.437a4.77 4.77 0 012.955-6.202c.444-.149.742-.559.742-1.02V7.667h-1a1 1 0 01-.625-.22l-2.603-2.082A1.672 1.672 0 0027.865 5h-3.281c-.55 0-1.046.258-1.363.707a1.647 1.647 0 00-.207 1.52l.885 2.479a.312.312 0 00.307.22.312.312 0 00.316-.207l.851-2.095a1 1 0 111.853.752l-.851 2.096a2.315 2.315 0 01-2.212 1.454 2.345 2.345 0 01-.916-.205 8.342 8.342 0 01-6.631 4.39l-4.875.444a4.336 4.336 0 00-2.205.839 1 1 0 01-.16.125A4.315 4.315 0 007.8 20.87v3.796a1 1 0 01-.071.371l-1.262 3.155V31zM3 16.133a3.806 3.806 0 002.889 3.69 6.31 6.31 0 011.235-2.826A17.13 17.13 0 013 14.116v2.017z"
      fill="currentColor"
    />
  </svg>
);

export default PetMedium;
