import { TActionCreator } from 'store/TActionCreator';

export const SET_IS_TABLET = 'SET_IS_TABLET';

export const setIsTablet: TActionCreator<
  typeof SET_IS_TABLET,
  { isTablet: boolean }
> = (payload) => ({
  type: SET_IS_TABLET,
  payload,
});
