import { TActionCreator } from 'store/TActionCreator';

export const FIRE_SEND_REQUEST = 'FIRE_SEND_REQUEST';

export const fireSendRequest: TActionCreator<
  typeof FIRE_SEND_REQUEST,
  { userId: number; orderId: number }
> = (payload) => ({
  type: FIRE_SEND_REQUEST,
  payload,
});
