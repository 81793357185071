import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import loadSitterFetch from 'api/client/loadSitter/loadSitterFetch';
import { fireLoadSitterData } from 'store/actions/client/fireLoadSitterData';
import { successLoadSitterData } from 'store/actions/client/successLoadSitterData';
import loadReviewsFetch from 'api/client/loadReviews/loadReviewsFetch';
import { successLoadReviews } from 'store/actions/client/successLoadReviews';

function* loadSitterSaga({
  payload,
}: ReturnType<typeof fireLoadSitterData>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: loadSitterFetch,
      payload: {
        id: payload.id,
      },
    });

    const reviewsResponse = yield call(fetchSaga, {
      fetcher: loadReviewsFetch,
      payload: {
        id: payload.id,
        page: 1,
      },
    });

    if (
      response.type === EResponseType.OK &&
      reviewsResponse.type === EResponseType.OK
    ) {
      yield put(successLoadSitterData({ data: response.data }));
      yield put(
        successLoadReviews({
          list: reviewsResponse.data.reviews,
          total: reviewsResponse.data.total,
        }),
      );
    } else {
      payload.historyPush('/not-found');
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default loadSitterSaga;
