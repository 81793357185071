import { TClientState } from 'store/reducers/client/TClientState';

const successDeletePetHandler = (state: TClientState): TClientState => {
  if (!state.pets) {
    return state;
  }

  const petIndex = state.pets.findIndex(
    (pet) => pet.id === state.petDeletingId,
  );

  return {
    ...state,
    pets: [...state.pets.slice(0, petIndex), ...state.pets.slice(petIndex + 1)],
    petDeletingId: null,
  };
};

export default successDeletePetHandler;
