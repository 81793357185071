import { TOrder } from 'store/reducers/orders/TOrder';
import { TMessage } from './TMessage';

export enum EDialogueType {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Admin = 'ADMIN',
}

export type TDialogue = {
  id: number;
  name: string;
  image: string;
  type: EDialogueType;
  messages: TMessage[];
  order?: TOrder;
  orders: TOrder[];
  totalMessages: number;
};
