import { TActionCreator } from 'store/TActionCreator';

export const FIRE_APPROVE_REQUEST = 'FIRE_APPROVE_REQUEST';

export const fireApproveRequest: TActionCreator<
  typeof FIRE_APPROVE_REQUEST,
  { id: number; historyPush: (path: string) => void; orderId: number }
> = (payload) => ({
  type: FIRE_APPROVE_REQUEST,
  payload,
});
