import { TActionCreator } from 'store/TActionCreator';

export const FIRE_LOGIN = 'FIRE_LOGIN';

export const fireLogin: TActionCreator<
  typeof FIRE_LOGIN,
  { user: string; password: string }
> = (payload) => ({
  type: FIRE_LOGIN,
  payload,
});
