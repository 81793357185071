import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { successAcceptSitterRequest } from 'store/actions/sitter/successAcceptSitterRequest';

const successAcceptSitterRequestHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof successAcceptSitterRequest>,
): TSitterState => {
  if (!state.requests) {
    return state;
  }

  return {
    ...state,
    requests: state.requests.filter(
      (request) => request.id !== payload.requestId,
    ),
  };
};

export default successAcceptSitterRequestHandler;
