import { TRawErrorPayload } from 'api/types/TRawPayload';
import { TErrorPayload } from 'api/types/TPayload';
import EResponseType from 'api/enums/EResponseType';

const cleanErrorPayload = ({
  error: { error_code: code, error_data: data },
}: TRawErrorPayload): TErrorPayload => ({
  type: EResponseType.RESPONSE_ERROR,
  error: {
    code,
    data,
  },
});

export default cleanErrorPayload;
