import React, { FC, ChangeEvent, useEffect } from 'react';
import Button from 'components/Button/Button';
import Paw from 'icons/Paw';
import Text from 'components/Text/Text';
import { getLang } from 'libs/langs/langs';
import Search from 'icons/Search';
import cn from 'classnames';
import Logo from 'icons/Logo';
import User from 'icons/User';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobileMenuOpened } from 'store/actions/main/setIsMobileMenuOpened';
import { useHistory } from 'react-router-dom';
import selectIsLoggedIn from 'store/selectors/user/selectIsLoggedIn';
import Logout from 'icons/Logout';
import { fireLogout } from 'store/actions/user/fireLogout';
import Chat from 'icons/Chat';
import Calendar from 'icons/Calendar';
import Cog from 'icons/Cog';
import selectRoles from 'store/selectors/profile/selectRoles';
import selectBecomeSitterStep from 'store/selectors/sitter/selectBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';
import Collar from 'icons/Collar';
import Receipt from 'icons/Receipt';
import Add from 'icons/Add';
import Briefcase from 'icons/Briefcase';
import globalConfig from 'config/globalConfig.json';

import { setLanguage, availableLanguages } from 'libs/langs/langs';
import selectCurrentLanguage from 'store/selectors/main/selectCurrentLanguage';

import styles from './MenuMobile.module.css';

export type MenuMobileProps = {
  isShown: boolean;
};

const MenuMobile: FC<MenuMobileProps> = ({ isShown }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const history = useHistory();
  const roles = useSelector(selectRoles);
  const becomeSitterStep = useSelector(selectBecomeSitterStep);

  const currentLanguage = useSelector(selectCurrentLanguage);

  // Загрузить выбранный язык из локального хранилища при монтировании компонента
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setLanguage(dispatch, savedLanguage);
    }
  }, [dispatch]);

  const go = (to: string) => {
    dispatch(setIsMobileMenuOpened({ opened: false }));
    history.push(to);
  };

  const onLogoutClick = () => {
    dispatch(fireLogout());
    dispatch(setIsMobileMenuOpened({ opened: false }));
    history.push('/');
  };

  const handleChangeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLang = event.target.value;
    setLanguage(dispatch, newLang);
    localStorage.setItem('selectedLanguage', newLang);
    window.location.reload();
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.menuShown]: isShown,
      })}
    >
      <div>
        <div className={styles.logoWrapper}>
          <Button className={styles.logo} view="ghost" onClick={() => go('/')}>
            <Logo />
          </Button>
        </div>
        {isLoggedIn === null && (
          <div className={styles.buttonsSkeleton}>
            <div className={styles.becomeSitterSkeleton} />
            <div className={styles.findSitterSkeleton} />
            <div className={styles.loginSkeleton} />
          </div>
        )}
        {!globalConfig.IS_MVP && isLoggedIn !== null && (
          <>
            {isLoggedIn && (
              <Button
                className={styles.menuButton}
                mode="secondary"
                view="ghost"
                block
                leftAddon={<Add />}
                onClick={() => go('/cabinet/order/new')}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('MENU_CREATE_ORDER')}
                </Text>
              </Button>
            )}
            {roles.length === 0 && (
              <Button
                onClick={() =>
                  go(
                    becomeSitterStep &&
                      becomeSitterStep !== EBecomeSitterStep.Services
                      ? '/cabinet/become-sitter'
                      : '/become-sitter',
                  )
                }
                className={styles.menuButton}
                block
                view="ghost"
                mode="secondary"
                leftAddon={<Paw />}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('BECOME_SITTER')}
                </Text>
              </Button>
            )}
            {!globalConfig.IS_MVP && (
              <Button
                className={styles.menuButton}
                view="ghost"
                mode="secondary"
                block
                leftAddon={<Search />}
                onClick={() => go('/search')}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('FIND_A_SITTER')}
                </Text>
              </Button>
            )}
            {isLoggedIn && (
              <Button
                className={styles.menuButton}
                view="ghost"
                mode="secondary"
                block
                leftAddon={<User />}
                onClick={() => go('/cabinet/profile')}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('PROFILE')}
                </Text>
              </Button>
            )}
            {isLoggedIn && (
              <Button
                className={styles.menuButton}
                view="ghost"
                mode="secondary"
                block
                leftAddon={<Chat />}
                onClick={() => go('/cabinet/chat')}
              >
                <Text className={styles.menuButtonText}>{getLang('CHAT')}</Text>
              </Button>
            )}
            {isLoggedIn && (
              <Button
                onClick={() => go('/cabinet/pets')}
                className={styles.menuButton}
                block
                view="ghost"
                mode="secondary"
                leftAddon={<Collar />}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('MY_PETS')}
                </Text>
              </Button>
            )}
            {isLoggedIn && (
              <Button
                onClick={() => go('/cabinet/orders')}
                className={styles.menuButton}
                block
                view="ghost"
                mode="secondary"
                leftAddon={<Receipt />}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('MY_ORDERS')}
                </Text>
              </Button>
            )}
            {roles.length > 0 && (
              <Button
                onClick={() => go('/cabinet/find-order')}
                className={styles.menuButton}
                block
                view="ghost"
                mode="secondary"
                leftAddon={<Briefcase />}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('FIND_ORDER')}
                </Text>
              </Button>
            )}
            {roles.length > 0 && (
              <Button
                onClick={() => go('/cabinet/jobs')}
                className={styles.menuButton}
                block
                view="ghost"
                mode="secondary"
                leftAddon={<Calendar />}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('MY_JOBS')}
                </Text>
              </Button>
            )}
            {roles.length > 0 && (
              <Button
                onClick={() => go('/cabinet/sitter')}
                className={styles.menuButton}
                block
                view="ghost"
                mode="secondary"
                leftAddon={<Paw />}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('SITTER_SETTINGS')}
                </Text>
              </Button>
            )}
            {isLoggedIn && (
              <Button
                className={styles.menuButton}
                view="ghost"
                mode="secondary"
                block
                leftAddon={<Cog />}
                onClick={() => go('/cabinet/settings')}
              >
                <Text className={styles.menuButtonText}>{getLang('SETTINGS')}</Text>
              </Button>
            )}
            {!isLoggedIn && (
              <Button
                className={styles.menuButton}
                view="ghost"
                mode="secondary"
                block
                leftAddon={<User />}
                onClick={() => go('/login')}
              >
                <Text className={styles.menuButtonText}>
                  {getLang('LOG_IN')}
                </Text>
              </Button>
            )}
          </>
        )}
      </div>
      <div>
        {isLoggedIn && (
          <Button
            className={styles.menuButton}
            view="ghost"
            mode="secondary"
            block
            onClick={onLogoutClick}
            leftAddon={<Logout />}
          >
            <Text className={styles.menuButtonText}>{getLang('LOGOUT')}</Text>
          </Button>
        )}
        <div className={styles.bottomContainer}>
          {!globalConfig.IS_MVP && (
            <Button
              className={styles.menuButton}
              view="ghost"
              mode="secondary"
              block
              leftAddon={<User />}
            >
              <Text className={styles.menuButtonText}>{getLang('ABOUT')}</Text>
            </Button>
          )}
          <div className={styles.languageSelectorContainer}>
            <select
              onChange={handleChangeLanguage}
              className={styles.languageSelector}
              value={currentLanguage}
            >
              {availableLanguages.map(lang => (
                <option key={lang.code} value={lang.code}>{lang.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
