import { TChatState } from 'store/reducers/chat/TChatState';
import { successLoadMoreMessages } from 'store/actions/chat/successLoadMoreMessages';

const successLoadMoreMessagesHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof successLoadMoreMessages>,
): TChatState => {
  const { dialogues } = state;
  const dialogueIndex = dialogues.findIndex(
    (dialogue) => payload.dialogueId === dialogue.id,
  );
  if (dialogueIndex === -1) {
    return state;
  }
  const dialogue = dialogues[dialogueIndex];
  const loadedMessages = payload.messages.filter((message) =>
    dialogue.messages.every((prevMessage) => prevMessage.id !== message.id),
  );

  return {
    ...state,
    dialogueMoreMessagesLoading: false,
    dialogues: [
      ...dialogues.slice(0, dialogueIndex),
      {
        ...dialogue,
        messages: [...loadedMessages, ...dialogue.messages],
        totalMessages: payload.totalMessages,
      },
      ...dialogues.slice(dialogueIndex + 1),
    ],
  };
};

export default successLoadMoreMessagesHandler;
