import { TMainState } from 'store/reducers/main/TMainState';
import { setIsTablet } from 'store/actions/main/setIsTablet';

const setIsTabletHandler = (
  state: TMainState,
  { payload }: ReturnType<typeof setIsTablet>,
): TMainState => {
  return {
    ...state,
    isTablet: payload.isTablet,
  };
};

export default setIsTabletHandler;
