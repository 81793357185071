import { TUserState } from 'store/reducers/user/TUserState';

const fireLoginHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    loginError: null,
  };
};

export default fireLoginHandler;
