import { TActionCreator } from 'store/TActionCreator';
import { TSitterProfile } from 'store/reducers/sitter/TSitterProfile';

export const FIRE_SITTER_PROFILE_SAVE = 'FIRE_SITTER_PROFILE_SAVE';

export const fireSitterProfileSave: TActionCreator<
  typeof FIRE_SITTER_PROFILE_SAVE,
  { profile: TSitterProfile }
> = (payload) => ({
  type: FIRE_SITTER_PROFILE_SAVE,
  payload,
});
