import { TActionCreator } from 'store/TActionCreator';

export const SET_IS_MOBILE_MENU_OPENED = 'SET_IS_MOBILE_MENU_OPENED';

export const setIsMobileMenuOpened: TActionCreator<
	typeof SET_IS_MOBILE_MENU_OPENED,
	{ opened: boolean }
> = (payload) => ({
	type: SET_IS_MOBILE_MENU_OPENED,
	payload,
});
