import { TActionCreator } from 'store/TActionCreator';

export const FAIL_LOGIN = 'FAIL_LOGIN';

export enum ELoginError {
  PHONE_NOT_FOUND = 'LOGIN_ERROR_PHONE',
  WRONG_PASSWORD = 'LOGIN_WRONG_PASSWORD',
}

export const failLogin: TActionCreator<
  typeof FAIL_LOGIN,
  { code: ELoginError }
> = (payload) => ({
  type: FAIL_LOGIN,
  payload,
});
