import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import EResponseType from 'api/enums/EResponseType';
import { petsList, sizesList } from 'entities/pets';

import { TSearchSitterParameters } from './types/TSearchSitterParameters';
import { TSearchSitterPayload } from './types/TSearchSitterPayload';
import searchSitterCleaner from './searchSitterCleaner';
import mock from './mock.json';

const searchSitterFetch = async ({
  sitterInfo,
  page,
}: TSearchSitterParameters): Promise<TSearchSitterPayload | never> => {
  const {
    type,
    petType,
    petsCount,
    addressPoint,
    dateFrom,
    dateTo,
    petSize,
    priceFrom,
    priceTo,
    noDogs,
    noCats,
    noKids,
    canMedicine,
    canInjection,
    house,
    flat,
    haveDogs,
  } = sitterInfo;
  const url = new URL(`${API_URL}/filtered/users`);
  if (page) {
    url.search = new URLSearchParams({
      page: page.toString(),
    }).toString();
  }
  const body: any = {
    role_id: type,
    pet_types: petType.length > 0 ? petType : petsList.map((pet) => pet.id),
    pet_sizes: petSize.length > 0 ? petSize : sizesList.map((size) => size.id),
    price_from: priceFrom,
    price_to: priceTo,
    longitude: addressPoint[1],
    latitude: addressPoint[0],
  };
  if (noDogs) {
    body.without_dog = 1;
  }
  if (noCats) {
    body.without_cat = 1;
  }
  if (canMedicine) {
    body.give_medication = 1;
  }
  if (canInjection) {
    body.give_injection = 1;
  }
  if ((house && !flat) || (!house && flat)) {
    body.type_of_housing = house ? 1 : 2;
  }
  if (noKids) {
    body.has_children = false;
  }
  if (haveDogs) {
    body.have_dog = 1;
  }

  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       type: EResponseType.OK,
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       data: searchSitterCleaner(mock),
  //     });
  //   }, 500);
  // });

  return apiFetch(searchSitterCleaner, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default searchSitterFetch;
