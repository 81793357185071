import React, { FC, useEffect, useState, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import selectIsMobileMenuOpened from 'store/selectors/main/selectIsMobileMenuOpened';
import selectIsMobile from 'store/selectors/main/selectIsMobile';
import { setIsMobileMenuOpened } from 'store/actions/main/setIsMobileMenuOpened';
import selectIsLoggedIn from 'store/selectors/user/selectIsLoggedIn';
import selectCurrentDialogueId from 'store/selectors/chat/selectCurrentDialogueId';
import selectIsMobileOnly from 'store/selectors/main/selectIsMobileOnly';
import selectRequestFail from 'store/selectors/main/selectRequestFail';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import { getLang } from 'libs/langs/langs';

import Header from './Header';
import HeaderMobile from './HeaderMobile';
import MenuMobile from './MenuMobile';
import LoadingScreen from './LoadingScreen';
import NotFound from './NotFound';

import 'react-toastify/dist/ReactToastify.css';
import styles from './Main.module.css';

const Landing = lazy(() => import('containers/Landing/Landing'));
const Login = lazy(() => import('containers/Auth/Login'));
const CabinetMain = lazy(() => import('containers/Cabinet/Main'));
const LandingSitter = lazy(() => import('containers/Landing/LandingSitter'));

const FindSitter = lazy(() => import('./FindSitter'));
const SitterPage = lazy(() => import('./SitterPage'));
const Document = lazy(() => import('./Document'));

const AuthRoute: FC<{ path: string | string[]; exact?: boolean }> = ({
  path,
  exact,
  children,
}) => {
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Route exact={exact} path={path}>
      {/* {isLoggedIn === null && <LoadingScreen />} */}
      {isLoggedIn === false && (
        <Redirect
          to={{
            pathname: '/login',
            state: { referer: location.pathname },
          }}
        />
      )}
      {isLoggedIn && children}
    </Route>
  );
};

const NotAuthRoute: FC<{ path: string | string[]; exact?: boolean }> = ({
  path,
  exact,
  children,
}) => {
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const redirectTo = (location.state as any)?.referer || '/cabinet';

  return (
    <Route exact={exact} path={path}>
      {!isLoggedIn ? children : <Redirect to={redirectTo} />}
    </Route>
  );
};

const Main: FC = () => {
  const dispatch = useDispatch();
  const isMobileMenuOpened = useSelector(selectIsMobileMenuOpened);
  const isMobile = useSelector(selectIsMobile);
  const isMobileOnly = useSelector(selectIsMobileOnly);
  const [isMobileOverlayShown, setIsMobileOverlayShown] = useState(false);
  const currentDialogueId = useSelector(selectCurrentDialogueId);
  const requestFail = useSelector(selectRequestFail);

  useEffect(() => {
    if (isMobileMenuOpened) {
      setTimeout(() => {
        setIsMobileOverlayShown(true);
      }, 0);
    } else {
      setTimeout(() => {
        setIsMobileOverlayShown(false);
      }, 200);
    }
  }, [isMobileMenuOpened]);

  const onMobileOverlayClick = () => {
    dispatch(setIsMobileMenuOpened({ opened: false }));
  };

  return (
    <div className={styles.container}>
      <ToastContainer
        toastClassName={styles.toast}
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        transition={Slide}
        closeButton={false}
      />
      {isMobile && <MenuMobile isShown={isMobileMenuOpened} />}
      <div
        className={cn(styles.content, {
          [styles.contentMobile]: isMobile,
          [styles.mobileMenuOpened]: isMobileMenuOpened,
          [styles.mobileChatOpened]: isMobileOnly && currentDialogueId,
        })}
      >
        {isMobile ? <HeaderMobile /> : <Header />}
        <Suspense fallback={null}>
          <Switch>
            <AuthRoute
              exact
              path={[
                '/cabinet',
                '/cabinet/chat',
                '/cabinet/become-sitter',
                '/cabinet/settings',
                '/cabinet/profile',
                '/cabinet/sitter',
                '/cabinet/pets',
                '/cabinet/pet/:id',
                '/cabinet/orders',
                '/cabinet/order/:id',
                '/cabinet/make-request/:id',
                '/cabinet/select-sitter/:id',
                '/cabinet/find-order',
                '/cabinet/jobs',
              ]}
            >
              <CabinetMain />
            </AuthRoute>
            <NotAuthRoute path="/login">
              <Login />
            </NotAuthRoute>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/search">
              <FindSitter />
            </Route>
            <Route exact path="/become-sitter">
              <LandingSitter />
            </Route>
            <Route exact path="/sitter/:id">
              <SitterPage />
            </Route>
            <Route exact path="/agreement">
              <Document name="public_offer.pdf" />
            </Route>
            <Route exact path="/privacy-policy">
              <Document name="privacy_policy.pdf" />
            </Route>
            <Route exact path="/sitter-instruction">
              <Document name="instruction.pdf" />
            </Route>
            <Route exact path="/sitter-agreement">
              <Document name="sitter_agreement.pdf" />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
        {(isMobileMenuOpened || isMobileOverlayShown) && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className={cn(styles.mobileOverlay, {
              [styles.mobileOverlayShown]:
                isMobileOverlayShown && isMobileMenuOpened,
            })}
            onClick={onMobileOverlayClick}
          />
        )}
        <Modal opened={requestFail} size="m">
          <Text className={styles.requestFailText}>
            {getLang('REQUEST_FAIL_INFO')}
          </Text>
          <div className={styles.requestFailActionWrapper}>
            <Button
              mode="primary"
              view="button"
              onClick={() => window.location.reload()}
            >
              {getLang('REFRESH')}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Main;
