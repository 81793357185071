import React, { FC } from 'react';

const Hamburger: FC = () => (
	<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M23 13H1a1 1 0 010-2h22a1 1 0 010 2zM23 5.313H1a1 1 0 010-2h22a1 1 0 010 2zM23 20.646H1a1 1 0 010-2h22a1 1 0 010 2z"
			fill="currentColor"
		/>
	</svg>
);

export default Hamburger;
