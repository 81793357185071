import { TActionCreator } from 'store/TActionCreator';

export const FIRE_TYPING = 'FIRE_TYPING';

export const fireTyping: TActionCreator<
  typeof FIRE_TYPING,
  { dialogueId: number }
> = (payload) => ({
  type: FIRE_TYPING,
  payload,
});
