import { TClientState } from 'store/reducers/client/TClientState';
import { setSelectedPet } from 'store/actions/client/setSelectedPet';

const setSelectedPetHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof setSelectedPet>,
): TClientState => ({
  ...state,
  selectedPet: payload.id,
  uploadedPetPhotos: null,
});

export default setSelectedPetHandler;
