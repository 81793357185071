import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { setBecomeSitterStep } from 'store/actions/sitter/setBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';
import becomeSitterTestCompleteFetch from 'api/sitter/becomeSitterTestComplete/becomeSitterTestCompleteFetch';

function* becomeSitterProfileSaveSaga(): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: becomeSitterTestCompleteFetch,
    });
    if (response.type === EResponseType.OK) {
      yield put(setBecomeSitterStep({ step: EBecomeSitterStep.Profile }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default becomeSitterProfileSaveSaga;
