import { TActionCreator } from 'store/TActionCreator';
import EBecomeSitterServices from 'store/reducers/sitter/EBecomeSitterServices';

export const SET_BECOME_SITTER_SERVICES = 'SET_BECOME_SITTER_SERVICES';

export const setBecomeSitterServices: TActionCreator<
  typeof SET_BECOME_SITTER_SERVICES,
  { services: EBecomeSitterServices[] }
> = (payload) => ({
  type: SET_BECOME_SITTER_SERVICES,
  payload,
});
