import { TUserState } from 'store/reducers/user/TUserState';
import { failRegister } from 'store/actions/user/failRegister';

const failRegisterHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof failRegister>,
): TUserState => {
  return {
    ...state,
    registerError: payload.code,
  };
};

export default failRegisterHandler;
