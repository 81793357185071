import { TActionCreator } from 'store/TActionCreator';

export const FIRE_DELETE_PROFILE_PHOTO = 'FIRE_DELETE_PROFILE_PHOTO';

export const fireDeleteProfilePhoto: TActionCreator<
  typeof FIRE_DELETE_PROFILE_PHOTO,
  { id: number }
> = (payload) => ({
  type: FIRE_DELETE_PROFILE_PHOTO,
  payload,
});
