import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TDeletePhotoParameters } from './types/TDeletePhotoParameters';

const deletePhotoFetch = async ({
  id,
}: TDeletePhotoParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/files/${id}`);

  return apiFetch(() => null, url.toString(), {
    method: 'DELETE',
  });
};

export default deletePhotoFetch;
