import { TActionCreator } from 'store/TActionCreator';

export const SET_MOBILE_MENU_ENABLED = 'SET_MOBILE_MENU_ENABLED';

export const setMobileMenuEnabled: TActionCreator<
  typeof SET_MOBILE_MENU_ENABLED,
  { enabled: boolean }
> = (payload) => ({
  type: SET_MOBILE_MENU_ENABLED,
  payload,
});
