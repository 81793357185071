import { TClientState } from 'store/reducers/client/TClientState';
import { successApproveRequest } from 'store/actions/client/successApproveRequest';
import { EOrderStatus } from 'store/reducers/client/TOrder';

const successApproveRequestHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successApproveRequest>,
): TClientState => {
  const { orders, requests } = state;
  if (!orders || !requests) {
    return state;
  }

  const { orderId, id } = payload;
  const newOrders = [...orders];
  const orderIndex = orders.findIndex((order) => order.id === orderId);
  const request = requests.find((req) => req.id === id);
  const order = orders[orderIndex];
  newOrders[orderIndex] = {
    ...order,
    status: EOrderStatus.Confirmed,
    workerShortName: request!.workerName,
    totalPrice: Math.floor(request!.offer! * order.rate),
  };

  return {
    ...state,
    orders: newOrders,
  };
};

export default successApproveRequestHandler;
