import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import loadPetsFetch from 'api/client/loadPets/loadPetsFetch';
import { successLoadPets } from 'store/actions/client/successLoadPets';

function* loadSitterSaga(): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: loadPetsFetch,
    });

    if (response.type === EResponseType.OK) {
      yield put(successLoadPets({ pets: response.data }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default loadSitterSaga;
