import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { fireApproveRequest } from 'store/actions/client/fireApproveRequest';
import approveRequestFetch from 'api/client/approveRequest/approveRequestFetch';
import { successApproveRequest } from 'store/actions/client/successApproveRequest';

function* approveRequestSaga({
  payload,
}: ReturnType<typeof fireApproveRequest>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: approveRequestFetch,
      payload: { id: payload.id },
    });

    if (response.type === EResponseType.OK) {
      payload.historyPush('/cabinet/orders');
      yield put(
        successApproveRequest({
          orderId: payload.orderId,
          id: payload.id,
        }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default approveRequestSaga;
