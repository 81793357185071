import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TGetBecomeSitterStatusPayload } from './types/TGetBecomeSitterStatusPayload';
import getBecomeSitterStatusCleaner from './getBecomeSitterStatusCleaner';

const getBecomeSitterStatusFetch = async (): Promise<
  TGetBecomeSitterStatusPayload | never
> => {
  const url = new URL(`${API_URL}/worker/status`);

  return apiFetch(getBecomeSitterStatusCleaner, url.toString());
};

export default getBecomeSitterStatusFetch;
