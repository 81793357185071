import { TActionCreator } from 'store/TActionCreator';

export const FIRE_CHECK_PHONE = 'FIRE_CHECK_PHONE';

export const fireCheckPhone: TActionCreator<
  typeof FIRE_CHECK_PHONE,
  { email: string }
> = (payload) => ({
  type: FIRE_CHECK_PHONE,
  payload,
});
