import { TRawPayload } from 'api/types/TRawPayload';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'const';
import globalConfig from 'config/globalConfig.json';

const OK_CODES = [200, 201, 202, 204];
export const ACCESS_TOKEN_ERROR_CODE = 401;
export const PARAMS_ERROR_CODE = 422;
export const SERVER_ERROR_CODE = 500;
export const REQUEST_TIMEOUT_ERROR = 408;
const SERVER_DEFAULT_ERROR_MESSAGE = 'Server error. Please try again';
const BYTES_IN_MB = 1024 * 1024;

const serverErrorPayload = (message?: string) => ({
  error: {
    error_code: SERVER_ERROR_CODE,
    error_data: message || SERVER_DEFAULT_ERROR_MESSAGE,
  },
});

export default async function apiRequest<TPayload>(
  input: RequestInfo,
  init?: RequestInit,
  errorCallback?: (err: Error) => void,
): Promise<TRawPayload<TPayload>> {
  try {
    let reqInit = init;
    if (!reqInit) {
      reqInit = {};
    }
    if (!reqInit.headers) {
      reqInit.headers = {};
    }
    const accessToken = window.localStorage.getItem(
      LOCAL_STORAGE_ACCESS_TOKEN_NAME,
    );
    if (accessToken) {
      (reqInit.headers as any).Authorization = `Bearer ${accessToken}`;
    }
    (reqInit.headers as any).Accept = 'application/json';
    let apiTimeoutMs = globalConfig.API_TIMEOUT_MS;
    if (init?.body instanceof FormData) {
      let bodySize = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of init?.body.values()) {
        if (item instanceof Blob) {
          bodySize += item.size;
        } else {
          bodySize += item.length;
        }
      }
      apiTimeoutMs +=
        Math.ceil(bodySize / BYTES_IN_MB) *
        globalConfig.API_TIMEOUT_DELAY_MS_PER_MB;
    }
    if (typeof init?.body !== 'object') {
      (reqInit.headers as any)['Content-Type'] = 'application/json;';
    }
    const req = await new Promise<any>((resolve) => {
      const requestTimeout = window.setTimeout(() => {
        resolve({
          status: REQUEST_TIMEOUT_ERROR,
        });
      }, apiTimeoutMs);
      fetch(input, reqInit).then((response) => {
        window.clearTimeout(requestTimeout);
        resolve(response);
      });
    });
    if (req.status === REQUEST_TIMEOUT_ERROR) {
      return {
        error: {
          error_code: REQUEST_TIMEOUT_ERROR,
          error_data: 'Request timeout',
        },
      };
    }
    if (req.status === ACCESS_TOKEN_ERROR_CODE) {
      return {
        error: {
          error_code: ACCESS_TOKEN_ERROR_CODE,
          error_data: 'Not authorized',
        },
      };
    }
    if (!OK_CODES.includes(req.status) && req.status !== PARAMS_ERROR_CODE) {
      toast.error(getLang('SERVER_ERROR'));
      return serverErrorPayload();
    }
    const response = await req.json();
    if (response.error || response.errors) {
      const error = response.errors || response.error;
      return {
        error: {
          error_code: error.code,
          error_data: error,
        },
      };
    }
    return {
      response,
    };
  } catch (error) {
    if (errorCallback) {
      errorCallback(error);
    }
    return serverErrorPayload(error.message);
  }
}
