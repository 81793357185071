import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import getProfileFetch from 'api/profile/getProfile/getProfileFetch';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'const';
import { successInitial } from 'store/actions/main/successInitial';
import EResponseType from 'api/enums/EResponseType';
import fetchSaga from 'store/sagas/fetchSaga';
import { startWebSocket } from 'store/actions/main/startWebSocket';
import { TGetProfilePayload } from 'api/profile/getProfile/types/TGetProfilePayload';
import { successLoadProfile } from 'store/actions/profile/successLoadProfile';
import { initial } from 'store/actions/main/initial';
import loadSitterProfileFetch from 'api/sitter/loadSitterProfile/loadSitterProfileFetch';
import { setSitterProfile } from 'store/actions/sitter/setSitterProfile';
import { setSitterSettings } from 'store/actions/sitter/setSitterSettings';
import { fireGetBecomeSitterStatus } from 'store/actions/sitter/fireGetBecomeSitterStatus';
import { fireLoadPets } from 'store/actions/client/fireLoadPets';

function* initialSaga({
  payload,
}: ReturnType<typeof initial>): SagaIterator<void> {
  try {
    if (payload.accessToken) {
      localStorage.setItem(
        LOCAL_STORAGE_ACCESS_TOKEN_NAME,
        payload.accessToken,
      );
    }
    const accessToken =
      payload.accessToken ||
      localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
    if (accessToken) {
      const response: TGetProfilePayload = yield call(fetchSaga, {
        fetcher: getProfileFetch,
      });
      if (response.type === EResponseType.OK) {
        yield put(fireLoadPets());
        if (response.data.profile.roles.length > 0) {
          const sitterProfile = yield call(fetchSaga, {
            fetcher: loadSitterProfileFetch,
          });
          if (sitterProfile.type === EResponseType.OK) {
            yield put(setSitterProfile({ profile: sitterProfile.data }));
          }
        }
        if (response.data.sitterSettings) {
          yield put(
            setSitterSettings({ settings: response.data.sitterSettings }),
          );
        }
        const becomeSitterStatus = yield put(fireGetBecomeSitterStatus());
        yield put(successInitial({ loggedIn: true, accessToken }));
        yield put(startWebSocket({ userId: response.data.profile.id }));
        yield put(successLoadProfile({ profile: response.data.profile }));
        if (payload.redirectUrl) {
          payload.historyPush(payload.redirectUrl);
        }
      } else {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
        payload.historyPush('/login');
      }
    } else {
      yield put(successInitial({ loggedIn: false }));
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default initialSaga;
