import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireDeletePet } from 'store/actions/client/fireDeletePet';
import deletePetFetch from 'api/client/deletePet/deletePetFetch';
import EResponseType from 'api/enums/EResponseType';
import { successDeletePet } from 'store/actions/client/successDeletePet';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';

function* deletePetSaga({
  payload,
}: ReturnType<typeof fireDeletePet>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: deletePetFetch,
      payload: { id: payload.id },
    });
    if (response.type === EResponseType.OK) {
      toast.success(getLang('SUCCESS_DELETE'));
      yield put(successDeletePet());
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default deletePetSaga;
