import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_APPROVE_REQUEST = 'SUCCESS_APPROVE_REQUEST';

export const successApproveRequest: TActionCreator<
  typeof SUCCESS_APPROVE_REQUEST,
  { orderId: number; id: number }
> = (payload) => ({
  type: SUCCESS_APPROVE_REQUEST,
  payload,
});
