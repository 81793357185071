import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TBecomeSitterProfileSaveParameters } from './types/TBecomeSitterProfileSaveParameters';

const becomeSitterProfileSaveFetch = async ({
  title,
  description,
  address,
  ordersCount,
  constantCare,
  allowBed,
  houseType,
  haveKids,
  haveCar,
  experience,
  medicine,
  injection,
  addressComment,
  instagram,
  vk,
  photos,
  isUpdate,
}: TBecomeSitterProfileSaveParameters): Promise<TPayload<unknown> | never> => {
  const fetchUrl = isUpdate
    ? '/worker/additional-profile/update'
    : '/worker/create/additional-profile';
  const url = new URL(`${API_URL}${fetchUrl}`);
  const body = new FormData();
  body.append('title', title);
  body.append('description', description);
  body.append('constant_supervision', constantCare ? '1' : '0');
  body.append('pets_on_the_bed', allowBed ? '1' : '0');
  body.append('type_of_housing', houseType === 'house' ? '1' : '2');
  body.append('children_under_twelve_yo', haveKids ? '1' : '0');
  body.append('has_car', haveCar ? '1' : '0');
  // body.append('care_experience', experience.toString());
  body.append('give_medication', medicine ? '1' : '0');
  body.append('give_injection', injection ? '1' : '0');
  body.append('address_q', address);
  body.append('care_experience', experience.toString());
  photos.forEach((photo, index) => {
    body.append(`files[${index}]`, photo);
  });

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body,
  });
};

export default becomeSitterProfileSaveFetch;
