import { TActionCreator } from 'store/TActionCreator';

export const LOAD_DIALOGUES = 'LOAD_DIALOGUES';

export const loadDialogues: TActionCreator<
  typeof LOAD_DIALOGUES,
  { more?: boolean; silent?: boolean }
> = (payload) => ({
  type: LOAD_DIALOGUES,
  payload,
});
