import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import * as Sentry from '@sentry/react';

import EResponseType from 'api/enums/EResponseType';
import { fireBecomeSitterIdentitySave } from 'store/actions/sitter/fireBecomeSitterIdentitySave';
import becomeSitterIdentitySaveFetch from 'api/sitter/becomeSitterIdentitySave/becomeSitterIdentitySaveFetch';
import { setBecomeSitterStep } from 'store/actions/sitter/setBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';

function* becomeSitterIdentitySaveSaga({
  payload,
}: ReturnType<typeof fireBecomeSitterIdentitySave>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: becomeSitterIdentitySaveFetch,
      payload,
    });
    if (response.type === EResponseType.OK) {
      yield put(setBecomeSitterStep({ step: EBecomeSitterStep.Moderation }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {
    Sentry.captureException(e);
  }
}

export default becomeSitterIdentitySaveSaga;
