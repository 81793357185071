import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireDeleteProfilePhoto } from 'store/actions/sitter/fireDeleteProfilePhoto';
import deletePhotoFetch from 'api/main/deletePhoto/deletePhotoFetch';

function* deleteProfilePhotoSaga({
  payload,
}: ReturnType<typeof fireDeleteProfilePhoto>): SagaIterator<void> {
  try {
    yield call(fetchSaga, {
      fetcher: deletePhotoFetch,
      payload: { id: payload.id },
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default deleteProfilePhotoSaga;
