import React, { FC } from 'react';

const Logout: FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 6.8A1.8 1.8 0 014.8 5h8.1a1.8 1.8 0 011.8 1.8v1.8a.9.9 0 11-1.8 0V6.8H4.8v10.8h8.1v-1.8a.9.9 0 111.8 0v1.8a1.8 1.8 0 01-1.8 1.8H4.8A1.8 1.8 0 013 17.6V6.8zm13.764 2.064a.9.9 0 011.273 0l2.7 2.7a.9.9 0 010 1.272l-2.7 2.7a.9.9 0 01-1.273-1.272l1.164-1.164H9.3a.9.9 0 110-1.8h8.628l-1.164-1.164a.9.9 0 010-1.272z"
      fill="currentColor"
    />
  </svg>
);

export default Logout;
