import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import loadRequestsFetch from 'api/client/loadRequests/loadRequestsFetch';
import { fireLoadRequests } from 'store/actions/client/fireLoadRequests';
import { successLoadRequests } from 'store/actions/client/successLoadRequests';

function* loadRequestsSaga({
  payload,
}: ReturnType<typeof fireLoadRequests>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: loadRequestsFetch,
      payload: { orderId: payload.orderId },
    });

    if (response.type === EResponseType.OK) {
      yield put(successLoadRequests({ requests: response.data }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default loadRequestsSaga;
