import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { fireSaveProfile } from 'store/actions/profile/fireSaveProfile';
import saveProfileFetch from 'api/profile/saveProfile/saveProfileFetch';
import { successSaveProfile } from 'store/actions/profile/successSaveProfile';
import { getLang } from 'libs/langs/langs';

function* saveProfileSaga({
  payload,
}: ReturnType<typeof fireSaveProfile>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: saveProfileFetch,
      payload: {
        profile: payload.profile,
      },
    });

    if (response.type === EResponseType.OK) {
      yield put(successSaveProfile({ profile: payload.profile }));
      toast.success(getLang('SAVE_SUCCESS'));
    } else {
      yield put(successSaveProfile({}));
      toast.success(getLang('SAVE_ERROR'));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default saveProfileSaga;
