import { TActionCreator } from 'store/TActionCreator';
import { TSitterSettings } from 'store/reducers/sitter/TSitterSettings';

export const FIRE_SITTER_SETTINGS_SAVE = 'FIRE_SITTER_SETTINGS_SAVE';

export const fireSitterSettingsSave: TActionCreator<
  typeof FIRE_SITTER_SETTINGS_SAVE,
  { settings: TSitterSettings }
> = (payload) => ({
  type: FIRE_SITTER_SETTINGS_SAVE,
  payload,
});
