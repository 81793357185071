import { TActionCreator } from 'store/TActionCreator';

export const FIRE_LOAD_SITTER_DATA = 'FIRE_LOAD_SITTER_DATA';

export const fireLoadSitterData: TActionCreator<
  typeof FIRE_LOAD_SITTER_DATA,
  { id: number; historyPush: (path: string) => void }
> = (payload) => ({
  type: FIRE_LOAD_SITTER_DATA,
  payload,
});
