import { TActionCreator } from 'store/TActionCreator';

export const FIRE_DELETE_PET_PHOTO = 'FIRE_DELETE_PET_PHOTO';

export const fireDeletePetPhoto: TActionCreator<
  typeof FIRE_DELETE_PET_PHOTO,
  { id: number; petId: number }
> = (payload) => ({
  type: FIRE_DELETE_PET_PHOTO,
  payload,
});
