import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_UPLOAD_PET_PHOTOS = 'SUCCESS_UPLOAD_PET_PHOTOS';

export const successUploadPetPhotos: TActionCreator<
  typeof SUCCESS_UPLOAD_PET_PHOTOS,
  { petId: number; photos: { id: number; url: string }[] }
> = (payload) => ({
  type: SUCCESS_UPLOAD_PET_PHOTOS,
  payload,
});
