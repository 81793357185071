import { TActionCreator } from 'store/TActionCreator';

export const FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO =
  'FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO';

export const fireBecomeSitterDeleteProfilePhoto: TActionCreator<
  typeof FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO,
  { id: number }
> = (payload) => ({
  type: FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO,
  payload,
});
