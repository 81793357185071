import { SET_LANGUAGE, setLanguage } from '../../../actions/main/languageActions';
import { TMainState } from 'store/reducers/main/TMainState';

type LanguageState = {
    currentLanguage: string;
};

// Определение типа для действия
interface ISetLanguageAction {
    type: typeof SET_LANGUAGE;
    payload: {
        language: string;
    };
}

const initialState: LanguageState = {
    currentLanguage: 'en-US', // Значение по умолчанию
};

const setLanguageHandler = (
    state: TMainState,
    { payload }: ReturnType<typeof setLanguage>
): TMainState => {
    return {
        ...state,
        currentLanguage: payload.language,
    };
};


const languageReducer = (state: TMainState, action: ISetLanguageAction): TMainState => {
    switch (action.type) {
        case SET_LANGUAGE:
            return setLanguageHandler(state, action);
        default:
            return state;
    }
};


export default languageReducer;
