import { TClientState } from 'store/reducers/client/TClientState';
import { setCurrentSearch } from 'store/actions/client/setCurrentSearch';

const setCurrentSearchHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof setCurrentSearch>,
): TClientState => ({
  ...state,
  currentSearch: payload.search,
});

export default setCurrentSearchHandler;
