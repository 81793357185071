import { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import selectDialogueMessages from 'store/selectors/chat/selectChatMessages';
import selectCurrentDialogueId from 'store/selectors/chat/selectCurrentDialogueId';
import loadDialogueFetch from 'api/chat/loadDialogue/loadDialogueFetch';
import { successLoadMoreMessages } from 'store/actions/chat/successLoadMoreMessages';

function* loadMoreMessagesSaga(): SagaIterator<void> {
  try {
    const messages = yield select(selectDialogueMessages);
    const dialogueId = yield select(selectCurrentDialogueId);
    const page = Math.floor(messages.length / 20) + 1;

    const response = yield call(fetchSaga, {
      fetcher: loadDialogueFetch,
      payload: { dialogueId, page },
    });
    yield put(
      successLoadMoreMessages({
        dialogueId,
        messages: response.data.messages,
        totalMessages: response.data.totalMessages,
      }),
    );
    // eslint-disable-next-line no-empty
  } catch {}
}

export default loadMoreMessagesSaga;
