import { TUserState } from 'store/reducers/user/TUserState';

const successResetPasswordHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    resetPasswordSuccess: true,
    resetPasswordError: null,
  };
};

export default successResetPasswordHandler;
