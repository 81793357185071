import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setBecomeSitterStep } from 'store/actions/sitter/setBecomeSitterStep';

const setBecomeSitterStepHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setBecomeSitterStep>,
): TSitterState => {
  return {
    ...state,
    becomeSitterStep: payload.step,
  };
};

export default setBecomeSitterStepHandler;
