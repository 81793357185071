import { TActionCreator } from 'store/TActionCreator';
import { TPet } from 'store/reducers/client/TPet';

export const SUCCESS_LOAD_PETS = 'SUCCESS_LOAD_PETS';

export const successLoadPets: TActionCreator<
  typeof SUCCESS_LOAD_PETS,
  { pets: TPet[] }
> = (payload) => ({
  type: SUCCESS_LOAD_PETS,
  payload,
});
