import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TBecomeSitterIdentitySaveParameters } from './types/TBecomeSitterIdentitySaveParameters';

const becomeSitterIdentitySaveFetch = async ({
  name,
  secondName,
  gender,
  birthDate,
  placeOfBirth,
  registrationAddress,
  passportSeries,
  passportNumber,
  passportIssueDate,
  passportIssuerUnit,
  passportIssuer,
  photos,
  isUpdate,
}: TBecomeSitterIdentitySaveParameters): Promise<TPayload<unknown> | never> => {
  const fetchUrl = isUpdate
    ? '/worker/profile/update'
    : '/worker/create/profile';
  const url = new URL(`${API_URL}${fetchUrl}`);
  const body = new FormData();
  body.append('first_name', name);
  body.append('last_name', secondName);
  body.append('gender', gender);
  body.append('city', placeOfBirth);
  body.append('registration_address', registrationAddress);
  body.append('passport_series', passportSeries);
  body.append('passport_number', passportNumber);
  body.append('passport_created_at', passportIssueDate);
  body.append('passport_issued_by', passportIssuer);
  body.append('department_code', passportIssuerUnit);
  body.append('birth', birthDate);
  body.append('birth', birthDate);
  body.append('files[0]', photos[0]);
  body.append('files[1]', photos[1]);

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body,
  });
};

export default becomeSitterIdentitySaveFetch;
