import React, { FC } from 'react';

const Rodent: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path d="M22.29 9.562a1.055 1.055 0 100-2.11 1.055 1.055 0 000 2.11z" />
      <path d="M0 22.219c0 6.979 5.71 12.657 12.727 12.657h11.672a1.055 1.055 0 000-2.11h-2.512l.12-.24a7.357 7.357 0 00-.603-7.549l1.434-3.497a9.49 9.49 0 002.493.665c.074.46.185.913.333 1.356.105.315.352.562.667.667a8.687 8.687 0 008.89-2.098 2.67 2.67 0 000-3.771l-1.098-1.098.745-.746a3.144 3.144 0 00.927-2.237c0-.845-.329-1.64-.927-2.238a1.055 1.055 0 10-1.491 1.492c.199.2.309.464.309.746s-.11.546-.31.746l-.745.745-1.098-1.097a2.67 2.67 0 00-3.77 0 8.693 8.693 0 00-1.059 1.285 3.235 3.235 0 00-.195-.007 3.17 3.17 0 01-2.984-2.109h2.984c.582 0 1.055-.472 1.055-1.055v-1.474c0-3.756-2.834-7.132-6.467-7.89a3.17 3.17 0 00-3.026-2.238h-2.11c-.582 0-1.054.473-1.054 1.055v2.11c0 .655.198 1.278.56 1.8a5.433 5.433 0 00-.284 4.1c-.86.282-1.687.655-2.467 1.111a6.336 6.336 0 00-6.317-5.957C2.889 5.343 0 8.186 0 11.672a6.3 6.3 0 001.662 4.275A12.691 12.691 0 000 22.22zm29.254-6.116a.557.557 0 01.787 0l3.688 3.688a.558.558 0 010 .787 6.537 6.537 0 01-6.213 1.739 6.647 6.647 0 01-.054-.245 3.17 3.17 0 002.21-3.017c0-.904-.38-1.72-.99-2.297a6.58 6.58 0 01.572-.655zm-12.346-4.198a1.055 1.055 0 00.652-1.624c-.553-.815-.69-1.809-.385-2.799.1-.327.252-.63.452-.9.34-.46.25-1.108-.202-1.46a1.05 1.05 0 01-.409-.833V3.234h1.055c.581 0 1.055.473 1.055 1.055 0 .543.413.998.954 1.05 2.963.284 5.374 2.937 5.374 5.914v.419H22.29c-.583 0-1.055.472-1.055 1.054A5.28 5.28 0 0026.51 18a1.056 1.056 0 010 2.11 7.39 7.39 0 01-5.538-2.5 1.055 1.055 0 00-1.581 1.396 9.492 9.492 0 001.593 1.437l-1.203 2.934c-1.084-.75-2.365-1.158-3.819-1.158a1.055 1.055 0 100 2.11c3.816 0 5.714 4.145 4.16 7.253l-.592 1.184H18.07c-5.273 0-9.563-4.258-9.563-9.492v-1.055c0-4.935 3.533-9.273 8.4-10.314zm-14.799-.233c0-2.327 1.925-4.22 4.29-4.22a4.224 4.224 0 013.992 5.587 12.61 12.61 0 00-3.992 9.18v1.055c0 3.854 1.9 7.274 4.819 9.385-5.143-.73-9.109-5.133-9.109-10.44 0-3.355 1.62-6.542 4.334-8.523a1.055 1.055 0 00-1.245-1.704 12.743 12.743 0 00-2.286 2.154 4.201 4.201 0 01-.803-2.474z" />
    </g>
  </svg>
);

export default Rodent;
