import { TActionCreator } from 'store/TActionCreator';
import { TReview } from 'store/reducers/client/TReview';

export const SUCCESS_LOAD_REVIEWS = 'SUCCESS_LOAD_REVIEWS';

export const successLoadReviews: TActionCreator<
  typeof SUCCESS_LOAD_REVIEWS,
  { list: TReview[]; total: number }
> = (payload) => ({
  type: SUCCESS_LOAD_REVIEWS,
  payload,
});
