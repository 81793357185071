import { TChatState } from 'store/reducers/chat/TChatState';
import { successLoadDialogues } from 'store/actions/chat/successLoadDialogues';

const successLoadDialoguesHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof successLoadDialogues>,
): TChatState => {
  return {
    ...state,
    dialogues: [...state.dialogues, ...payload.dialogues],
    allDialoguesLoaded: payload.dialogues.length < 20,
    moreDialoguesLoading: false,
  };
};

export default successLoadDialoguesHandler;
