import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const searchSitterCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    list: rawPayloadData.data.map((rawSitter) => {
      const address = [];
      if (rawSitter.city) {
        address.push(rawSitter.city.name);
      }
      if (rawSitter.district) {
        address.push(rawSitter.district.name);
      }
      const review = rawSitter.client_reviews.data;
      return {
        id: rawSitter.id,
        userId: rawSitter.user_id,
        avatar: rawSitter.avatar.data.path,
        addressPoint: [Number(rawSitter.latitude), Number(rawSitter.longitude)],
        name: rawSitter.name,
        sittingPrice: rawSitter.sitting_settings_price,
        walkingPrice: rawSitter.walking_settings_price,
        userRating: rawSitter.user_rating,
        reviewsCount: rawSitter.reviews_count,
        repeatOrderCount: rawSitter.repeat_order_count,
        address: address.join(', '),
        title: rawSitter.title,
        lastReview: Array.isArray(review)
          ? null
          : {
              avatar: review.avatar.path,
              rating: review.rating,
              author: review.user.short_name,
              date: new Date(review.created_at),
              text: review.review,
            },
      };
    }),
    currentPage: rawPayloadData.current_page,
    totalPages: rawPayloadData.last_page,
  };
};

export default searchSitterCleaner;
