import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const savePetCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    id: rawPayloadData.data.id,
  };
};

export default savePetCleaner;
