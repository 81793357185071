import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireMessagesAsRead } from 'store/actions/chat/fireMessagesAsRead';
import messagesAsReadFetch from 'api/chat/messagesAsRead/messagesAsReadFetch';

function* messagesAsReadSaga({
  payload,
}: ReturnType<typeof fireMessagesAsRead>): SagaIterator<void> {
  try {
    const { dialogueId } = payload;
    yield call(fetchSaga, {
      fetcher: messagesAsReadFetch,
      payload: { dialogueId },
    });
    // eslint-disable-next-line no-empty
  } catch {}
}

export default messagesAsReadSaga;
