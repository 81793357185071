import { TProfileState } from 'store/reducers/profile/TProfileState';
import { successSaveProfile } from 'store/actions/profile/successSaveProfile';

const successSaveProfileHandler = (
  state: TProfileState,
  { payload }: ReturnType<typeof successSaveProfile>,
): TProfileState => {
  return {
    ...state,
    profileSaving: false,
    profile: payload.profile || state.profile,
  };
};

export default successSaveProfileHandler;
