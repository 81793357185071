export enum EOrderType {
  Sitting = 1,
  Walking = 2,
}

export enum EOrderStatus {
  New = 1,
  Cancelled = 2,
  Confirmed = 3,
  Paid = 4,
  Completed = 5,
}

export type TOrderSittingInfo = {
  allowCats: boolean;
  allowDogs: boolean;
  allowKids: boolean;
  startAt: Date;
  endAt: Date;
};

export type TOrderWalkingInfo = {
  duration: number;
  washPaws: boolean;
  dates: Date[];
};

export type TOrder = {
  id: number;
  type: EOrderType;
  status: EOrderStatus;
  rate: number;
  workerId: number | null;
  workerShortName: string | null;
  requestsCount: number;
  totalPrice: number | null;
  info: TOrderSittingInfo | TOrderWalkingInfo;
  petsIds: number[];
  requestsUserIds: number[];
};
