import { cleanMessage } from 'api/chat/loadDialogues/loadDialoguesCleaner';
import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const loadDialogueCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    messages: rawPayloadData.data.messages.data.map(cleanMessage),
    totalMessages: rawPayloadData.data.messages.meta.total,
  };
};

export default loadDialogueCleaner;
