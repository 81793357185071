import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TRegisterParameters } from './types/TRegisterParameters';
import { TRegisterPayload } from './types/TRegisterPayload';
import registerCleaner from './registerCleaner';

const registerFetch = async ({
  phone,
  address,
  code,
  password,
  repeatPassword,
  name,
  timezone,
  email,
}: TRegisterParameters): Promise<TRegisterPayload | never> => {
  const url = new URL(`${API_URL}/register`);
  const body = {
    phone,
    address,
    code,
    first_name: name,
    password,
    password_confirmation: repeatPassword,
    timezone,
    name,
    email,
  };

  return apiFetch(registerCleaner, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default registerFetch;
