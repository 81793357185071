import { TChatState } from 'store/reducers/chat/TChatState';
import { fireMessagesAsRead } from 'store/actions/chat/fireMessagesAsRead';
import { EMessageStatus } from 'store/reducers/chat/TMessage';

const fireMessagesAsReadHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof fireMessagesAsRead>,
): TChatState => {
  const { dialogues } = state;
  const dialogueIndex = dialogues.findIndex(
    (dialogue) => payload.dialogueId === dialogue.id,
  );
  if (dialogueIndex === -1) {
    return state;
  }
  const dialogue = dialogues[dialogueIndex];

  return {
    ...state,
    dialogues: [
      ...dialogues.slice(0, dialogueIndex),
      {
        ...dialogue,
        messages: dialogue.messages.map((message) => {

          return {
            ...message,
            status:
              message.status === EMessageStatus.Sent &&
              message.ownerId !== payload.profileId
                ? EMessageStatus.Read
                : message.status,
          };
        }),
      },
      ...dialogues.slice(dialogueIndex + 1),
    ],
  };
};

export default fireMessagesAsReadHandler;
