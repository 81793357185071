import { TUserState } from 'store/reducers/user/TUserState';

const fireCheckResetCodeHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    correctResetCode: null,
  };
};

export default fireCheckResetCodeHandler;
