import {
  EOrderStatus,
  EOrderType,
  TOrderSittingInfo,
  TOrderWalkingInfo,
  TOrder,
} from 'store/reducers/client/TOrder';
import { parse } from 'date-fns';
import {
  TRawOkayPayloadData,
  TRawOrder,
  TRawOrderSittingInfo,
  TRawOrderWalkingInfo,
} from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

export const rawOrderTypeToType = (rawOrderType: number) => {
  switch (rawOrderType) {
    case 2:
      return EOrderType.Walking;
    case 1:
    default:
      return EOrderType.Sitting;
  }
};

export const rawOrderStatusToStatus = (rawOrderStatus: number) => {
  switch (rawOrderStatus) {
    case 2:
      return EOrderStatus.Cancelled;
    case 3:
      return EOrderStatus.Confirmed;
    case 4:
      return EOrderStatus.Paid;
    case 5:
      return EOrderStatus.Completed;
    case 1:
    default:
      return EOrderStatus.New;
  }
};

export const cleanOrderInfo = (
  rawInfo: TRawOrderWalkingInfo | TRawOrderSittingInfo,
  rawType: number,
): TOrderSittingInfo | TOrderWalkingInfo => {
  let info: TOrderSittingInfo | TOrderWalkingInfo;
  if (rawType === 1) {
    const rawSittingInfo = rawInfo as TRawOrderSittingInfo;
    info = {
      allowCats: Boolean(rawSittingInfo.show_who_has_cats),
      allowDogs: Boolean(rawSittingInfo.show_who_has_dogs),
      allowKids: Boolean(rawSittingInfo.show_who_has_kids),
      startAt: parse(
        rawSittingInfo.dates.data[0].start_at,
        'yyyy-MM-dd HH:mm:ss',
        new Date(),
      ),
      endAt: parse(
        rawSittingInfo.dates.data[0].end_at,
        'yyyy-MM-dd HH:mm:ss',
        new Date(),
      ),
    };
  } else {
    const rawWalkingInfo = rawInfo as TRawOrderWalkingInfo;
    info = {
      duration: rawWalkingInfo.walk_duration,
      washPaws: Boolean(rawWalkingInfo.washing_paws),
      dates: rawWalkingInfo.dates.data.map((rawDate) =>
        parse(rawDate.start_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
      ),
    };
  }
  return info;
};

export const cleanOrder = (rawOrder: TRawOrder): TOrder => ({
  id: rawOrder.id,
  type: rawOrderTypeToType(rawOrder.type),
  status: rawOrderStatusToStatus(rawOrder.status),
  rate: rawOrder.rate,
  workerId: rawOrder.worker_id,
  workerShortName: rawOrder.worker_short_name,
  requestsCount: rawOrder.count_of_requests,
  totalPrice: rawOrder.total_price,
  info: cleanOrderInfo(rawOrder.orderable.data, rawOrder.type),
  petsIds: rawOrder.pets.data.map((rawPet) => rawPet.id),
  requestsUserIds: rawOrder.requests,
});

const loadOrdersCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return rawPayloadData.data.map((rawOrder) => cleanOrder(rawOrder));
};

export default loadOrdersCleaner;
