import { TUserState } from 'store/reducers/user/TUserState';
import { successLogin } from 'store/actions/user/successLogin';

const successLoginHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof successLogin>,
): TUserState => {
  return {
    ...state,
    accessToken: payload.accessToken,
    loggedIn: true,
    loginError: null,
  };
};

export default successLoginHandler;
