import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TLoadRequestsPayload } from './types/TLoadRequestsPayload';
import loadRequestsCleaner from './loadRequestsCleaner';

const loadRequestsFetch = async (): Promise<TLoadRequestsPayload | never> => {
  const url = new URL(
    `${API_URL}/orders/requests/clients?include=order,order.pets,order.pets.avatar,order.pets.breed,order.pets.type,order.orderable,order.pets.files`,
  );

  return apiFetch(loadRequestsCleaner, url.toString());
};

export default loadRequestsFetch;
