import { rawOrderStatusToStatus } from 'api/client/loadOrders/loadOrdersCleaner';

import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const saveOrderCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    id: rawPayloadData.data.id,
    rate: rawPayloadData.data.rate,
    status: rawOrderStatusToStatus(rawPayloadData.data.status),
  };
};

export default saveOrderCleaner;
