import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TUploadPetPhotosParameters } from './types/TUploadPetPhotosParameters';
import { TUploadPetPhotosPayload } from './types/TUploadPetPhotosPayload';
import uploadPetPhotosCleaner from './uploadPetPhotosCleaner';

const uploadPetPhotosFetch = async ({
  files,
  petId,
}: TUploadPetPhotosParameters): Promise<TUploadPetPhotosPayload | never> => {
  const url = new URL(`${API_URL}/pets/photos/${petId}`);
  const body = new FormData();
  files.forEach((file, index) => {
    body.append(`files[${index}]`, file);
  });

  return apiFetch(uploadPetPhotosCleaner, url.toString(), {
    method: 'POST',
    body,
  });
};

export default uploadPetPhotosFetch;
