import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'const';
import { successLogout } from 'store/actions/user/successLogout';

function* logoutSaga(): SagaIterator<void> {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME);
    yield put(successLogout());
    // eslint-disable-next-line no-empty
  } catch {}
}

export default logoutSaga;
