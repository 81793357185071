import { TChatState } from 'store/reducers/chat/TChatState';

const setMoreDialoguesLoadingHandler = (state: TChatState): TChatState => {
  return {
    ...state,
    moreDialoguesLoading: true,
  };
};

export default setMoreDialoguesLoadingHandler;
