import { TActionCreator } from 'store/TActionCreator';

export const FIRE_CHECK_CODE = 'FIRE_CHECK_CODE';

export const fireCheckCode: TActionCreator<
  typeof FIRE_CHECK_CODE,
  { code: number }
> = (payload) => ({
  type: FIRE_CHECK_CODE,
  payload,
});
