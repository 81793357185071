import { TActionCreator } from 'store/TActionCreator';

export const FIRE_BECOME_SITTER_TEST_COMPLETE =
  'FIRE_BECOME_SITTER_TEST_COMPLETE';

export const fireBecomeSitterTestComplete: TActionCreator<
  typeof FIRE_BECOME_SITTER_TEST_COMPLETE
> = () => ({
  type: FIRE_BECOME_SITTER_TEST_COMPLETE,
});
