import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TAcceptRequestParameters } from './types/TAcceptRequestParameters';

const acceptRequestFetch = async ({
  requestId,
}: TAcceptRequestParameters): Promise<unknown> => {
  const url = new URL(`${API_URL}/orders/requests/${requestId}/client`);

  return apiFetch(() => null, url.toString(), {
    method: 'GET',
  });
};

export default acceptRequestFetch;
