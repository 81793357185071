import { TActionCreator } from 'store/TActionCreator';
import { TSearchSitter } from './fireSearchSitter';

export const SET_CURRENT_SEARCH = 'SET_CURRENT_SEARCH';

export const setCurrentSearch: TActionCreator<
  typeof SET_CURRENT_SEARCH,
  { search: TSearchSitter | null }
> = (payload) => ({
  type: SET_CURRENT_SEARCH,
  payload,
});
