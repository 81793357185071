import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TChangePasswordParameters } from './types/TChangePasswordParameters';

const changePasswordFetch = async ({
  oldPassword,
  repeatPassword,
  password,
}: TChangePasswordParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/password/change`);
  const body = {
    old_password: oldPassword,
    password,
    password_confirmation: repeatPassword,
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default changePasswordFetch;
