import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireSavePet } from 'store/actions/client/fireSavePet';
import savePetFetch from 'api/client/savePet/savePetFetch';
import EResponseType from 'api/enums/EResponseType';
import uploadPetPhotosFetch from 'api/client/uploadPetPhoto/uploadPetPhotosFetch';
import { successSavePet } from 'store/actions/client/successSavePet';
import { TPet } from 'store/reducers/client/TPet';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';

function* savePetSaga({
  payload,
}: ReturnType<typeof fireSavePet>): SagaIterator<void> {
  try {
    const { pet } = payload;
    const response = yield call(fetchSaga, {
      fetcher: savePetFetch,
      payload: { pet },
    });
    if (response.type === EResponseType.OK) {
      toast.success(getLang('SAVE_SUCCESS'));
      if (pet.id) {
        yield put(successSavePet({ pet: pet as TPet }));
      } else if (!pet.id && pet.photos) {
        const avatarIndex = pet.photos.findIndex(
          (photo) => photo.id === pet.avatarId,
        );
        const uploadPhotosResponse = yield call(fetchSaga, {
          fetcher: uploadPetPhotosFetch,
          payload: {
            petId: response.data.id,
            files: pet.photos.map((photo) => photo.raw),
          },
        });
        if (uploadPhotosResponse.type === EResponseType.OK) {
          const realAvatarId = uploadPhotosResponse.data[avatarIndex].id;
          const newPet = {
            ...pet,
            id: response.data.id,
            avatarId: realAvatarId,
          };
          yield call(fetchSaga, {
            fetcher: savePetFetch,
            payload: { pet: newPet as TPet },
          });
          yield put(successSavePet({ pet: newPet as TPet }));
        }
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default savePetSaga;
