import { successLoadProfile } from 'store/actions/profile/successLoadProfile';
import { TProfileState } from 'store/reducers/profile/TProfileState';

const successLoadProfileHandler = (
  state: TProfileState,
  { payload }: ReturnType<typeof successLoadProfile>,
): TProfileState => {
  return {
    ...state,
    profile: payload.profile,
  };
};

export default successLoadProfileHandler;
