import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TSitterSettingsSaveParameters } from './types/TSitterSettingsSaveParameters';

const sitterSettingsSaveFetch = async ({
  sitterPrice,
  sitterPetTypes,
  sitterDogSizes,
  sitterCatSizes,
  walkingPrice,
  walkingDogSizes,
  ordersCount,
}: TSitterSettingsSaveParameters): Promise<TPayload<unknown> | never> => {
  const url = new URL(`${API_URL}/settings`);

  const body: any = {};
  if (sitterPrice != null) {
    body.sitting_settings = {
      price: sitterPrice,
      allowed_pet_types: sitterPetTypes,
      allowed_dog_sizes: sitterDogSizes,
      allowed_cat_sizes: sitterCatSizes,
      count_of_orders: ordersCount,
    };
  }
  if (walkingPrice !== null) {
    body.walking_settings = {
      price: walkingPrice,
      allowed_dog_sizes: walkingDogSizes,
    };
  }

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default sitterSettingsSaveFetch;
