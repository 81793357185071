import { TUserState } from 'store/reducers/user/TUserState';

const fireCheckCodeHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    correctCode: null,
  };
};

export default fireCheckCodeHandler;
