import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { fireLogin } from 'store/actions/user/fireLogin';
import { successLogin } from 'store/actions/user/successLogin';
import { TOkayPayloadData } from 'api/user/login/types/TOkayPayloadData';
import { TPayload } from 'api/types/TPayload';
import loginFetch from 'api/user/login/loginFetch';
import EResponseType from 'api/enums/EResponseType';
import { ELoginError, failLogin } from 'store/actions/user/failLogin';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'const';
import fetchSaga from 'store/sagas/fetchSaga';
import { TGetProfilePayload } from 'api/profile/getProfile/types/TGetProfilePayload';
import getProfileFetch from 'api/profile/getProfile/getProfileFetch';
import { startWebSocket } from 'store/actions/main/startWebSocket';
import { successLoadProfile } from 'store/actions/profile/successLoadProfile';
import loadSitterProfileFetch from 'api/sitter/loadSitterProfile/loadSitterProfileFetch';
import { setSitterProfile } from 'store/actions/sitter/setSitterProfile';
import { setSitterSettings } from 'store/actions/sitter/setSitterSettings';

function* loginSaga({
  payload,
}: ReturnType<typeof fireLogin>): SagaIterator<void> {
  try {
    const response: TPayload<TOkayPayloadData> = yield call(fetchSaga, {
      fetcher: loginFetch,
      payload,
    });
    if (response.type === EResponseType.OK) {
      const { accessToken } = response.data;
      window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME, accessToken);
      yield put(successLogin({ accessToken }));

      const profileResponse: TGetProfilePayload = yield call(fetchSaga, {
        fetcher: getProfileFetch,
      });
      if (profileResponse.type === EResponseType.OK) {
        if (profileResponse.data.profile.roles.length > 0) {
          const sitterProfile = yield call(fetchSaga, {
            fetcher: loadSitterProfileFetch,
          });
          if (sitterProfile.type === EResponseType.OK) {
            yield put(setSitterProfile({ profile: sitterProfile.data }));
          }
        }
        if (profileResponse.data.sitterSettings) {
          yield put(
            setSitterSettings({
              settings: profileResponse.data.sitterSettings,
            }),
          );
        }
        yield put(startWebSocket({ userId: profileResponse.data.profile.id }));
        yield put(
          successLoadProfile({ profile: profileResponse.data.profile }),
        );
      }
    } else if (response.error) {
      yield put(failLogin({ code: ELoginError.WRONG_PASSWORD }));
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default loginSaga;
