import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Main from 'containers/Main/Main';
import { setIsMobile } from 'store/actions/main/setIsMobile';
import selectIsMobile from 'store/selectors/main/selectIsMobile';
import { initial } from 'store/actions/main/initial';
import selectIsTablet from 'store/selectors/main/selectIsTablet';
import { setIsTablet } from 'store/actions/main/setIsTablet';
import selectIsLoggedIn from 'store/selectors/user/selectIsLoggedIn';
import useSiteLoad from 'hooks/useSiteLoad';

import 'styles/app.css';

const MAXIMUM_MOBILE_WIDTH = 1279;
const TABLET_MINIMUM_WIDTH = 767;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const [authFromParams, setAuthFromParams] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  useSiteLoad();

  useEffect(() => {
    if (authFromParams && isLoggedIn !== null) {
      setAuthFromParams(false);
    }
  }, [authFromParams, isLoggedIn]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');
    if (accessToken) {
      const redirectUrl = urlParams.get('redirectUrl') || '/cabinet';
      dispatch(
        initial({ accessToken, redirectUrl, historyPush: history.push }),
      );
      setAuthFromParams(true);
    } else {
      dispatch(initial({ historyPush: history.push }));
    }
  }, [dispatch, history.push]);

  useEffect(() => {
    dispatch(
      setIsMobile({ isMobile: window.innerWidth <= MAXIMUM_MOBILE_WIDTH }),
    );
    dispatch(
      setIsTablet({
        isTablet:
          window.innerWidth > TABLET_MINIMUM_WIDTH &&
          window.innerWidth <= MAXIMUM_MOBILE_WIDTH,
      }),
    );

    const onResize = () => {
      const newIsMobile = window.innerWidth <= MAXIMUM_MOBILE_WIDTH;
      const newIsTablet =
        window.innerWidth > TABLET_MINIMUM_WIDTH &&
        window.innerWidth <= MAXIMUM_MOBILE_WIDTH;
      if (newIsMobile !== isMobile) {
        dispatch(setIsMobile({ isMobile: newIsMobile }));
      }
      if (newIsTablet !== isTablet) {
        dispatch(setIsTablet({ isTablet: newIsTablet }));
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isMobile, dispatch, isTablet]);

  if (isMobile === null) {
    return null;
  }

  if (authFromParams) {
    return null;
  }

  return <Main />;
};

export default App;
