import { TActionCreator } from 'store/TActionCreator';

export const FIRE_MESSAGES_AS_READ = 'FIRE_MESSAGES_AS_READ';

export const fireMessagesAsRead: TActionCreator<
  typeof FIRE_MESSAGES_AS_READ,
  { dialogueId: number; profileId: number }
> = (payload) => ({
  type: FIRE_MESSAGES_AS_READ,
  payload,
});
