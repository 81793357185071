import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { fireSaveOrder } from 'store/actions/client/fireSaveOrder';
import saveOrderFetch from 'api/client/saveOrder/saveOrderFetch';
import { successSaveOrder } from 'store/actions/client/successSaveOrder';
import { TOrder } from 'store/reducers/client/TOrder';
import selectProfile from 'store/selectors/profile/selectProfile';
import saveProfileFetch from 'api/profile/saveProfile/saveProfileFetch';
import { successSaveProfile } from 'store/actions/profile/successSaveProfile';

function* saveOrderSaga({
  payload,
}: ReturnType<typeof fireSaveOrder>): SagaIterator<void> {
  try {
    if (payload.address) {
      const profile = yield select(selectProfile);
      const newProfile = { ...profile, address: payload.address };
      yield call(fetchSaga, {
        fetcher: saveProfileFetch,
        payload: {
          profile: newProfile,
        },
      });
      yield put(successSaveProfile({ profile: newProfile }));
    }
    const { order } = payload;
    const response = yield call(fetchSaga, {
      fetcher: saveOrderFetch,
      payload: { order },
    });
    if (response.type === EResponseType.OK) {
      toast.success(getLang('CREATE_SUCCESS'));
      yield put(
        successSaveOrder({
          order: {
            ...order,
            id: response.data.id,
            rate: response.data.rate,
            status: response.data.status,
            requestsUserIds: [],
          } as TOrder,
        }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default saveOrderSaga;
