import {
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { EventChannel, eventChannel } from 'redux-saga';
import Echo from 'laravel-echo';
import fetchSaga from 'store/sagas/fetchSaga';
import getSocketParamsFetch from 'api/main/getSocketParams/getSocketParamsFetch';
import { TOkayPayloadData as TSocketParams } from 'api/main/getSocketParams/types/TOkayPayloadData';
import selectAccessToken from 'store/selectors/user/selectAccessToken';
import { TPayload } from 'api/types/TPayload';
import EResponseType from 'api/enums/EResponseType';
import {
  START_WEB_SOCKET,
  startWebSocket,
} from 'store/actions/main/startWebSocket';
import { newMessage } from 'store/actions/chat/newMessage';
import { cleanMessage } from 'api/chat/loadDialogues/loadDialoguesCleaner';
import { FIRE_TYPING, fireTyping } from 'store/actions/chat/fireTyping';
import { FIRE_LISTEN_TYPING } from 'store/actions/chat/fireListenTyping';
import { setDialogueTyping } from 'store/actions/chat/setDialogueTyping';
// eslint-disable-next-line @typescript-eslint/no-var-requires
(window as any).Pusher = require('pusher-js');

export type TWebSocketMessage = {
  type: string;
  payload: any;
};

let echo: Echo;

function* createSocketConnection(socketParams: TSocketParams) {
  const { appKey, tls, namespace, appCluster, port, host } = socketParams;
  const accessToken: string | null = yield select(selectAccessToken);

  return new Echo({
    broadcaster: 'pusher',
    key: appKey,
    cluster: appCluster,
    wsHost: host,
    wsPort: port,
    wssPort: port,
    forceTLS: tls,
    namespace,
    disableStats: true,
    encrypted: true,
    authEndpoint: `http://${host}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    enabledTransports: ['ws', 'wss'],
    disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
  });
  // socket.onopen = () => {
  //   resolve(socket);
  // };
  //
  // socket.onerror = (evt) => {
  //   reject(evt);
  // };
}

function createSocketChannel(echo: Echo, userId: number): EventChannel<any> {
  return eventChannel((emit) => {
    echo
      .private(`App.Containers.User.Models.User.${userId}`)
      .listen('ReceiveUserMessage', (response: any) => {
        console.log(response);
        emit({
          type: 'new-message',
          payload: response.resource,
        });
      });

    // webSocket.onclose = () => {
    //   emit(END);
    // };

    return () => {
      // webSocket.onmessage = null;
    };
  });
}

function* listenForSocketMessages(userId: number) {
  let socketChannel: EventChannel<TWebSocketMessage>;

  try {
    const socketParamsResponse: TPayload<TSocketParams> = yield call(
      fetchSaga,
      {
        fetcher: getSocketParamsFetch,
      },
    );
    if (socketParamsResponse.type === EResponseType.OK) {
      echo = yield call(createSocketConnection, socketParamsResponse.data);
      socketChannel = yield call(createSocketChannel, echo, userId);

      while (true) {
        const data: TWebSocketMessage = yield take(socketChannel);
        if (data.type === 'new-message') {
          yield put(
            newMessage({
              dialogueId: data.payload.group_id,
              message: cleanMessage(data.payload),
            }),
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
    console.error('Error while connecting web socket');
  } finally {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (yield cancelled()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      socketChannel?.close();
    }
  }
}

function* startWebSocketSaga({ payload }: ReturnType<typeof startWebSocket>) {
  yield fork(listenForSocketMessages, payload.userId);
}

function typingSaga({ payload }: ReturnType<typeof fireTyping>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  echo.private(`App.Models.ChatGroup.${payload.dialogueId}`).whisper('typing');
}

function createTypingListenChannel(dialogueId: number): EventChannel<any> {
  let stopTypingTimeout: number | null = null;

  return eventChannel((emit) => {
    echo
      .private(`App.Models.ChatGroup.${dialogueId}`)
      .listenForWhisper('typing', () => {
        if (stopTypingTimeout) {
          window.clearTimeout(stopTypingTimeout);
        }
        emit({
          type: 'typing',
          payload: true,
        });
        stopTypingTimeout = window.setTimeout(() => {
          emit({
            type: 'typing',
            payload: false,
          });
        }, 3000);
      });

    return () => { };
  });
}

function* listenTypingSaga({ payload }: ReturnType<typeof fireTyping>) {
  const channel: EventChannel<TWebSocketMessage> = yield call(
    createTypingListenChannel,
    payload.dialogueId,
  );
  // TODO: clear on dialogue close
  while (true) {
    const data: TWebSocketMessage = yield take(channel);
    if (data.type === 'typing') {
      yield put(setDialogueTyping({ typing: data.payload }));
    }
  }
}

export default function* webSocketRootSaga() {
  yield takeLatest(START_WEB_SOCKET, startWebSocketSaga);
  yield takeLatest(FIRE_TYPING, typingSaga);
  yield takeLatest(FIRE_LISTEN_TYPING, listenTypingSaga);
}
