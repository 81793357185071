import { TMainState } from 'store/reducers/main/TMainState';
import { setIsMobileMenuOpened } from 'store/actions/main/setIsMobileMenuOpened';

const setIsMobileMenuOpenedHandler = (
	state: TMainState,
	{ payload }: ReturnType<typeof setIsMobileMenuOpened>,
): TMainState => {
	return {
		...state,
		isMobileMenuOpened: payload.opened,
	};
};

export default setIsMobileMenuOpenedHandler;
