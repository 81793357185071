import React, { FC } from 'react';
import Paw from 'icons/Paw';
import Text from 'components/Text/Text';
import { getLang } from 'libs/langs/langs';
import Button from 'components/Button/Button';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import selectIsMobile from 'store/selectors/main/selectIsMobile';

import styles from './NotFound.module.css';

const NotFound: FC = () => {
  const isMobile = useSelector(selectIsMobile);

  return (
    <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
      <div className={styles.form}>
        <div className={styles.content}>
          <Text>4&nbsp;</Text>
          <span className={styles.icon}>
            <Paw />
          </span>
          <Text>&nbsp;4</Text>
        </div>
        <Text tag="div" className={styles.caption}>
          {getLang('PAGE_NOT_FOUND')}
        </Text>
        <Button href="/" view="ghost" className={styles.button}>
          На главную
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
