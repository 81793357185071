import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { TOkayPayloadData } from 'api/user/register/types/TOkayPayloadData';
import { TPayload } from 'api/types/TPayload';
import registerFetch from 'api/user/register/registerFetch';
import { fireRegister } from 'store/actions/user/fireRegister';
import { successRegister } from 'store/actions/user/successRegister';
import EResponseType from 'api/enums/EResponseType';
import { ERegisterError, failRegister } from 'store/actions/user/failRegister';
import { LOCAL_STORAGE_ACCESS_TOKEN_NAME } from 'const';
import fetchSaga from 'store/sagas/fetchSaga';
import { TGetProfilePayload } from 'api/profile/getProfile/types/TGetProfilePayload';
import getProfileFetch from 'api/profile/getProfile/getProfileFetch';
import { successLoadProfile } from 'store/actions/profile/successLoadProfile';

function* registerSaga({
  payload,
}: ReturnType<typeof fireRegister>): SagaIterator<void> {
  try {
    const email: string = yield select((state) => state.user.checkPhone);
    const code: number = yield select((state) => state.user.correctCode);
    const timezone =
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/Moscow';
    const response: TPayload<TOkayPayloadData> = yield call(fetchSaga, {
      fetcher: registerFetch,
      payload: {
        email,
        address: payload.address,
        code,
        password: payload.password,
        repeatPassword: payload.repeatPassword,
        timezone,
        name: payload.name,
        phone: payload.phone,
      },
    });
    if (response.type === EResponseType.OK) {
      const { accessToken } = response.data;
      window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_NAME, accessToken);

      const profileResponse: TGetProfilePayload = yield call(fetchSaga, {
        fetcher: getProfileFetch,
      });
      if (profileResponse.type === EResponseType.OK) {
        yield put(
          successLoadProfile({ profile: profileResponse.data.profile }),
        );
      }
      yield put(successRegister({ accessToken }));
    } else if (response.error.data.password) {
      yield put(failRegister({ code: ERegisterError.PASSWORD_TOO_SHORT }));
    } else if (response.error.data.email) {
      yield put(
        failRegister({ code: ERegisterError.EMAIL_ALREADY_REGISTERED }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch { }
}

export default registerSaga;
