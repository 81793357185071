import { put, takeEvery } from 'redux-saga/effects';
import { SET_LANGUAGE } from '../../actions/main/languageActions';

// Определение типа для действия
interface ISetLanguageAction {
    type: typeof SET_LANGUAGE;
    payload: {
        language: string;
    };
}

function* handleLanguageChange(action: ISetLanguageAction) {
    const { language } = action.payload;
    // Сохраняем язык в localStorage
    localStorage.setItem('userLanguage', language);
    // Другие побочные эффекты
}

export default function* watchLanguageChange() {
    yield takeEvery(SET_LANGUAGE, handleLanguageChange);
}