import { TClientState } from 'store/reducers/client/TClientState';
import { successSavePet } from 'store/actions/client/successSavePet';

const successSavePetHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successSavePet>,
): TClientState => {
  const { pets } = state;
  if (!pets) {
    return state;
  }

  const { pet } = payload;
  let newPets = [...(state.pets || [])];
  const petIndex = pets.findIndex((otherPet) => otherPet.id === pet.id);
  if (petIndex !== -1) {
    newPets[petIndex] = pet;
  } else {
    newPets = [...newPets, pet];
  }

  return {
    ...state,
    petSaving: false,
    pets: newPets,
  };
};

export default successSavePetHandler;
