import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import EResponseType from 'api/enums/EResponseType';
import { TPayload } from 'api//types/TPayload';
import { ACCESS_TOKEN_ERROR_CODE, REQUEST_TIMEOUT_ERROR } from 'libs/apiRequest';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { successLogout } from 'store/actions/user/successLogout';
import { requestFail } from 'store/actions/main/requestFail';

const UNRESOLVED_ERROR_TEXT = 'Неизвестная ошибка';

type TFetchParams = {
  accessToken?: string;
  [key: string]: any;
};

type TFetchOptions = {
  preventDefaultTimeout?: boolean;
};

function* fetchSaga<
  FetchParams extends TFetchParams,
  FetchResponse extends TPayload<any>,
  Fetcher extends (params: FetchParams) => Promise<FetchResponse>
>(sagaParams: {
  fetcher: Fetcher;
  payload?: FetchParams;
  options?: TFetchOptions;
}): SagaIterator<FetchResponse> {
  try {
    const { fetcher, payload } = sagaParams;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(fetcher, payload);
    if (response.error?.code === ACCESS_TOKEN_ERROR_CODE) {
      toast.error(getLang('ACCESS_TOKEN_ERROR'));
      yield put(successLogout());
    } else if (response.error?.code === REQUEST_TIMEOUT_ERROR) {
      yield put(requestFail());
    }
    return response;
  } catch {
    yield put(requestFail());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return {
      type: EResponseType.FATAL_ERROR,
      error: UNRESOLVED_ERROR_TEXT,
      payload: null,
    };
  }
}

export default fetchSaga;
