import { TChatState } from 'store/reducers/chat/TChatState';
import { newMessage } from 'store/actions/chat/newMessage';

const newMessageHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof newMessage>,
): TChatState => {
  const { dialogues } = state;
  const dialogueIndex = dialogues.findIndex(
    (dialogue) => payload.dialogueId === dialogue.id,
  );
  if (dialogueIndex === -1) {
    return state;
  }
  const dialogue = dialogues[dialogueIndex];

  const sameMessageIndex = dialogue.messages.findIndex(
    (message) => message.id === payload.message.id,
  );
  let messages;
  if (sameMessageIndex !== -1) {
    messages = [
      ...dialogue.messages.slice(0, sameMessageIndex),
      { ...payload.message },
      ...dialogue.messages.slice(sameMessageIndex + 1),
    ];
  } else {
    messages = [...dialogue.messages, { ...payload.message }];
  }

  return {
    ...state,
    dialogues: [
      ...dialogues.slice(0, dialogueIndex),
      {
        ...dialogue,
        messages,
      },
      ...dialogues.slice(dialogueIndex + 1),
    ],
  };
};

export default newMessageHandler;
