import { TUserState } from 'store/reducers/user/TUserState';
import { successRegister } from 'store/actions/user/successRegister';

const successRegisterHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof successRegister>,
): TUserState => {
  return {
    ...state,
    loggedIn: true,
    accessToken: payload.accessToken,
    registerError: null,
  };
};

export default successRegisterHandler;
