import { TActionCreator } from 'store/TActionCreator';

export const FIRE_BECOME_SITTER_PROFILE_SAVE =
  'FIRE_BECOME_SITTER_PROFILE_SAVE';

export type TBecomeSitterProfile = {
  title: string;
  description: string;
  address: string;
  ordersCount: number;
  constantCare: boolean;
  allowBed: boolean;
  houseType: 'flat' | 'house';
  haveKids: boolean;
  haveCar: boolean;
  experience: number;
  medicine: boolean;
  injection: boolean;
  addressComment: string;
  instagram: string;
  vk: string;
  photos: File[];
  uploadedPhotos?: {
    id: number;
    url: string;
  }[];
};

export const fireBecomeSitterProfileSave: TActionCreator<
  typeof FIRE_BECOME_SITTER_PROFILE_SAVE,
  { profile: TBecomeSitterProfile; isUpdate: boolean }
> = (payload) => ({
  type: FIRE_BECOME_SITTER_PROFILE_SAVE,
  payload,
});
