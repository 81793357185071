import { TActionCreator } from 'store/TActionCreator';

export const FIRE_SEARCH_SITTER = 'FIRE_SEARCH_SITTER';

export type TSearchSitter = {
  type: number;
  petType: number[];
  petsCount: number;
  address: string;
  addressPoint: [number, number];
  dateFrom: string;
  dateTo: string;
  petSize: number[];
  priceFrom: number;
  priceTo: number;
  haveDogs: boolean;
  noDogs: boolean;
  noCats: boolean;
  noKids: boolean;
  canMedicine: boolean;
  canInjection: boolean;
  house: boolean;
  flat: boolean;
};

export const fireSearchSitter: TActionCreator<
  typeof FIRE_SEARCH_SITTER,
  { sitterInfo: TSearchSitter; page?: number; }
> = (payload) => ({
  type: FIRE_SEARCH_SITTER,
  payload,
});
