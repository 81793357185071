import React, { FC } from 'react';

const Dots: FC = () => (
  <svg width="30" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="currentColor" />
    <circle cx="15" cy="3" r="3" fill="currentColor" />
    <circle cx="27" cy="3" r="3" fill="currentColor" />
  </svg>
);

export default Dots;
