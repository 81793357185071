import { format } from 'date-fns';

import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import {
  EOrderType,
  TOrderSittingInfo,
  TOrderWalkingInfo,
} from 'store/reducers/client/TOrder';

import { TSaveOrderPayload } from './types/TSaveOrderPayload';
import saveOrderCleaner from './saveOrderCleaner';
import { TSaveOrderParameters } from './types/TSaveOrderParameters';

const savePetFetch = async ({
  order,
}: TSaveOrderParameters): Promise<TSaveOrderPayload | never> => {
  const url = new URL(`${API_URL}/orders`);
  let body: any = {
    type: order.type,
    pets: order.petsIds,
  };
  if (order.type === EOrderType.Sitting) {
    const sittingInfo = order.info as TOrderSittingInfo;
    body = {
      ...body,
      dates: [
        {
          start_at: format(sittingInfo.startAt, 'yyyy-MM-dd HH:mm:ss'),
          end_at: format(sittingInfo.endAt, 'yyyy-MM-dd HH:mm:ss'),
        },
      ],
      sitting: {
        show_who_has_kids: Number(sittingInfo.allowKids),
        show_who_has_dogs: Number(sittingInfo.allowDogs),
        show_who_has_cats: Number(sittingInfo.allowCats),
      },
    };
  } else {
    const walkingInfo = order.info as TOrderWalkingInfo;
    body = {
      ...body,
      dates: walkingInfo.dates.map((date) => ({
        start_at: format(date, 'yyyy-MM-dd HH:mm:ss'),
      })),
      walking: {
        washing_paws: Number(walkingInfo.washPaws),
        walk_duration: Number(walkingInfo.duration),
      },
    };
  }

  return apiFetch(saveOrderCleaner, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default savePetFetch;
