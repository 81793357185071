import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';

import EResponseType from 'api/enums/EResponseType';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { fireAcceptSitterRequest } from 'store/actions/sitter/fireAcceptSitterRequest';
import acceptRequestFetch from 'api/sitter/acceptRequest/acceptRequestFetch';
import { successAcceptSitterRequest } from 'store/actions/sitter/successAcceptSitterRequest';

function* acceptSitterRequestSaga({
  payload,
}: ReturnType<typeof fireAcceptSitterRequest>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: acceptRequestFetch,
      payload,
    });
    if (response.type === EResponseType.OK) {
      toast.info(getLang('SITTER_REQUEST_CANCEL_SUCCESS'));
      yield put(successAcceptSitterRequest(payload));
      // TODO: redirect to order
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default acceptSitterRequestSaga;
