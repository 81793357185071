import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TCheckResetCodeParameters } from './types/TCheckResetCodeParameters';

const checkResetCodeFetch = async ({
  code,
  phone,
}: TCheckResetCodeParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/code/reset/check`);
  const body = {
    email: phone,
    code,
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default checkResetCodeFetch;
