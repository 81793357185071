import { TChatState } from 'store/reducers/chat/TChatState';
import { sendMessage } from 'store/actions/chat/sendMessage';
import { EMessageStatus } from 'store/reducers/chat/TMessage';

const sendMessageHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof sendMessage>,
): TChatState => {
  const { dialogues } = state;
  const dialogueIndex = dialogues.findIndex(
    (dialogue) => payload.dialogueId === dialogue.id,
  );
  if (dialogueIndex === -1) {
    return state;
  }
  const dialogue = dialogues[dialogueIndex];

  return {
    ...state,
    dialogues: [
      ...dialogues.slice(0, dialogueIndex),
      {
        ...dialogue,
        messages: [
          ...dialogue.messages,
          {
            // TODO: new message id
            id: `new-message-${payload.date.getTime()}`,
            ownerId: payload.profileId,
            text: payload.text,
            media: payload.media,
            date: payload.date,
            status: EMessageStatus.Sending,
          },
        ],
      },
      ...dialogues.slice(dialogueIndex + 1),
    ],
  };
};

export default sendMessageHandler;
