import { TActionCreator } from 'store/TActionCreator';

export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';

export const setCurrentOrder: TActionCreator<
  typeof SET_CURRENT_ORDER,
  { id: number | 'new' | null }
> = (payload) => ({
  type: SET_CURRENT_ORDER,
  payload,
});
