import { TUserState } from 'store/reducers/user/TUserState';
import { successCheckResetCode } from 'store/actions/user/successCheckResetCode';

const successCheckResetCodeHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof successCheckResetCode>,
): TUserState => {
  return {
    ...state,
    correctResetCode: payload.correctCode,
    resetPasswordError: null,
  };
};

export default successCheckResetCodeHandler;
