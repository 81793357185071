import { TActionCreator } from 'store/TActionCreator';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';

export const SET_BECOME_SITTER_STEP = 'SET_BECOME_SITTER_STEP';

export const setBecomeSitterStep: TActionCreator<
  typeof SET_BECOME_SITTER_STEP,
  { step: EBecomeSitterStep }
> = (payload) => ({
  type: SET_BECOME_SITTER_STEP,
  payload,
});
