import { TAction } from 'store/TAction';
import { SUCCESS_CHECK_PHONE } from 'store/actions/user/successCheckPhone';
import { FIRE_CHECK_PHONE } from 'store/actions/user/fireCheckPhone';
import { SUCCESS_CHECK_CODE } from 'store/actions/user/successCheckCode';
import { CANCEL_SEND_CODE } from 'store/actions/user/cancelCheckCode';
import { SUCCESS_REGISTER } from 'store/actions/user/successRegister';
import { FAIL_LOGIN } from 'store/actions/user/failLogin';
import { SUCCESS_LOGIN } from 'store/actions/user/successLogin';
import { FORGOT_PASSWORD } from 'store/actions/user/forgotPassword';
import { CANCEL_FORGOT_PASSWORD } from 'store/actions/user/cancelForgotPassword';
import { SUCCESS_CHECK_RESET_CODE } from 'store/actions/user/successCheckResetCode';
import { SUCCESS_RESET_PASSWORD } from 'store/actions/user/successResetPassword';
import { CANCEL_RESET_PASSWORD } from 'store/actions/user/cancelResetPassword';
import { AFTER_RESET_PASSWORD } from 'store/actions/user/afterResetPassword';
import { FAIL_RESET_PASSWORD } from 'store/actions/user/failResetPassword';
import { FAIL_REGISTER } from 'store/actions/user/failRegister';
import { SUCCESS_INITIAL } from 'store/actions/main/successInitial';
import { SUCCESS_LOGOUT } from 'store/actions/user/successLogout';
import { FIRE_CHECK_CODE } from 'store/actions/user/fireCheckCode';
import { FIRE_CHECK_RESET_CODE } from 'store/actions/user/fireCheckResetCode';
import { FIRE_LOGIN } from 'store/actions/user/fireLogin';
import { FIRE_RESET_PASSWORD } from 'store/actions/user/fireResetPassword';
import { FIRE_REGISTER } from 'store/actions/user/fireRegister';
import { CHANGE_PASSWORD_ERROR } from 'store/actions/user/changePasswordError';
import { CHANGE_PASSWORD_SUCCESS } from 'store/actions/user/changePasswordSuccess';

import { TUserState } from './TUserState';
import successLoginHandler from './handlers/successLoginHandler';
import successCheckPhoneHandler from './handlers/successCheckPhoneHandler';
import successCheckCodeHandler from './handlers/successCheckCodeHandler';
import checkPhoneHandler from './handlers/checkPhoneHandler';
import cancelCheckCodeHandler from './handlers/cancelCheckCodeHandler';
import successRegisterHandler from './handlers/successRegisterHandler';
import failLoginHandler from './handlers/failLoginHandler';
import forgotPasswordHandler from './handlers/forgotPasswordHandler';
import cancelForgotPasswordHandler from './handlers/cancelForgotPasswordHandler';
import successCheckResetCodeHandler from './handlers/successCheckResetCodeHandler';
import successResetPasswordHandler from './handlers/successResetPasswordHandler';
import cancelResetPasswordHandler from './handlers/cancelResetPasswordHandler';
import afterResetPasswordHandler from './handlers/afterResetPasswordHandler';
import failResetPasswordHandler from './handlers/failResetPasswordHandler';
import failRegisterHandler from './handlers/failRegisterHandler';
import successInitialHandler from './handlers/successInitialHandler';
import successLogoutHandler from './handlers/successLogoutHandler';
import fireCheckCodeHandler from './handlers/fireCheckCodeHandler';
import fireCheckResetCodeHandler from './handlers/fireCheckResetCodeHandler';
import fireLoginHandler from './handlers/fireLoginHandler';
import fireResetPasswordHandler from './handlers/fireResetPasswordHandler';
import fireRegisterHandler from './handlers/fireRegisterHandler';
import changePasswordErrorHandler from './handlers/changePasswordErrorHandler';
import changePasswordSuccessHandler from './handlers/changePasswordSucessHandler';

export const userInitialState: TUserState = {
  loggedIn: null,
  accessToken: null,
  checkPhone: null,
  exists: null,
  correctCode: null,
  loginError: null,
  forgotPassword: false,
  correctResetCode: null,
  resetPasswordSuccess: null,
  resetPasswordError: null,
  registerError: null,
  changePasswordError: null,
  changePasswordSuccess: false,
};

const userReducer = (state = userInitialState, action: TAction): TUserState => {
  switch (action.type) {
    case SUCCESS_INITIAL:
      return successInitialHandler(state, action);
    case SUCCESS_LOGIN:
      return successLoginHandler(state, action);
    case FIRE_CHECK_PHONE:
      return checkPhoneHandler(state, action);
    case SUCCESS_CHECK_PHONE:
      return successCheckPhoneHandler(state, action);
    case SUCCESS_CHECK_CODE:
      return successCheckCodeHandler(state, action);
    case CANCEL_SEND_CODE:
      return cancelCheckCodeHandler(state);
    case SUCCESS_REGISTER:
      return successRegisterHandler(state, action);
    case FAIL_LOGIN:
      return failLoginHandler(state, action);
    case FORGOT_PASSWORD:
      return forgotPasswordHandler(state);
    case CANCEL_FORGOT_PASSWORD:
      return cancelForgotPasswordHandler(state);
    case SUCCESS_CHECK_RESET_CODE:
      return successCheckResetCodeHandler(state, action);
    case SUCCESS_RESET_PASSWORD:
      return successResetPasswordHandler(state);
    case CANCEL_RESET_PASSWORD:
      return cancelResetPasswordHandler(state);
    case AFTER_RESET_PASSWORD:
      return afterResetPasswordHandler(state);
    case FAIL_RESET_PASSWORD:
      return failResetPasswordHandler(state, action);
    case FAIL_REGISTER:
      return failRegisterHandler(state, action);
    case SUCCESS_LOGOUT:
      return successLogoutHandler(state);
    case FIRE_CHECK_CODE:
      return fireCheckCodeHandler(state);
    case FIRE_CHECK_RESET_CODE:
      return fireCheckResetCodeHandler(state);
    case FIRE_LOGIN:
      return fireLoginHandler(state);
    case FIRE_RESET_PASSWORD:
      return fireResetPasswordHandler(state);
    case FIRE_REGISTER:
      return fireRegisterHandler(state);
    case CHANGE_PASSWORD_ERROR:
      return changePasswordErrorHandler(state, action);
    case CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccessHandler(state, action);
    default:
      return state;
  }
};

export default userReducer;
