import { TActionCreator } from 'store/TActionCreator';
import { TOrder } from 'store/reducers/client/TOrder';

export const SUCCESS_LOAD_ORDERS = 'SUCCESS_LOAD_ORDERS';

export const successLoadOrders: TActionCreator<
  typeof SUCCESS_LOAD_ORDERS,
  { orders: TOrder[] }
> = (payload) => ({
  type: SUCCESS_LOAD_ORDERS,
  payload,
});
