import { TClientState } from 'store/reducers/client/TClientState';
import { successLoadPets } from 'store/actions/client/successLoadPets';

const successLoadPetsHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successLoadPets>,
): TClientState => ({
  ...state,
  pets: payload.pets,
});

export default successLoadPetsHandler;
