import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { fireSitterSettingsSave } from 'store/actions/sitter/fireSitterSettingsSave';
import sitterSettingsSaveFetch from 'api/sitter/sitterSettingsSave/sitterSettingsSaveFetch';
import { setSitterSettings } from 'store/actions/sitter/setSitterSettings';
import { successSitterSettingsSave } from 'store/actions/sitter/successSitterSettingsSave';

function* sitterSettingsSaveSaga({
  payload,
}: ReturnType<typeof fireSitterSettingsSave>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: sitterSettingsSaveFetch,
      payload: { ...payload.settings },
    });
    yield put(successSitterSettingsSave());
    if (response.type === EResponseType.OK) {
      yield put(setSitterSettings({ settings: payload.settings }));
      toast.success(getLang('SAVE_SUCCESS'));
    } else {
      toast.error(getLang('SAVE_ERROR'));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default sitterSettingsSaveSaga;
