import { EOrderType } from 'store/reducers/client/TOrder';

export enum ERequestType {
  FromSitter = 1,
  FromMe = 2,
}

export enum ERequestStatus {
  Waiting = 1,
  Approved = 2,
  Cancelled = 3,
}

export type TRequest = {
  id: number;
  addressPoint: [number, number];
  message: string;
  offer: number | null;
  repeatOrderCount: number;
  reviewsCount: number;
  workerName: string;
  status: ERequestStatus;
  type: ERequestType;
  workerRating: number;
  workerAddress: string;
  workerId: number;
  workerAvatar: string;
  rate: number;
  orderType: EOrderType;
};
