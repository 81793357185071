import { TActionCreator } from 'store/TActionCreator';

export const FAIL_SEND_MESSAGE = 'FAIL_SEND_MESSAGE';

export const failSendMessage: TActionCreator<
  typeof FAIL_SEND_MESSAGE,
  {
    dialogueId: number;
    date: Date;
  }
> = (payload) => ({
  type: FAIL_SEND_MESSAGE,
  payload,
});
