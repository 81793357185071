import { TUserState } from 'store/reducers/user/TUserState';

const fireRegisterHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    registerError: null,
  };
};

export default fireRegisterHandler;
