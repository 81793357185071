import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_CHECK_RESET_CODE = 'SUCCESS_CHECK_RESET_CODE';

export const successCheckResetCode: TActionCreator<
  typeof SUCCESS_CHECK_RESET_CODE,
  { correctCode: number | boolean }
> = (payload) => ({
  type: SUCCESS_CHECK_RESET_CODE,
  payload,
});
