import { TAction } from 'store/TAction';

import { SET_BECOME_SITTER_STEP } from 'store/actions/sitter/setBecomeSitterStep';
import { SET_BECOME_SITTER_SERVICES } from 'store/actions/sitter/setBecomeSitterServices';
import { SET_BECOME_SITTER_MESSAGE_FROM_ADMIN } from 'store/actions/sitter/setBecomeSitterMessageFromAdmin';
import { SET_BECOME_SITTER_IDENTITY } from 'store/actions/sitter/setBecomeSitterIdentity';
import { SET_BECOME_SITTER_PROFILE } from 'store/actions/sitter/setBecomeSiiterProfile';
import { SET_SITTER_PROFILE } from 'store/actions/sitter/setSitterProfile';
import { FIRE_SITTER_PROFILE_SAVE } from 'store/actions/sitter/fireSitterProfileSave';
import { SUCCESS_SITTER_PROFILE_SAVE } from 'store/actions/sitter/successSitterProfileSave';
import { SET_SITTER_SETTINGS } from 'store/actions/sitter/setSitterSettings';
import { FIRE_SITTER_SETTINGS_SAVE } from 'store/actions/sitter/fireSitterSettingsSave';
import { SUCCESS_SITTER_SETTINGS_SAVE } from 'store/actions/sitter/successSitterSettingsSave';
import { FIRE_DELETE_PROFILE_PHOTO } from 'store/actions/sitter/fireDeleteProfilePhoto';
import { FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO } from 'store/actions/sitter/fireBecomeSitterDeleteProfilePhoto';
import { SUCCESS_LOAD_SITTER_REQUESTS } from 'store/actions/sitter/successLoadSitterRequests';
import { SUCCESS_CANCEL_SITTER_REQUEST } from 'store/actions/sitter/successCancelSitterRequest';
import { SUCCESS_ACCEPT_SITTER_REQUEST } from 'store/actions/sitter/successAcceptSitterRequest';

import { TSitterState } from './TSitterState';
import setBecomeSitterStepHandler from './handlers/setBecomeSitterStepHandler';
import setBecomeSitterServicesHandler from './handlers/setBecomeSitterServicesHandler';
import setBecomeSitterMessageFromAdminHandler from './handlers/setBecomeSitterMessageFromAdminHandler';
import setBecomeSitterIdentityHandler from './handlers/setBecomeSitterIdentityHandler';
import setBecomeSitterProfileHandler from './handlers/setBecomeSitterIdentityProfile';
import setSitterProfileHandler from './handlers/setSitterProfileHandler';
import fireSitterProfileSaveHandler from './handlers/fireSItterProfileSaveHandler';
import successSitterProfileSaveHandler from './handlers/successSitterProfileSaveHandler';
import setSitterSettingsHandler from './handlers/setSitterSettingsHandler';
import fireSitterSettingsSaveHandler from './handlers/fireSitterSettingsSaveHandler';
import successSitterSettingsSaveHandler from './handlers/successSitterSettingsSaveHandler';
import fireDeleteProfilePhotoHandler from './handlers/fireDeleteProfilePhotoHandler';
import fireDeleteBecomeSitterProfilePhotoHandler from './handlers/fireDeleteBecomeSitterProfilePhotoHandler';
import successLoadSitterRequestsHandler from './handlers/successLoadSitterRequestsHandler';
import successCancelSitterRequestHandler from './handlers/successCancelSitterRequestHandler';
import successAcceptSitterRequestHandler from './handlers/successAcceptSitterRequestHandler';

export const sitterInitialState: TSitterState = {
  becomeSitterStep: null,
  becomeSitterServices: [],
  messageFromAdmin: null,
  identity: null,
  becomeSitterProfile: null,
  profile: null,
  profileSaving: false,
  settings: null,
  settingsSaving: false,
  requests: null,
};

const sitterReducer = (
  state = sitterInitialState,
  action: TAction,
): TSitterState => {
  switch (action.type) {
    case SET_BECOME_SITTER_STEP:
      return setBecomeSitterStepHandler(state, action);
    case SET_BECOME_SITTER_SERVICES:
      return setBecomeSitterServicesHandler(state, action);
    case SET_BECOME_SITTER_MESSAGE_FROM_ADMIN:
      return setBecomeSitterMessageFromAdminHandler(state, action);
    case SET_BECOME_SITTER_IDENTITY:
      return setBecomeSitterIdentityHandler(state, action);
    case SET_BECOME_SITTER_PROFILE:
      return setBecomeSitterProfileHandler(state, action);
    case SET_SITTER_PROFILE:
      return setSitterProfileHandler(state, action);
    case FIRE_SITTER_PROFILE_SAVE:
      return fireSitterProfileSaveHandler(state);
    case SUCCESS_SITTER_PROFILE_SAVE:
      return successSitterProfileSaveHandler(state);
    case SET_SITTER_SETTINGS:
      return setSitterSettingsHandler(state, action);
    case FIRE_SITTER_SETTINGS_SAVE:
      return fireSitterSettingsSaveHandler(state);
    case SUCCESS_SITTER_SETTINGS_SAVE:
      return successSitterSettingsSaveHandler(state);
    case FIRE_DELETE_PROFILE_PHOTO:
      return fireDeleteProfilePhotoHandler(state, action);
    case FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO:
      return fireDeleteBecomeSitterProfilePhotoHandler(state, action);
    case SUCCESS_LOAD_SITTER_REQUESTS:
      return successLoadSitterRequestsHandler(state, action);
    case SUCCESS_CANCEL_SITTER_REQUEST:
      return successCancelSitterRequestHandler(state, action);
    case SUCCESS_ACCEPT_SITTER_REQUEST:
      return successAcceptSitterRequestHandler(state, action);
    default:
      return state;
  }
};

export default sitterReducer;
