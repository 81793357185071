import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const uploadPetPhotosCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return rawPayloadData.data.map((rawPhoto) => ({
    id: rawPhoto.id,
    url: rawPhoto.path,
  }));
};

export default uploadPetPhotosCleaner;
