import { TActionCreator } from 'store/TActionCreator';

export const FAIL_REGISTER = 'FAIL_REGISTER';

export enum ERegisterError {
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  EMAIL_ALREADY_REGISTERED = 'EMAIL_ALREADY_REGISTERED',
}

export const failRegister: TActionCreator<
  typeof FAIL_REGISTER,
  { code: ERegisterError }
> = (payload) => ({
  type: FAIL_REGISTER,
  payload,
});
