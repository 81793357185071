import { TClientState } from 'store/reducers/client/TClientState';
import { successLoadSitterData } from 'store/actions/client/successLoadSitterData';

const successLoadSitterDataHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successLoadSitterData>,
): TClientState => ({
  ...state,
  sitterProfile: payload.data,
});

export default successLoadSitterDataHandler;
