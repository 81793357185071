import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TMessagesAsReadParameters } from './types/TMessagesAsReadParameters';

const messagesAsReadFetch = async ({
  dialogueId,
}: TMessagesAsReadParameters): Promise<TPayload<unknown> | never> => {
  const url = new URL(`${API_URL}/chat/as_read`);
  const body = new FormData();
  body.append('group_id', dialogueId.toString());

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body,
  });
};

export default messagesAsReadFetch;
