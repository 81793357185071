import React, { FC } from 'react';

const Bird: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path d="M28.547 10.617a1.055 1.055 0 100-2.11 1.055 1.055 0 000 2.11z" />
      <path d="M34.945 10.617H33.82a6.335 6.335 0 00-6.328-6.328 6.354 6.354 0 00-4.607 1.99L4.59 25.699.315 29.904a1.055 1.055 0 00.74 1.807h.362c3.964 0 7.83-1.533 10.681-4.219h3.14l1.819 3.636a1.055 1.055 0 101.886-.944l-1.346-2.692H18c.48 0 .954-.025 1.42-.073l1.855 3.709a1.055 1.055 0 101.887-.944l-1.583-3.167c5.83-1.578 10.132-6.913 10.132-13.236 0-.581.473-1.055 1.055-1.055h2.18a1.055 1.055 0 000-2.109zm-18.14 5.191c.018-.012.834-.972 2.253-.972 1.873 0 2.896 1.37 3.08 2.729.262 1.955-.94 3.599-4.138 3.599h-6.24l5.046-5.356zM3.86 29.379l1.917-1.887h2.897a13.608 13.608 0 01-4.814 1.887zM31.706 10.8a3.169 3.169 0 00-2.104 2.981c0 6.397-5.205 11.602-11.602 11.602H7.786l1.987-2.11H18c4.558 0 6.653-2.833 6.227-5.99-.314-2.336-2.038-4.255-4.55-4.523l4.744-5.035a4.18 4.18 0 013.071-1.327 4.224 4.224 0 014.214 4.402z" />
    </g>
  </svg>
);

export default Bird;
