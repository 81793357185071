import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { fireSendRequest } from 'store/actions/client/fireSendRequest';
import sendRequestFetch from 'api/client/sendRequest/sendRequestFetch';
import { successSendRequest } from 'store/actions/client/successSendRequest';

function* sendRequestSaga({
  payload: { userId, orderId },
}: ReturnType<typeof fireSendRequest>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: sendRequestFetch,
      payload: { userId, orderId },
    });
    if (response.type === EResponseType.OK) {
      toast.success(getLang('SEND_REQUEST_SUCCESS'));
      yield put(successSendRequest({ userId, orderId }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default sendRequestSaga;
