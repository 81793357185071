import { TActionCreator } from 'store/TActionCreator';

export const FIRE_CHANGE_PASSWORD = 'FIRE_CHANGE_PASSWORD';

export const fireChangePassword: TActionCreator<
  typeof FIRE_CHANGE_PASSWORD,
  { oldPassword: string; password: string; repeatPassword: string }
> = (payload) => ({
  type: FIRE_CHANGE_PASSWORD,
  payload,
});
