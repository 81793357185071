import { TUserState } from 'store/reducers/user/TUserState';
import { changePasswordSuccess } from 'store/actions/user/changePasswordSuccess';

const changePasswordSuccessHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof changePasswordSuccess>,
): TUserState => {
  return {
    ...state,
    changePasswordSuccess: payload.success,
  };
};

export default changePasswordSuccessHandler;
