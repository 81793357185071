import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { TOkayPayloadData } from 'api/user/checkPhone/types/TOkayPayloadData';
import { TOkayPayload } from 'api/types/TPayload';
import { fireCheckPhone } from 'store/actions/user/fireCheckPhone';
import checkPhoneFetch from 'api/user/checkPhone/checkPhoneFetch';
import { successCheckPhone } from 'store/actions/user/successCheckPhone';
import fetchSaga from 'store/sagas/fetchSaga';

function* checkPhoneSaga({
  payload,
}: ReturnType<typeof fireCheckPhone>): SagaIterator<void> {
  try {
    const response: TOkayPayload<TOkayPayloadData> = yield call(fetchSaga, {
      fetcher: checkPhoneFetch,
      payload,
    });

    yield put(successCheckPhone({ exists: response.data.phoneExists }));
    // eslint-disable-next-line no-empty
  } catch { }
}

export default checkPhoneSaga;
