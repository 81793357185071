import { TActionCreator } from 'store/TActionCreator';
import { TProfile } from 'store/reducers/profile/TProfile';

export type TNewAvatar = {
  newAvatar?: {
    data: File;
    preview: string;
  };
};

export const FIRE_SAVE_PROFILE = 'FIRE_SAVE_PROFILE';

export const fireSaveProfile: TActionCreator<
  typeof FIRE_SAVE_PROFILE,
  { profile: TProfile & TNewAvatar }
> = (payload) => ({
  type: FIRE_SAVE_PROFILE,
  payload,
});
