import { TUserState } from 'store/reducers/user/TUserState';
import { failResetPassword } from 'store/actions/user/failResetPassword';

const failResetPasswordHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof failResetPassword>,
): TUserState => {
  return {
    ...state,
    resetPasswordError: payload.code,
  };
};

export default failResetPasswordHandler;
