import { TAction } from 'store/TAction';

import { SUCCESS_LOAD_PROFILE } from 'store/actions/profile/successLoadProfile';
import { FIRE_SAVE_PROFILE } from 'store/actions/profile/fireSaveProfile';
import { SUCCESS_SAVE_PROFILE } from 'store/actions/profile/successSaveProfile';

import { TProfileState } from './TProfileState';
import successLoadProfileHandler from './handlers/successLoadProfileHandler';
import fireSaveProfileHandler from './handlers/fireSaveProfileHandler';
import successSaveProfileHandler from './handlers/successSaveProfileHandler';

export const profileInitialState: TProfileState = {
  profile: null,
  profileSaving: false,
};

const profileReducer = (
  state = profileInitialState,
  action: TAction,
): TProfileState => {
  switch (action.type) {
    case SUCCESS_LOAD_PROFILE:
      return successLoadProfileHandler(state, action);
    case FIRE_SAVE_PROFILE:
      return fireSaveProfileHandler(state);
    case SUCCESS_SAVE_PROFILE:
      return successSaveProfileHandler(state, action);
    default:
      return state;
  }
};

export default profileReducer;
