import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { TOkayPayloadData } from 'api/user/register/types/TOkayPayloadData';
import { TPayload } from 'api/types/TPayload';
import { fireResetPassword } from 'store/actions/user/fireResetPassword';
import resetPasswordFetch from 'api/user/resetPassword/resetPasswordFetch';
import EResponseType from 'api/enums/EResponseType';
import { successResetPassword } from 'store/actions/user/successResetPassword';
import {
  EResetPasswordError,
  failResetPassword,
} from 'store/actions/user/failResetPassword';
import fetchSaga from 'store/sagas/fetchSaga';

function* resetPasswordSaga({
  payload,
}: ReturnType<typeof fireResetPassword>): SagaIterator<void> {
  try {
    const phone: string = yield select((state) => state.user.checkPhone);
    const code: number = yield select((state) => state.user.correctResetCode);
    const response: TPayload<TOkayPayloadData> = yield call(fetchSaga, {
      fetcher: resetPasswordFetch,
      payload: {
        phone,
        code,
        password: payload.password,
      },
    });
    if (response.type === EResponseType.OK) {
      yield put(successResetPassword());
    } else if (response.error.data.password) {
      yield put(
        failResetPassword({ code: EResetPasswordError.PASSWORD_TOO_SHORT }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch { }
}

export default resetPasswordSaga;
