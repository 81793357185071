import { TActionCreator } from 'store/TActionCreator';
import { TPet } from 'store/reducers/client/TPet';

export const FIRE_SAVE_PET = 'FIRE_SAVE_PET';

export const fireSavePet: TActionCreator<
  typeof FIRE_SAVE_PET,
  { pet: Partial<TPet> }
> = (payload) => ({
  type: FIRE_SAVE_PET,
  payload,
});
