import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const checkPhoneCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return {
    phoneExists: rawPayloadData.exists,
  };
};

export default checkPhoneCleaner;
