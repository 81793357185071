import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_SEND_MESSAGE = 'SUCCESS_SEND_MESSAGE';

export const successSendMessage: TActionCreator<
  typeof SUCCESS_SEND_MESSAGE,
  {
    dialogueId: number;
    date: Date;
    realDate: Date;
    id: string;
  }
> = (payload) => ({
  type: SUCCESS_SEND_MESSAGE,
  payload,
});
