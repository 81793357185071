import { TActionCreator } from 'store/TActionCreator';
import { Optional } from 'types/global';

export const SET_CURRENT_DIALOGUE = 'SET_CURRENT_DIALOGUE';

export const setCurrentDialogue: TActionCreator<
  typeof SET_CURRENT_DIALOGUE,
  { dialogueId: Optional<number>; historyPush?: (path: string) => void }
> = (payload) => ({
  type: SET_CURRENT_DIALOGUE,
  payload,
});
