import { useEffect } from 'react';

const useSiteLoad = () => {
  useEffect(() => {
    // Landings
    import('containers/Landing/Landing');
    import('containers/Landing/LandingSitter');

    // Auth
    import('containers/Auth/Login');
    import('containers/Auth/CheckPhone');
    import('containers/Auth/SendCode');
    import('containers/Auth/Registration');
    import('containers/Auth/EnterPassword');
    import('containers/Auth/ResetPassword');
    import('containers/Auth/ResetPasswordSuccess');

    // Search
    import('containers/Main/FindSitter');
    import('containers/Main/SitterPage');

    // Cabinet
    import('containers/Cabinet/Main');
    import('containers/Cabinet/Settings/Settings');
    import('containers/Cabinet/Chat/Chat');
    import('containers/Cabinet/BecomeSitter/BecomeSitter');
    import('containers/Cabinet/Profile');
  }, []);
};

export default useSiteLoad;
