import React, { FC } from 'react';

const Close: FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.412 12l4.3-4.29a1.004 1.004 0 00-1.42-1.42l-4.29 4.3-4.29-4.3a1.004 1.004 0 10-1.42 1.42l4.3 4.29-4.3 4.29a.999.999 0 000 1.42 1.001 1.001 0 001.42 0l4.29-4.3 4.29 4.3a1.002 1.002 0 001.639-.325 1 1 0 00-.22-1.095l-4.3-4.29z"
      fill="currentColor"
    />
  </svg>
);

export default Close;
