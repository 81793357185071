import React, { FC } from 'react';

const Briefcase: FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.157 6.294h-3.255V5.45c0-.69-.236-1.27-.708-1.742C14.72 3.236 14.14 3 13.45 3h-3.294c-.689 0-1.27.236-1.742.709-.472.472-.709 1.053-.709 1.742v.843H4.451c-.69 0-1.27.23-1.742.689C2.236 7.443 2 8.03 2 8.745V12c0 .536.268.804.804.804v6.55c0 .433.166.816.498 1.148.332.332.715.498 1.15.498h14.705c.434 0 .817-.166 1.15-.498.331-.332.497-.715.497-1.149v-6.549c.536 0 .805-.268.805-.804V8.745c0-.715-.237-1.302-.709-1.762-.472-.46-1.053-.69-1.743-.69zM9.353 5.45a.78.78 0 01.23-.574.78.78 0 01.574-.23h3.294a.78.78 0 01.574.23.78.78 0 01.23.574v.843H9.353V5.45zM4.451 19.353v-6.549h4.902v2.451c0 .46.16.85.479 1.168.32.32.708.48 1.168.48h1.608c.46 0 .85-.16 1.169-.48.319-.319.478-.708.478-1.168v-2.45h4.902v6.548H4.451zM11 15.255v-2.45h1.608v2.45H11zm8.962-4.06H3.647v-2.45c0-.562.268-.843.804-.843h14.706c.537 0 .805.281.805.843v2.45z"
      fill="currentColor"
    />
  </svg>
);

export default Briefcase;
