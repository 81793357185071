import { takeLatest } from 'redux-saga/effects';

import { FIRE_BECOME_SITTER_SERVICES_SAVE } from 'store/actions/sitter/fireBecomeSitterServicesSave';
import { FIRE_GET_BECOME_SITTER_STATUS } from 'store/actions/sitter/fireGetBecomeSitterStatus';
import { FIRE_BECOME_SITTER_SERVICES_OPTIONS_SAVE } from 'store/actions/sitter/fireBecomeSitterServicesOptionsSave';
import { FIRE_BECOME_SITTER_IDENTITY_SAVE } from 'store/actions/sitter/fireBecomeSitterIdentitySave';
import { FIRE_BECOME_SITTER_PROFILE_SAVE } from 'store/actions/sitter/fireBecomeSitterProfileSave';
import { FIRE_BECOME_SITTER_TEST_COMPLETE } from 'store/actions/sitter/fireBecomeSitterTestComplete';
import { FIRE_SITTER_PROFILE_SAVE } from 'store/actions/sitter/fireSitterProfileSave';
import { FIRE_SITTER_SETTINGS_SAVE } from 'store/actions/sitter/fireSitterSettingsSave';
import { FIRE_DELETE_PROFILE_PHOTO } from 'store/actions/sitter/fireDeleteProfilePhoto';
import { FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO } from 'store/actions/sitter/fireBecomeSitterDeleteProfilePhoto';
import { FIRE_LOAD_SITTER_REQUESTS } from 'store/actions/sitter/fireLoadSitterRequests';
import { FIRE_CANCEL_SITTER_REQUEST } from 'store/actions/sitter/fireCancelSitterRequest';
import { FIRE_ACCEPT_SITTER_REQUEST } from 'store/actions/sitter/fireAcceptSitterRequest';

import becomeSitterServicesSaveSaga from './becomeSitterServicesSaveSaga';
import getBecomeSitterStatusSaga from './getBecomeSitterStatusSaga';
import becomeSitterServicesOptionsSaveSaga from './becomeSitterServicesOptionsSaveSaga';
import becomeSitterIdentitySaveSaga from './becomeSitterIdentitySaveSaga';
import becomeSitterProfileSaveSaga from './becomeSitterProfileSaveSaga';
import becomeSitterTestCompleteSaga from './becomeSitterTestCompleteSaga';
import sitterProfileSaveSaga from './sitterProfileSaveSaga';
import sitterSettingsSaveSaga from './sitterSettingsSaveSaga';
import deleteProfilePhotoSaga from './deleteProfilePhotoSaga';
import loadSitterRequestsSaga from './loadSitterRequestsSaga';
import cancelSitterRequestSaga from './cancelSitterRequestSaga';
import acceptSitterRequestSaga from './acceptSitterRequestSaga';

export default function* rootSitterSaga() {
  yield takeLatest(
    FIRE_BECOME_SITTER_SERVICES_SAVE,
    becomeSitterServicesSaveSaga,
  );
  yield takeLatest(FIRE_GET_BECOME_SITTER_STATUS, getBecomeSitterStatusSaga);
  yield takeLatest(
    FIRE_BECOME_SITTER_SERVICES_OPTIONS_SAVE,
    becomeSitterServicesOptionsSaveSaga,
  );
  yield takeLatest(
    FIRE_BECOME_SITTER_IDENTITY_SAVE,
    becomeSitterIdentitySaveSaga,
  );
  yield takeLatest(
    FIRE_BECOME_SITTER_PROFILE_SAVE,
    becomeSitterProfileSaveSaga,
  );
  yield takeLatest(
    FIRE_BECOME_SITTER_TEST_COMPLETE,
    becomeSitterTestCompleteSaga,
  );
  yield takeLatest(FIRE_SITTER_PROFILE_SAVE, sitterProfileSaveSaga);
  yield takeLatest(FIRE_SITTER_SETTINGS_SAVE, sitterSettingsSaveSaga);
  yield takeLatest(FIRE_DELETE_PROFILE_PHOTO, deleteProfilePhotoSaga);
  yield takeLatest(
    FIRE_BECOME_SITTER_DELETE_PROFILE_PHOTO,
    deleteProfilePhotoSaga,
  );
  yield takeLatest(FIRE_LOAD_SITTER_REQUESTS, loadSitterRequestsSaga);
  yield takeLatest(FIRE_CANCEL_SITTER_REQUEST, cancelSitterRequestSaga);
  yield takeLatest(FIRE_ACCEPT_SITTER_REQUEST, acceptSitterRequestSaga);
}
