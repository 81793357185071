import { TActionCreator } from 'store/TActionCreator';
import { TProfile } from 'store/reducers/profile/TProfile';

export const SUCCESS_SAVE_PROFILE = 'SUCCESS_SAVE_PROFILE';

export const successSaveProfile: TActionCreator<
  typeof SUCCESS_SAVE_PROFILE,
  { profile?: TProfile }
> = (payload) => ({
  type: SUCCESS_SAVE_PROFILE,
  payload,
});
