import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TSitterProfileSave } from './types/TSitterProfileSave';

const sitterProfileSaveFetch = async ({
  title,
  description,
  constantSupervision,
  allowBed,
  houseType,
  hasChildren,
  hasCar,
  careExperience,
  canInjection,
  canMedicine,
  newPhotos,
  address,
}: TSitterProfileSave): Promise<TPayload<unknown> | never> => {
  const url = new URL(`${API_URL}/my/profile/update`);

  const body = new FormData();
  body.append('title', title);
  body.append('description', description);
  body.append('constant_supervision', constantSupervision ? '1' : '0');
  body.append('pets_on_the_bed', allowBed ? '1' : '0');
  body.append('type_of_housing', houseType === 'house' ? '1' : '2');
  body.append('children_under_twelve_yo', hasChildren ? '1' : '0');
  body.append('has_car', hasCar ? '1' : '0');
  body.append('care_experience', careExperience.toString());
  body.append('give_medication', canMedicine ? '1' : '0');
  body.append('give_injection', canInjection ? '1' : '0');
  body.append('address_q', address);
  newPhotos?.forEach((photo, index) => {
    body.append(`files[${index}]`, photo);
  });

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body,
  });
};

export default sitterProfileSaveFetch;
