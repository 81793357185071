import React, { FC, ChangeEvent, useEffect } from 'react';
import Logo from 'icons/Logo';
import Button from 'components/Button/Button';
import Paw from 'icons/Paw';
import GlobeIcon from 'icons/GlobeIcon'; // Assuming you have a globe icon
import { setLanguage, availableLanguages, getLang } from 'libs/langs/langs';
import Text from 'components/Text/Text';
import Search from 'icons/Search';
import User from 'icons/User';
import selectIsLoggedIn from 'store/selectors/user/selectIsLoggedIn';
import { useSelector, useDispatch } from 'react-redux';
import selectRoles from 'store/selectors/profile/selectRoles';
import selectCurrentLanguage from 'store/selectors/main/selectCurrentLanguage';
import globalConfig from 'config/globalConfig.json';
import selectBecomeSitterStep from 'store/selectors/sitter/selectBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';

import styles from './Header.module.css';

const Header: FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const roles = useSelector(selectRoles);
  const becomeSitterStep = useSelector(selectBecomeSitterStep);
  const currentLanguage = useSelector(selectCurrentLanguage);

  const dispatch = useDispatch();

  const handleChangeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLang = event.target.value;
    setLanguage(dispatch, newLang);
    // Сохранить выбранный язык в локальное хранилище браузера
    localStorage.setItem('selectedLanguage', newLang);
    window.location.reload();
  };

  // Загрузить выбранный язык из локального хранилища при монтировании компонента
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setLanguage(dispatch, savedLanguage);
    }
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <Button className={styles.logo} view="ghost" href="/">
        <Logo />
      </Button>
      <div className={styles.right}>

        {isLoggedIn === null && (
          <div className={styles.buttonsSkeleton}>
            <div className={styles.becomeSitterSkeleton} />
            <div className={styles.findSitterSkeleton} />
            <div className={styles.loginSkeleton} />
          </div>
        )}
        {isLoggedIn !== null && (
          <>
            {roles.length === 0 && (
              <Button
                href={
                  becomeSitterStep &&
                    becomeSitterStep !== EBecomeSitterStep.Services
                    ? '/cabinet/become-sitter'
                    : '/become-sitter'
                }
                view="ghost"
                mode="secondary"
                leftAddon={<Paw />}
              >
                <Text className={styles.text}>{getLang('BECOME_SITTER')}</Text>
              </Button>
            )}
            {!globalConfig.IS_MVP && (
              <Button
                view="ghost"
                mode="secondary"
                leftAddon={<Search />}
                href="/search"
              >
                <Text className={styles.text}>{getLang('FIND_A_SITTER')}</Text>
              </Button>
            )}
            {!isLoggedIn && (
              <Button
                href="/login"
                view="ghost"
                mode="secondary"
                leftAddon={<User />}
              >
                <Text className={styles.text}>{getLang('LOG_IN')}</Text>
              </Button>
            )}
            {isLoggedIn && (
              <Button
                href="/cabinet"
                view="ghost"
                mode="secondary"
                leftAddon={<User />}
              >
                <Text className={styles.text}>{getLang('CABINET')}</Text>
              </Button>
            )}
            <div className={styles.languageSelectorContainer}>
              <div className={styles.customSelect}>
                <select
                  onChange={handleChangeLanguage}
                  value={currentLanguage}
                  className={styles.languageSelector}
                >
                  {availableLanguages.map(lang => (
                    <option key={lang.code} value={lang.code}>{lang.code}</option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {/*<Button view="ghost" className={styles.notification}>*/}
        {/*<Notification />*/}
        {/*<span className={styles.notificationBadge}>*/}
        {/*	<Text className={styles.notificationBadgeText}>12</Text>*/}
        {/*</span>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default Header;
