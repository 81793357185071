import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const getSocketParamsCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { app_cluster, app_key, host, namespace, port, tls} = rawPayloadData;
  return {
    appKey: app_key,
    appCluster: app_cluster,
    host,
    namespace,
    port,
    tls,
  };
};

export default getSocketParamsCleaner;
