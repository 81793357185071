import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TCancelRequestParameters } from './types/TCancelRequestParameters';

const cancelRequestFetch = async ({
  requestId,
}: TCancelRequestParameters): Promise<unknown> => {
  const url = new URL(`${API_URL}/orders/requests/${requestId}/client`);

  return apiFetch(() => null, url.toString(), {
    method: 'DELETE',
  });
};

export default cancelRequestFetch;
