import { takeLatest, all, fork } from 'redux-saga/effects';
import { INITIAL } from 'store/actions/main/initial';
import initialSaga from './initialSaga';
import watchSetLanguage from './languageSaga';

export default function* rootMainSaga() {
  yield all([
    takeLatest(INITIAL, initialSaga),
    fork(watchSetLanguage),
    // ... добавьте другие саги здесь ...
  ]);
}
