import { TActionCreator } from 'store/TActionCreator';

export const FIRE_LOAD_REQUESTS = 'FIRE_LOAD_REQUESTS';

export const fireLoadRequests: TActionCreator<
  typeof FIRE_LOAD_REQUESTS,
  { orderId: number }
> = (payload) => ({
  type: FIRE_LOAD_REQUESTS,
  payload
});
