import React, { FC } from 'react';

const Calendar: FC = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
    <g>
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        d="m18.83537,4.80398l-13.85595,0a1.97942,1.80398 0 0 0 -1.97942,1.80398l0,12.62789a1.97942,1.80398 0 0 0 1.97942,1.80398l13.85595,0a1.97942,1.80398 0 0 0 1.97942,-1.80398l0,-12.62789a1.97942,1.80398 0 0 0 -1.97942,-1.80398zm-2.96913,-1.80398l0,3.60797m-7.91769,-3.60797l0,3.60797m-4.94855,3.60797l17.8148,0"
      />
    </g>
  </svg>
);

export default Calendar;
