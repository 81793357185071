import { takeLatest } from 'redux-saga/effects';

import { FIRE_SEARCH_SITTER } from 'store/actions/client/fireSearchSitter';
import { FIRE_LOAD_SITTER_DATA } from 'store/actions/client/fireLoadSitterData';
import { FIRE_LOAD_REVIEWS } from 'store/actions/client/fireLoadReviews';
import { FIRE_LOAD_PETS } from 'store/actions/client/fireLoadPets';
import { FIRE_DELETE_PET_PHOTO } from 'store/actions/client/fireDeletePetPhoto';
import { FIRE_SAVE_PET } from 'store/actions/client/fireSavePet';
import { FIRE_UPLOAD_PET_PHOTOS } from 'store/actions/client/fireUploadPetPhotos';
import { FIRE_DELETE_PET } from 'store/actions/client/fireDeletePet';
import { FIRE_LOAD_ORDERS } from 'store/actions/client/fireLoadOrders';
import { FIRE_SAVE_ORDER } from 'store/actions/client/fireSaveOrder';
import { FIRE_SEND_REQUEST } from 'store/actions/client/fireSendRequest';
import { FIRE_LOAD_REQUESTS } from 'store/actions/client/fireLoadRequests';
import { FIRE_APPROVE_REQUEST } from 'store/actions/client/fireApproveRequest';

import searchSitterSaga from './searchSitterSaga';
import loadSitterSaga from './loadSitterSaga';
import loadReviewsSaga from './loadReviewsSaga';
import loadPetsSaga from './loadPetsSaga';
import deletePetPhotoSaga from './deletePetPhotoSaga';
import savePetSaga from './savePetSaga';
import uploadPetPhotosSaga from './uploadPetPhotosSaga';
import deletePetSaga from './deletePetSaga';
import loadOrdersSaga from './loadOrdersSaga';
import saveOrderSaga from './saveOrderSaga';
import sendRequestSaga from './sendRequestSaga';
import loadRequestsSaga from './loadRequestsSaga';
import approveRequestSaga from './approveRequestSaga';

export default function* rootClientSaga() {
  yield takeLatest(FIRE_SEARCH_SITTER, searchSitterSaga);
  yield takeLatest(FIRE_LOAD_SITTER_DATA, loadSitterSaga);
  yield takeLatest(FIRE_LOAD_REVIEWS, loadReviewsSaga);
  yield takeLatest(FIRE_LOAD_PETS, loadPetsSaga);
  yield takeLatest(FIRE_DELETE_PET_PHOTO, deletePetPhotoSaga);
  yield takeLatest(FIRE_SAVE_PET, savePetSaga);
  yield takeLatest(FIRE_UPLOAD_PET_PHOTOS, uploadPetPhotosSaga);
  yield takeLatest(FIRE_DELETE_PET, deletePetSaga);
  yield takeLatest(FIRE_LOAD_ORDERS, loadOrdersSaga);
  yield takeLatest(FIRE_SAVE_ORDER, saveOrderSaga);
  yield takeLatest(FIRE_SEND_REQUEST, sendRequestSaga);
  yield takeLatest(FIRE_LOAD_REQUESTS, loadRequestsSaga);
  yield takeLatest(FIRE_APPROVE_REQUEST, approveRequestSaga);
}
