import { TActionCreator } from 'store/TActionCreator';
import { TOrder } from 'store/reducers/client/TOrder';

export const FIRE_SAVE_ORDER = 'FIRE_SAVE_ORDER';

export const fireSaveOrder: TActionCreator<
  typeof FIRE_SAVE_ORDER,
  { order: Partial<TOrder>; address?: string }
> = (payload) => ({
  type: FIRE_SAVE_ORDER,
  payload,
});
