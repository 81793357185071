import {
  TRawErrorPayload,
  TRawOkayPayload,
  TRawPayload,
} from 'api/types/TRawPayload';
import EApiErrors from 'api/enums/EApiErrors';
import EResponseType from 'api/enums/EResponseType';
import { TPayload } from 'api/types/TPayload';

import cleanErrorPayload from './clearErrorPayload';
import cleanOkayPayload from './clearOkayPayload';

const cleanPayload = <TRawOkayPayloadData, TCleanPayloadData>(
  payload: TRawPayload<TRawOkayPayloadData>,
  okayPayloadDataCleaner: (data: TRawOkayPayloadData) => TCleanPayloadData,
): TPayload<TCleanPayloadData> => {
  if (!payload) {
    return {
      type: EResponseType.FATAL_ERROR,
      error: {
        code: EApiErrors.EMPTY_PAYLOAD,
        data: null,
      },
    };
  }

  if ((payload as TRawErrorPayload).error) {
    return cleanErrorPayload(payload as TRawErrorPayload);
  }

  return cleanOkayPayload(
    payload as TRawOkayPayload<TRawOkayPayloadData>,
    okayPayloadDataCleaner,
  );
};

export default cleanPayload;
