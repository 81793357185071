import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TResetPasswordParameters } from './types/TResetPasswordParameters';

const resetPasswordFetch = async ({
  code,
  phone,
  password,
}: TResetPasswordParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/password/reset`);
  const body = {
    email: phone,
    code,
    password,
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default resetPasswordFetch;
