import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { fireDeleteProfilePhoto } from 'store/actions/sitter/fireDeleteProfilePhoto';

const fireDeleteProfilePhotoHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof fireDeleteProfilePhoto>,
): TSitterState => {
  if (!state.profile) {
    return state;
  }

  return {
    ...state,
    profile: {
      ...state.profile,
      photos: state.profile.photos.filter((photo) => photo.id !== payload.id),
    },
  };
};

export default fireDeleteProfilePhotoHandler;
