import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadDialoguePayload } from './types/TLoadDialoguePayload';
import loadDialogueCleaner from './loadDialogueCleaner';
import { TLoadDialogueParameters } from './types/TLoadDialogueParameters';

const loadDialogueFetch = async ({
  page,
  dialogueId,
}: TLoadDialogueParameters): Promise<TLoadDialoguePayload | never> => {
  const url = new URL(`${API_URL}/chat/dialog`);
  url.search = new URLSearchParams({
    per_page: '20',
    group_id: dialogueId.toString(),
    page: page.toString(),
  }).toString();

  return apiFetch(loadDialogueCleaner, url.toString());
};

export default loadDialogueFetch;
