import { TOkayPayload } from 'api/types/TPayload';
import { TRawOkayPayload } from 'api/types/TRawPayload';
import EResponseType from 'api/enums/EResponseType';

const cleanOkayPayload = <TRawData, TCleanData>(
	{ response }: TRawOkayPayload<TRawData>,
	cleaner: (data: TRawData) => TCleanData,
): TOkayPayload<TCleanData> => ({
	type: EResponseType.OK,
	data: cleaner(response),
});

export default cleanOkayPayload;
