import { TMedia } from 'store/reducers/chat/TMedia';

export enum EMessageStatus {
  Sending = 'sending',
  Sent = 'sent',
  Read = 'read',
  Failed = 'failed',
}

export type TMessage = {
  id: string;
  date: Date;
  text: string;
  media: TMedia[];
  status: EMessageStatus;
  ownerId: number;
};
