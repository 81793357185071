import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireUploadPetPhotos } from 'store/actions/client/fireUploadPetPhotos';
import uploadPetPhotosFetch from 'api/client/uploadPetPhoto/uploadPetPhotosFetch';
import EResponseType from 'api/enums/EResponseType';
import { successUploadPetPhotos } from 'store/actions/client/successUploadPetPhotos';

function* uploadPetPhotosSaga({
  payload,
}: ReturnType<typeof fireUploadPetPhotos>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: uploadPetPhotosFetch,
      payload: { files: payload.photos, petId: payload.petId },
    });
    if (response.type === EResponseType.OK) {
      yield put(
        successUploadPetPhotos({ petId: payload.petId, photos: response.data }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default uploadPetPhotosSaga;
