import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadRequestsPayload } from './types/TLoadRequestsPayload';
import loadRequestsCleaner from './loadRequestsCleaner';
import { TLoadRequestsParameters } from './types/TLoadRequestsParameters';

const loadRequestsFetch = async ({
  orderId,
}: TLoadRequestsParameters): Promise<TLoadRequestsPayload | never> => {
  const url = new URL(
    `${API_URL}/orders/requests/workers/${orderId}?include=order`,
  );

  return apiFetch(loadRequestsCleaner, url.toString());
};

export default loadRequestsFetch;
