import { TActionCreator } from 'store/TActionCreator';
import { TSitterProfile } from 'store/reducers/sitter/TSitterProfile';

export const SET_SITTER_PROFILE = 'SET_SITTER_PROFILE';

export const setSitterProfile: TActionCreator<
  typeof SET_SITTER_PROFILE,
  { profile: TSitterProfile }
> = (payload) => ({
  type: SET_SITTER_PROFILE,
  payload,
});
