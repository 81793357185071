import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import getSocketParamsCleaner from './getSocketParamsCleaner';
import { TGetSocketParamsPayload } from './types/TGetSocketParamsPayload';

const getSocketParamsFetch = async (): Promise<
  TGetSocketParamsPayload | never
> => {
  const url = new URL(`${API_URL}/chat/verify`);

  return apiFetch(getSocketParamsCleaner, url.toString());
};

export default getSocketParamsFetch;
