import { TActionCreator } from 'store/TActionCreator';

export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const setIsMobile: TActionCreator<
  typeof SET_IS_MOBILE,
  { isMobile: boolean }
> = (payload) => ({
  type: SET_IS_MOBILE,
  payload,
});
