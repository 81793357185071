import { TActionCreator } from 'store/TActionCreator';
import { TOrder } from 'store/reducers/client/TOrder';

export const SUCCESS_SAVE_ORDER = 'SUCCESS_SAVE_ORDER';

export const successSaveOrder: TActionCreator<
  typeof SUCCESS_SAVE_ORDER,
  { order: TOrder }
> = (payload) => ({
  type: SUCCESS_SAVE_ORDER,
  payload,
});
