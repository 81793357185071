import React, { FC } from 'react';

const Collar: FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.219 9.11a.781.781 0 00-.782.78v1.143c-.016.12-.499.726-2.427 1.304-1.713.513-3.904.808-6.01.808-2.1 0-4.291-.304-6.013-.834-1.93-.594-2.41-1.208-2.425-1.327v-3.31c.503.281 1.122.54 1.85.77 1.883.597 4.285.939 6.588.939 2.31 0 4.71-.333 6.584-.912.957-.296 1.727-.64 2.288-1.024C21.621 6.937 22 6.352 22 5.711c0-.812-.563-1.96-3.244-2.814C16.938 2.32 14.538 2 12 2a.781.781 0 00-.568.244L8.698 5.135a.781.781 0 00.052 1.124L10.5 7.5c-1.6-.108-3.195-.598-4.5-1-1.833-.564-2.114-.66-2.176-.82.04-.149-.017-.326.676-.68.82-.418 2.088-.769 3.5-1 .426-.07.661-.598.591-1.023a.781.781 0 00-.897-.645c-1.57.257-2.907.652-3.87 1.143-1.366.697-1.754 1.484-1.815 2.072C2.004 5.584 2 10.984 2 10.984c0 .64.378 1.226 1.123 1.742.562.388 1.332.74 2.289 1.042 1.671.53 3.75.859 5.807.926v1.155a3.13 3.13 0 00-2.344 3.026A3.129 3.129 0 0012 22a3.129 3.129 0 003.125-3.125 3.13 3.13 0 00-2.344-3.026v-1.154c2.062-.066 4.14-.385 5.803-.9.957-.296 1.727-.64 2.288-1.023.749-.511 1.128-1.095 1.128-1.737V9.891a.781.781 0 00-.781-.782zM10.623 5.68L12.5 4c2.256.026 3.91-.006 5.5.5 1.57.5 2 .756 2 1-.016.12-.071.922-2 1.5-1.476.442-3.674.431-5.5.5l-1.877-1.82zm2.94 13.195c0 .862-.701 1.563-1.563 1.563a1.563 1.563 0 01-.781-2.915v.532a.781.781 0 101.562 0v-.532c.467.27.781.775.781 1.352z"
      fill="currentColor"
    />
  </svg>
);

export default Collar;
