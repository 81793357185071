import { TChatState } from 'store/reducers/chat/TChatState';
import { setCurrentDialogue } from 'store/actions/chat/setCurrentDialogue';

const setCurrentDialogueHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof setCurrentDialogue>,
): TChatState => {
  return {
    ...state,
    currentDialogueId: payload.dialogueId,
  };
};

export default setCurrentDialogueHandler;
