import { TActionCreator } from 'store/TActionCreator';
import { TRequest } from 'store/reducers/client/TRequest';

export const SUCCESS_LOAD_REQUESTS = 'SUCCESS_LOAD_REQUESTS';

export const successLoadRequests: TActionCreator<
  typeof SUCCESS_LOAD_REQUESTS,
  { requests: TRequest[] }
> = (payload) => ({
  type: SUCCESS_LOAD_REQUESTS,
  payload,
});
