import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import sendCodeFetch from 'api/user/sendCode/sendCodeFetch';
import fetchSaga from 'store/sagas/fetchSaga';

function* sendCodeSaga(): SagaIterator<void> {
  try {
    const phone: string = yield select((state) => state.user.checkPhone);
    yield call(fetchSaga, {
      fetcher: sendCodeFetch,
      payload: { phone },
    });
    // eslint-disable-next-line no-empty
  } catch {}
}

export default sendCodeSaga;
