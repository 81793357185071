import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_SEND_REQUEST = 'SUCCESS_SEND_REQUEST';

export const successSendRequest: TActionCreator<
  typeof SUCCESS_SEND_REQUEST,
  { userId: number; orderId: number }
> = (payload) => ({
  type: SUCCESS_SEND_REQUEST,
  payload,
});
