import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_CHECK_PHONE = 'SUCCESS_CHECK_PHONE';

export const successCheckPhone: TActionCreator<
  typeof SUCCESS_CHECK_PHONE,
  { exists: boolean }
> = (payload) => ({
  type: SUCCESS_CHECK_PHONE,
  payload,
});
