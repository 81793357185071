import { ERequestStatus, ERequestType } from 'store/reducers/client/TRequest';
import { rawOrderTypeToType } from 'api/client/loadOrders/loadOrdersCleaner';
import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const rawRequestTypeToType = (rawRequestType: number) => {
  switch (rawRequestType) {
    case 1:
      return ERequestType.FromSitter;
    case 2:
    default:
      return ERequestType.FromMe;
  }
};

export const rawRequestStatusToStatus = (rawOrderStatus: number) => {
  switch (rawOrderStatus) {
    case 2:
      return ERequestStatus.Approved;
    case 3:
      return ERequestStatus.Cancelled;
    case 1:
    default:
      return ERequestStatus.Waiting;
  }
};

const loadRequestsCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  return rawPayloadData.data.map((rawRequest) => ({
    id: rawRequest.id,
    addressPoint: [Number(rawRequest.latitude), Number(rawRequest.longitude)],
    message: rawRequest.message || '',
    offer: rawRequest.offer,
    repeatOrderCount: rawRequest.repeat_order_count,
    reviewsCount: rawRequest.reviews_count,
    workerName: rawRequest.short_name,
    workerRating: rawRequest.user_rating,
    workerAddress: rawRequest.worker_address,
    workerId: rawRequest.worker_id,
    type: rawRequestTypeToType(rawRequest.type),
    status: rawRequestStatusToStatus(rawRequest.status),
    workerAvatar: rawRequest.avatar.path,
    rate: rawRequest.order.data.rate,
    orderType: rawOrderTypeToType(rawRequest.order.data.type),
  }));
};

export default loadRequestsCleaner;
