import { TActionCreator } from 'store/TActionCreator';
import { TMessage } from 'store/reducers/chat/TMessage';

export const SUCCESS_LOAD_MORE_MESSAGES = 'SUCCESS_LOAD_MORE_MESSAGES';

export const successLoadMoreMessages: TActionCreator<
  typeof SUCCESS_LOAD_MORE_MESSAGES,
  { dialogueId: number; messages: TMessage[]; totalMessages: number }
> = (payload) => ({
  type: SUCCESS_LOAD_MORE_MESSAGES,
  payload,
});
