import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';

export const successLogin: TActionCreator<
  typeof SUCCESS_LOGIN,
  { accessToken: string }
> = (payload) => ({
  type: SUCCESS_LOGIN,
  payload,
});
