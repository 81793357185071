import { TSitterState } from 'store/reducers/sitter/TSitterState';

const successSitterProfileSaveHandler = (state: TSitterState): TSitterState => {
  return {
    ...state,
    profileSaving: false,
  };
};

export default successSitterProfileSaveHandler;
