import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setSitterProfile } from 'store/actions/sitter/setSitterProfile';

const setSitterProfileHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setSitterProfile>,
): TSitterState => {
  return {
    ...state,
    profile: payload.profile,
  };
};

export default setSitterProfileHandler;
