import React, { FC } from 'react';

const Receipt: FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 12H7a1 1 0 000 2h6a1 1 0 100-2zm-6-2h6a1 1 0 100-2H7a1 1 0 100 2zm14 2h-3V3a1 1 0 00-1.5-.87l-3 1.72-3-1.72a1 1 0 00-1 0l-3 1.72-3-1.72A1 1 0 002 3v16a3 3 0 003 3h14a3 3 0 003-3v-6a1 1 0 00-1-1zM5 20a1 1 0 01-1-1V4.73l2 1.14a1.08 1.08 0 001 0l3-1.72 3 1.72a1.08 1.08 0 001 0l2-1.14V19a3 3 0 00.18 1H5zm15-1a1 1 0 11-2 0v-5h2v5zm-6.3-6.71a1 1 0 00-1.62.33A1 1 0 0013 14a1 1 0 001-1 1.002 1.002 0 00-.08-.38.91.91 0 00-.22-.33z"
      fill="currentColor"
    />
  </svg>
);

export default Receipt;
