import { TAction } from 'store/TAction';

import { SET_CURRENT_DIALOGUE } from 'store/actions/chat/setCurrentDialogue';
import { LOAD_DIALOGUES } from 'store/actions/chat/loadDialogues';
import { SUCCESS_LOAD_DIALOGUES } from 'store/actions/chat/successLoadDialogues';
import { SEND_MESSAGE } from 'store/actions/chat/sendMessage';
import { SUCCESS_SEND_MESSAGE } from 'store/actions/chat/successSendMessage';
import { NEW_MESSAGE } from 'store/actions/chat/newMessage';
import { SET_DIALOGUE_TYPING } from 'store/actions/chat/setDialogueTyping';
import { FIRE_LOAD_MORE_MESSAGES } from 'store/actions/chat/fireLoadMoreMessages';
import { SUCCESS_LOAD_MORE_MESSAGES } from 'store/actions/chat/successLoadMoreMessages';
import { FAIL_SEND_MESSAGE } from 'store/actions/chat/failSendMessage';
import { SET_MORE_DIALOGUES_LOADING } from 'store/actions/chat/setMoreDialoguesLoading';
import { FIRE_MESSAGES_AS_READ } from 'store/actions/chat/fireMessagesAsRead';

import { TChatState } from './TChatState';
import setCurrentDialogueHandler from './handlers/setCurrentDialogueHandler';
import loadDialoguesHandler from './handlers/loadDialoguesHandler';
import successLoadDialoguesHandler from './handlers/successLoadDialoguesHandler';
import sendMessageHandler from './handlers/sendMessageHandler';
import successSendMessageHandler from './handlers/successSendMessageHandler';
import newMessageHandler from './handlers/newMessageHandler';
import setDialogueTypingHandler from './handlers/setDialogueTypingHandler';
import fireLoadMoreMessagesHandler from './handlers/fireLoadMoreMessagesHandler';
import successLoadMoreMessagesHandler from './handlers/successLoadMoreMessagesHandler';
import failSendMessageHandler from './handlers/failSendMessageHandler';
import setMoreDialoguesLoadingHandler from './handlers/setMoreDialoguesLoadingHandler';
import fireMessagesAsReadHandler from './handlers/fireMessagesAsReadHandler';

export const chatInitialState: TChatState = {
  currentDialogueId: null,
  dialogues: [],
  dialogueTyping: false,
  dialogueMoreMessagesLoading: false,
  allDialoguesLoaded: false,
  moreDialoguesLoading: false,
};

const chatReducer = (state = chatInitialState, action: TAction): TChatState => {
  switch (action.type) {
    case SET_CURRENT_DIALOGUE:
      return setCurrentDialogueHandler(state, action);
    case LOAD_DIALOGUES:
      return loadDialoguesHandler(state, action);
    case SUCCESS_LOAD_DIALOGUES:
      return successLoadDialoguesHandler(state, action);
    case SEND_MESSAGE:
      return sendMessageHandler(state, action);
    case SUCCESS_SEND_MESSAGE:
      return successSendMessageHandler(state, action);
    case NEW_MESSAGE:
      return newMessageHandler(state, action);
    case SET_DIALOGUE_TYPING:
      return setDialogueTypingHandler(state, action);
    case FIRE_LOAD_MORE_MESSAGES:
      return fireLoadMoreMessagesHandler(state);
    case SUCCESS_LOAD_MORE_MESSAGES:
      return successLoadMoreMessagesHandler(state, action);
    case FAIL_SEND_MESSAGE:
      return failSendMessageHandler(state, action);
    case SET_MORE_DIALOGUES_LOADING:
      return setMoreDialoguesLoadingHandler(state);
    case FIRE_MESSAGES_AS_READ:
      return fireMessagesAsReadHandler(state, action);
    default:
      return state;
  }
};

export default chatReducer;
