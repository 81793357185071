import React, { FC } from 'react';

const Cat: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9 18.225c-.552 0-1.08.485-1.055 1.054.025.572.463 1.055 1.054 1.055.552 0 1.08-.485 1.055-1.055-.026-.571-.463-1.054-1.055-1.054zM24.102 18.225c-.551 0-1.08.485-1.054 1.054.025.572.463 1.055 1.055 1.055.551 0 1.08-.485 1.054-1.055-.026-.571-.463-1.054-1.055-1.054z"
      fill="currentColor"
    />
    <path
      d="M34.945 21.669h-3.244c.511-1.666.795-3.286.795-4.742a12.92 12.92 0 00-.575-3.81c.37-1.68.57-3.657.57-5.685 0-2.438-.289-4.804-.814-6.664a1.054 1.054 0 00-1.14-.76c-3.272.027-6.226 1.422-8.33 3.643A14.3 14.3 0 0018 3.007c-1.419 0-2.839.222-4.207.644C11.69 1.43 8.735.035 5.463.007a1.055 1.055 0 00-1.14.761c-.525 1.86-.814 4.226-.814 6.664 0 2.028.2 4.006.57 5.684a12.926 12.926 0 00-.575 3.811c0 1.456.284 3.076.795 4.742H1.055a1.055 1.055 0 000 2.11h4.004c.327.793.7 1.585 1.112 2.364H3.106a1.055 1.055 0 000 2.109h4.302a24.777 24.777 0 003.064 3.864c.984 1.004 2.263 1.524 3.582 1.509A4.494 4.494 0 0018 36a4.494 4.494 0 003.946-2.376l.056.002c1.3 0 2.556-.52 3.526-1.51a24.783 24.783 0 003.064-3.864h4.302a1.055 1.055 0 100-2.11h-3.065c.413-.778.785-1.57 1.112-2.364h4.004a1.055 1.055 0 100-2.11zM29.85 2.149c.345 1.538.532 3.38.532 5.283 0 .765-.03 1.518-.09 2.247a14.642 14.642 0 00-2.212-2.618A15.097 15.097 0 0024.36 4.5a9.468 9.468 0 015.491-2.352zM5.619 7.432c0-1.903.187-3.745.531-5.283A9.468 9.468 0 0111.641 4.5 15.099 15.099 0 007.92 7.06a14.642 14.642 0 00-2.212 2.618 28.14 28.14 0 01-.09-2.246zM18 33.891c-.688 0-1.32-.303-1.76-.794A5.018 5.018 0 0018 31.679a5.018 5.018 0 001.76 1.418c-.44.49-1.072.794-1.76.794zm1.396-8.552L18 26.268l-1.396-.929a1.423 1.423 0 01-.633-1.189c0-.783.626-1.42 1.396-1.42h1.266c.77 0 1.396.637 1.396 1.42 0 .481-.236.926-.633 1.19zm4.626 5.3c-.847.865-2.02 1.108-3.136.652-.886-.361-1.831-1.272-1.831-2.744V28.1l1.51-1.003a3.527 3.527 0 001.573-2.946c0-1.946-1.572-3.53-3.505-3.53h-1.266c-1.933 0-3.505 1.584-3.505 3.53 0 1.19.588 2.29 1.574 2.946l1.51 1.003v.448c0 1.472-.946 2.383-1.832 2.744-1.117.456-2.29.213-3.136-.651-3.628-3.702-6.364-9.597-6.364-13.713 0-3.101 1.331-6.06 3.75-8.328C11.72 6.385 14.868 5.116 18 5.116c3.13 0 6.279 1.27 8.637 3.483 2.418 2.269 3.75 5.226 3.75 8.328 0 4.116-2.737 10.011-6.365 13.713z"
      fill="currentColor"
    />
  </svg>
);

export default Cat;
