import { TActionCreator } from 'store/TActionCreator';
import { TSitterRequest } from 'store/reducers/sitter/TSitterRequest';

export const SUCCESS_LOAD_SITTER_REQUESTS = 'SUCCESS_LOAD_SITTER_REQUESTS';

export const successLoadSitterRequests: TActionCreator<
  typeof SUCCESS_LOAD_SITTER_REQUESTS,
  { requests: TSitterRequest[] }
> = (payload) => ({
  type: SUCCESS_LOAD_SITTER_REQUESTS,
  payload,
});
