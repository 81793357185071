import { TUserState } from 'store/reducers/user/TUserState';
import { failLogin } from 'store/actions/user/failLogin';

const successLoginHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof failLogin>,
): TUserState => {
  return {
    ...state,
    loginError: payload.code,
  };
};

export default successLoginHandler;
