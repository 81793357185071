import { TActionCreator } from 'store/TActionCreator';
import { TPet } from 'store/reducers/client/TPet';

export const SUCCESS_SAVE_PET = 'SUCCESS_SAVE_PET';

export const successSavePet: TActionCreator<
  typeof SUCCESS_SAVE_PET,
  { pet: TPet }
> = (payload) => ({
  type: SUCCESS_SAVE_PET,
  payload,
});
