import { TActionCreator } from 'store/TActionCreator';

export const FIRE_BECOME_SITTER_SERVICES_SAVE = 'FIRE_BECOME_SITTER_SERVICES_SAVE';

export const fireBecomeSitterServicesSave: TActionCreator<
  typeof FIRE_BECOME_SITTER_SERVICES_SAVE,
  { sitter: boolean; walker: boolean }
> = (payload) => ({
  type: FIRE_BECOME_SITTER_SERVICES_SAVE,
  payload,
});
