import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import checkPhoneCleaner from './checkPhoneCleaner';
import { TCheckPhoneParameters } from './types/TCheckPhoneParameters';
import { TCheckPhonePayload } from './types/TCheckPhonePayload';

const checkPhoneFetch = async ({
  email,
}: TCheckPhoneParameters): Promise<TCheckPhonePayload | never> => {
  const url = new URL(`${API_URL}/check/user`);
  const body = {
    email: email,
  };

  return apiFetch(checkPhoneCleaner, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default checkPhoneFetch;
