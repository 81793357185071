import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import getBecomeSitterStatusFetch from 'api/sitter/getBecomeSitterStatus/getBecomeSitterStatusFetch';
import { setBecomeSitterStep } from 'store/actions/sitter/setBecomeSitterStep';
import EBecomeSitterStep from 'store/reducers/sitter/EBecomeSitterStep';
import { setBecomeSitterServices } from 'store/actions/sitter/setBecomeSitterServices';
import EBecomeSitterServices from 'store/reducers/sitter/EBecomeSitterServices';
import { setBecomeSitterMessageFromAdmin } from 'store/actions/sitter/setBecomeSitterMessageFromAdmin';
import { setBecomeSitterIdentity } from 'store/actions/sitter/setBecomeSitterIdentity';
import { setBecomeSitterProfile } from 'store/actions/sitter/setBecomeSiiterProfile';

const becomeSitterStepByNumber: Record<number, EBecomeSitterStep> = {
  1: EBecomeSitterStep.Services,
  2: EBecomeSitterStep.ServicesOptions,
  3: EBecomeSitterStep.Identity,
  4: EBecomeSitterStep.Test,
  5: EBecomeSitterStep.Profile,
};

const becomeSitterStepByModerationStatus: Record<number, EBecomeSitterStep> = {
  1: EBecomeSitterStep.Moderation,
  2: EBecomeSitterStep.ModerationSuccess,
  3: EBecomeSitterStep.ModerationFailed,
  4: EBecomeSitterStep.ModerationAgain,
};

function* getBecomeSitterStatusSaga(): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: getBecomeSitterStatusFetch,
    });

    const { moderationStatus, messageFromAdmin, currentStep } = response.data;
    if (messageFromAdmin) {
      yield put(setBecomeSitterMessageFromAdmin({ message: messageFromAdmin }));
    }

    if (
      currentStep &&
      becomeSitterStepByNumber[currentStep] === EBecomeSitterStep.Test
    ) {
      yield put(setBecomeSitterStep({ step: EBecomeSitterStep.Test }));
      return;
    }

    if (
      currentStep &&
      becomeSitterStepByNumber[currentStep] === EBecomeSitterStep.Profile
    ) {
      const step = becomeSitterStepByModerationStatus[moderationStatus];
      if (!step) {
        yield put(setBecomeSitterStep({ step: EBecomeSitterStep.Profile }));
        return;
      }
      if (step === EBecomeSitterStep.ModerationAgain) {
        yield put(setBecomeSitterProfile({ profile: response.data.profile }));
      }
      yield put(setBecomeSitterStep({ step }));
      return;
    }

    if (moderationStatus) {
      const step = becomeSitterStepByModerationStatus[moderationStatus];
      if (step === EBecomeSitterStep.ModerationAgain) {
        yield put(
          setBecomeSitterIdentity({ identity: response.data.identity }),
        );
      }
      yield put(setBecomeSitterStep({ step }));
      return;
    }

    yield put(
      setBecomeSitterStep({ step: becomeSitterStepByNumber[currentStep] }),
    );

    if (
      becomeSitterStepByNumber[currentStep] ===
      EBecomeSitterStep.ServicesOptions
    ) {
      const { sitter, walker } = response.data;
      const services: EBecomeSitterServices[] = [];
      if (sitter) {
        services.push(EBecomeSitterServices.Sitter);
      }
      if (walker) {
        services.push(EBecomeSitterServices.Walker);
      }
      yield put(setBecomeSitterServices({ services }));
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default getBecomeSitterStatusSaga;
