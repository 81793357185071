import { TUserState } from 'store/reducers/user/TUserState';

const forgotPasswordHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    forgotPassword: true,
    loginError: null,
    correctResetCode: null,
  };
};

export default forgotPasswordHandler;
