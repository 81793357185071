import React, { FC } from 'react';

const PetSmall: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.367 28.173a4.82 4.82 0 00-3.656-4.683 2.923 2.923 0 01-1.829-1.388 16.547 16.547 0 00-2.092-5.78l.217-.1a3.825 3.825 0 002.115-4.315l-.366-1.583h.63a.879.879 0 00.88-.879v-2.05A4.4 4.4 0 0022.87 3c-.65 0-1.229.304-1.605.776C20.193 3.261 19.16 3 18.184 3c-.976 0-2.01.261-3.083.776A2.048 2.048 0 0013.496 3a4.4 4.4 0 00-4.394 4.395v2.05c0 .486.393.88.878.88h.63l-.364 1.582a3.825 3.825 0 002.115 4.315l.216.1a16.546 16.546 0 00-2.092 5.78 2.922 2.922 0 01-1.828 1.387A4.82 4.82 0 005 28.174c0 2.3 1.613 4.265 3.855 4.724.167.066.349.103.54.103h17.578c.19 0 .372-.037.54-.103a4.815 4.815 0 003.854-4.724zM22.578 5.05c0-.162.132-.293.293-.293a2.64 2.64 0 012.637 2.637v1.171h-1.172a1.76 1.76 0 01-1.758-1.757V5.05zM10.86 7.395a2.64 2.64 0 012.637-2.637c.162 0 .293.131.293.293v1.758a1.76 1.76 0 01-1.758 1.757H10.86V7.395zm1.1 4.908l.461-2a3.52 3.52 0 003.127-3.494v-1.29c.952-.505 1.837-.761 2.637-.761s1.684.256 2.636.76v1.29a3.52 3.52 0 003.127 3.494l.462 2a2.06 2.06 0 01-1.139 2.324l-4.207 1.942v-1.76h.293a.879.879 0 000-1.759h-2.344a.879.879 0 000 1.758h.293v1.76l-4.208-1.941a2.06 2.06 0 01-1.139-2.323zm4.874 5.983a3.22 3.22 0 002.701 0l2.65-1.223a14.77 14.77 0 012.152 7.677v2.999a3.784 3.784 0 00-1.758.59v-4.997a.879.879 0 00-1.758 0v7.91h-5.273v-7.91a.879.879 0 00-1.758 0v4.996a3.784 3.784 0 00-1.758-.59V24.74c0-2.713.742-5.352 2.151-7.677l2.651 1.223zM8.032 30.661a3.06 3.06 0 01-1.274-2.488c0-1.411.956-2.636 2.325-2.978a4.672 4.672 0 001.19-.48V28.02a3.823 3.823 0 00-2.241 2.641zm3.706-1.177c1.032 0 1.888.766 2.03 1.758h-4.06a2.054 2.054 0 012.03-1.758zM22.6 31.242a2.054 2.054 0 012.03-1.758c1.031 0 1.887.766 2.03 1.758h-4.06zm5.736-.58a3.823 3.823 0 00-2.241-2.642v-3.28-.025c.37.211.769.374 1.19.48a3.065 3.065 0 012.325 2.978 3.06 3.06 0 01-1.274 2.488z"
      fill="currentColor"
    />
  </svg>
);

export default PetSmall;
