import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadReviewsParameters } from './types/TLoadReviewsParameters';
import loadReviewsCleaner from './loadReviewsCleaner';
import { TLoadReviewsPayload } from './types/TLoadReviewsPayload';

const loadReviewsFetch = async ({
  id,
  page,
}: TLoadReviewsParameters): Promise<TLoadReviewsPayload | never> => {
  const url = new URL(`${API_URL}/profile/reviews/${id}`);
  url.search = new URLSearchParams({
    page: page.toString(),
  }).toString();

  return apiFetch(loadReviewsCleaner, url.toString());
};

export default loadReviewsFetch;
