import { all } from 'redux-saga/effects';

import rootUserSaga from './sagas/user/rootUserSaga';
import rootMainSaga from './sagas/main/rootMainSaga';
import rootChatSaga from './sagas/chat/rootChatSaga';
import webSocketSaga from './sagas/webSocket';
import rootSitterSaga from './sagas/sitter/rootSitterSaga';
import rootClientSaga from './sagas/client/rootClientSaga';
import rootProfileSaga from './sagas/profile/rootProfileSaga';

export default function* rootSaga() {
  yield all([
    rootMainSaga(),
    rootUserSaga(),
    rootChatSaga(),
    webSocketSaga(),
    rootSitterSaga(),
    rootClientSaga(),
    rootProfileSaga(),
  ]);
}
