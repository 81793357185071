import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import selectIsMobileOnly from 'store/selectors/main/selectIsMobileOnly';
import Close from 'icons/Close';
import { Optional } from 'types/global';
import Button from 'components/Button/Button';

import styles from './Modal.module.css';

export type ModalProps = {
  size: 's' | 'm' | 'l' | 'fullscreen';
  className?: string;
  onClose?: () => void;
  opened?: boolean;
};

const Modal: FC<ModalProps> = ({
  children,
  size,
  className,
  onClose,
  opened,
}) => {
  const isMobileOnly = useSelector(selectIsMobileOnly);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  useEffect(() => {
    if (opened) {
      setModalContent(children);
    }
  }, [children, opened]);

  useEffect(() => {
    let modalVisibleTimeout: Optional<number> = null;
    let modalVisibleRequestFrame: Optional<number> = null;

    if (opened) {
      setModalOpened(true);
      modalVisibleRequestFrame = window.requestAnimationFrame(() => {
        setModalVisible(true);
      });
    } else {
      setModalVisible(false);
      modalVisibleTimeout = window.setTimeout(() => {
        setModalOpened(false);
        setModalContent(null);
      }, 200);
    }

    return () => {
      if (modalVisibleTimeout) {
        window.clearTimeout(modalVisibleTimeout);
      }
      if (modalVisibleRequestFrame) {
        window.cancelAnimationFrame(modalVisibleRequestFrame);
      }
    };
  }, [opened]);

  return createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={cn(styles.wrapper, {
        [styles.opened]: modalOpened,
        [styles.modalVisible]: modalVisible,
        [styles.mobileOnly]: isMobileOnly,
        [styles.wrapperFullscreen]: size === 'fullscreen',
      })}
      onClick={onClose}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={cn(styles.modal, className, styles[size])}
        onClick={(event) => event.stopPropagation()}
      >
        {onClose && (
          <Button
            className={styles.closeButton}
            view="ghost"
            mode="white"
            onClick={onClose}
          >
            <Close />
          </Button>
        )}
        <div className={styles.modalContent}>{modalContent}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
