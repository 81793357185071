export enum EOrderStatus {
  New = 1,
  Cancelled = 2,
  Approved = 3,
  Paid = 4,
  Finished = 5,
}

export enum EOrderType {
  Walker = 'WALKER',
  Sitter = 'SITTER',
}

export type TOrder = {
  id: number;
  price: number;
  status: EOrderStatus;
  type: EOrderType;
  startDate?: Date;
  endDate?: Date;
  dates?: Date[];
  isClient: boolean;
  phone: number;
};
