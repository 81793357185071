import { TActionCreator } from 'store/TActionCreator';

export const FIRE_ACCEPT_SITTER_REQUEST = 'FIRE_ACCEPT_SITTER_REQUEST';

export const fireAcceptSitterRequest: TActionCreator<
  typeof FIRE_ACCEPT_SITTER_REQUEST,
  { requestId: number }
> = (payload) => ({
  type: FIRE_ACCEPT_SITTER_REQUEST,
  payload,
});
