import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TSendMessagePayload } from 'api/chat/sendMessage/types/TSendMessagePayload';
import sendMessageCleaner from 'api/chat/sendMessage/sendMessageCleaner';

import { TSendMessageParameters } from './types/TSendMessageParameters';

const sendMessageFetch = async ({
  dialogueId,
  text,
  media,
}: TSendMessageParameters): Promise<TSendMessagePayload | never> => {
  const url = new URL(`${API_URL}/chat/message`);
  const body = new FormData();
  body.append('message', text);
  if (dialogueId) {
    body.append('group_id', dialogueId.toString());
  }
  media.forEach((item, index) => {
    body.append(`gallery[${index}]`, item.dataRaw!);
  });

  return apiFetch(sendMessageCleaner, url.toString(), {
    method: 'POST',
    body,
  });
};

export default sendMessageFetch;
