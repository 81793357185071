import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

import { TSendResetCodeParameters } from './types/TSendResetCodeParameters';

const sendResetCodeFetch = async ({
  phone,
}: TSendResetCodeParameters): Promise<TPayload<unknown> | never> => {
  const url = new URL(`${API_URL}/code/reset`);
  const body = {
    email: phone,
  };
  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default sendResetCodeFetch;
