import { TActionCreator } from 'store/TActionCreator';

export const FAIL_RESET_PASSWORD = 'FAIL_RESET_PASSWORD';

export enum EResetPasswordError {
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
}

export const failResetPassword: TActionCreator<
  typeof FAIL_RESET_PASSWORD,
  { code: EResetPasswordError }
> = (payload) => ({
  type: FAIL_RESET_PASSWORD,
  payload,
});
