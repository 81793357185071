import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';
import { TPayload } from 'api/types/TPayload';

const becomeSitterTestCompleteFetch = async (): Promise<
  TPayload<unknown> | never
> => {
  const url = new URL(`${API_URL}/worker/add/tests`);
  const body = {
    tests: [
      {
        question: 'test',
        answer: 1,
      },
    ],
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default becomeSitterTestCompleteFetch;
