import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoadDialoguesPayload } from './types/TLoadDialoguesPayload';
import loadDialoguesCleaner from './loadDialoguesCleaner';
import { TLoadDialoguesParameters } from './types/TLoadDialoguesParameters';

const loadDialoguesFetch = async ({
  page,
}: TLoadDialoguesParameters): Promise<TLoadDialoguesPayload | never> => {
  const url = new URL(`${API_URL}/chat/dialogues`);
  url.search = new URLSearchParams({
    per_page: '20',
    page: page.toString(),
  }).toString();

  return apiFetch(loadDialoguesCleaner, url.toString());
};

export default loadDialoguesFetch;
