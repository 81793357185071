import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setBecomeSitterIdentity } from 'store/actions/sitter/setBecomeSitterIdentity';

const setBecomeSitterIdentityHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setBecomeSitterIdentity>,
): TSitterState => {
  return {
    ...state,
    identity: payload.identity,
  };
};

export default setBecomeSitterIdentityHandler;
