import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setBecomeSitterMessageFromAdmin } from 'store/actions/sitter/setBecomeSitterMessageFromAdmin';

const setBecomeSitterMessageFromAdminHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setBecomeSitterMessageFromAdmin>,
): TSitterState => {
  return {
    ...state,
    messageFromAdmin: payload.message,
  };
};

export default setBecomeSitterMessageFromAdminHandler;
