import { TUserState } from 'store/reducers/user/TUserState';
import { userInitialState } from 'store/reducers/user/userReducer';

const successLogoutHandler = (state: TUserState): TUserState => {
  return {
    ...userInitialState,
    loggedIn: false,
  };
};

export default successLogoutHandler;
