import { TClientState } from 'store/reducers/client/TClientState';

const fireSaveOrderHandler = (state: TClientState): TClientState => {
  return {
    ...state,
    orderSaving: true,
  };
};

export default fireSaveOrderHandler;
