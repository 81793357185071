import React, { FC } from 'react';

const Fish: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.48 17.984a.984.984 0 100-1.968.984.984 0 000 1.968z"
      fill="currentColor"
    />
    <path
      d="M35.967 18.266c-1.054-3.824-5.577-7.815-11.1-8.523-.447-2.485-2.653-4.204-5.573-4.204H13.36c-.582 0-1.054.472-1.054 1.055 0 1.902.496 3.703 1.445 5.275-.989.54-2.086 1.259-3.06 2.02a5.437 5.437 0 00-5.418-5.066h-4.22C.473 8.823 0 9.295 0 9.878v2.734c0 2.469 1.3 4.64 3.25 5.864A6.917 6.917 0 000 24.338v2.734c0 .582.472 1.055 1.055 1.055h4.219a5.439 5.439 0 005.4-4.86c.825.659 1.877 1.373 2.824 1.902-1.156 1.435-1.156 3.11-1.156 4.206 0 .582.472 1.055 1.054 1.055 3.46-.008 6.994.565 8.052-2.88 8.392.651 15.085-6.614 14.519-9.284zM14.479 7.648c3.65.097 7.208-.642 8.15 1.918-2.266-.056-4.707.425-6.968 1.38a7.758 7.758 0 01-1.182-3.298zm-12.37 4.964v-1.68h3.165a3.325 3.325 0 013.321 3.322v1.46c-.74.718-1.28 1.329-1.6 1.712-.027-.002-.052-.004-.079-.004a4.814 4.814 0 01-4.807-4.81zm3.165 13.405H2.109v-1.68a4.812 4.812 0 014.807-4.806l.039-.002c.32.417.87 1.087 1.64 1.868v1.299a3.325 3.325 0 01-3.321 3.321zm12.151 2.303h-2.938c.076-.858.308-1.575.99-2.18a18.37 18.37 0 003.814 1.147c-.326.63-1.013 1.033-1.866 1.033zm3.254-2.951c-4.587-.459-9.032-3.386-11.837-6.855 3.169-3.56 8.47-7.427 14.995-6.77-1.644 2.02-2.532 4.389-2.532 6.835 0 2.443.888 4.806 2.532 6.817-.93.108-2.106.095-3.158-.027zm10.117-2.904c-.923.73-2.5 1.782-4.57 2.428-1.817-1.804-2.812-4.027-2.812-6.314 0-2.314 1.015-4.566 2.868-6.392 4.345 1.135 7.021 4.522 7.611 6.476-.047.454-.967 2.115-3.097 3.802z"
      fill="currentColor"
    />
    <path
      d="M19.453 15.514a1.055 1.055 0 10-2.007-.649c-.827 2.557-.688 5.151.402 7.502a1.055 1.055 0 101.914-.888c-.863-1.86-.97-3.922-.309-5.965z"
      fill="currentColor"
    />
  </svg>
);

export default Fish;
