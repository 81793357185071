import { TClientState } from 'store/reducers/client/TClientState';
import { fireDeletePet } from 'store/actions/client/fireDeletePet';

const fireDeletePetHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof fireDeletePet>,
): TClientState => {
  return {
    ...state,
    petDeletingId: payload.id,
  };
};

export default fireDeletePetHandler;
