import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import loadRequestsFetch from 'api/sitter/loadRequests/loadRequestsFetch';
import EResponseType from 'api/enums/EResponseType';
import { successLoadSitterRequests } from 'store/actions/sitter/successLoadSitterRequests';

function* loadSitterRequestsSaga(): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: loadRequestsFetch,
    });

    if (response.type === EResponseType.OK) {
      yield put(successLoadSitterRequests({ requests: response.data }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default loadSitterRequestsSaga;
