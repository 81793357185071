import { TActionCreator } from 'store/TActionCreator';

export const INITIAL = 'INITIAL';

export const initial: TActionCreator<
  typeof INITIAL,
  {
    accessToken?: string;
    redirectUrl?: string;
    historyPush: (path: string) => void;
  }
> = (payload) => ({
  type: INITIAL,
  payload,
});
