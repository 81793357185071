import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TDeletePetParameters } from './types/TDeletePetParameters';

const deletePetFetch = async ({
  id,
}: TDeletePetParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/admin/pets/delete/${id}`);

  return apiFetch(() => null, url.toString(), {
    method: 'DELETE',
  });
};

export default deletePetFetch;
