import { TSitterState } from 'store/reducers/sitter/TSitterState';

const successSitterSettingsSaveHandler = (
  state: TSitterState,
): TSitterState => {
  return {
    ...state,
    settingsSaving: false,
  };
};

export default successSitterSettingsSaveHandler;
