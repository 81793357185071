import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TCheckCodeParameters } from './types/TCheckCodeParameters';

const checkCodeFetch = async ({
  code,
  phone,
}: TCheckCodeParameters): Promise<unknown | never> => {
  const url = new URL(`${API_URL}/code/check`);
  const body = {
    email: phone,
    code,
  };

  return apiFetch(() => null, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default checkCodeFetch;
