import { TClientState } from 'store/reducers/client/TClientState';
import { successSendRequest } from 'store/actions/client/successSendRequest';

const successSendRequestHandler = (
  state: TClientState,
  { payload }: ReturnType<typeof successSendRequest>,
): TClientState => {
  if (!state.orders) {
    return state;
  }
  const orders = [...state.orders];
  const orderIndex = state.orders.findIndex(
    (order) => order.id === payload.orderId,
  );
  console.log(orderIndex);
  if (orderIndex === -1) {
    return state;
  }
  const order = orders[orderIndex];
  orders[orderIndex] = {
    ...order,
    requestsUserIds: [...order.requestsUserIds, payload.userId],
  };

  return {
    ...state,
    requestSendingOrderId: null,
    orders,
  };
};

export default successSendRequestHandler;
