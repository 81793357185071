import { TUserState } from 'store/reducers/user/TUserState';
import { fireCheckPhone } from 'store/actions/user/fireCheckPhone';

const checkPhoneHandler = (
  state: TUserState,
  { payload }: ReturnType<typeof fireCheckPhone>,
): TUserState => {
  return {
    ...state,
    checkPhone: payload.email,
  };
};

export default checkPhoneHandler;
