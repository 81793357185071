import { TActionCreator } from 'store/TActionCreator';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const setLanguage: TActionCreator<
    typeof SET_LANGUAGE,
    { language: string }
> = (payload) => ({
    type: SET_LANGUAGE,
    payload,
});
