import { cleanPet } from 'api/client/loadPets/loadPetsCleaner';
import {
  cleanOrderInfo,
  rawOrderStatusToStatus,
  rawOrderTypeToType,
} from 'api/client/loadOrders/loadOrdersCleaner';
import { TRawOkayPayloadData } from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const loadRequestsCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  const { data } = rawPayloadData;

  return data.map((rawRequest) => {
    const rawOrder = rawRequest.order.data;

    return {
      id: rawRequest.id,
      clientName: rawRequest.short_name,
      type: rawOrderTypeToType(rawOrder.type),
      status: rawOrderStatusToStatus(rawOrder.status),
      rate: rawOrder.rate,
      pets: rawOrder.pets.data.map(cleanPet),
      info: cleanOrderInfo(rawOrder.orderable.data, rawOrder.type),
    };
  });
};

export default loadRequestsCleaner;
