import { TSitterState } from 'store/reducers/sitter/TSitterState';

const fireSitterSettingsSaveHandler = (state: TSitterState): TSitterState => {
  return {
    ...state,
    settingsSaving: true,
  };
};

export default fireSitterSettingsSaveHandler;
