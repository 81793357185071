import { TActionCreator } from 'store/TActionCreator';

export const SET_SELECTED_PET = 'SET_SELECTED_PET';

export const setSelectedPet: TActionCreator<
  typeof SET_SELECTED_PET,
  { id: number | 'new' | null }
> = (payload) => ({
  type: SET_SELECTED_PET,
  payload,
});
