import { TActionCreator } from 'store/TActionCreator';
import { TProfile } from 'store/reducers/profile/TProfile';

export const SUCCESS_LOAD_PROFILE = 'SUCCESS_LOAD_PROFILE';

export const successLoadProfile: TActionCreator<
  typeof SUCCESS_LOAD_PROFILE,
  { profile: TProfile }
> = (payload) => ({
  type: SUCCESS_LOAD_PROFILE,
  payload,
});
