import { TActionCreator } from 'store/TActionCreator';
import { TBecomeSitterProfile } from 'store/actions/sitter/fireBecomeSitterProfileSave';

export const SET_BECOME_SITTER_PROFILE = 'SET_BECOME_SITTER_PROFILE';

export const setBecomeSitterProfile: TActionCreator<
  typeof SET_BECOME_SITTER_PROFILE,
  { profile: TBecomeSitterProfile }
> = (payload) => ({
  type: SET_BECOME_SITTER_PROFILE,
  payload,
});
