import { TActionCreator } from 'store/TActionCreator';

export const FIRE_BECOME_SITTER_IDENTITY_SAVE =
  'FIRE_BECOME_SITTER_IDENTITY_SAVE';

export type TFireBecomeSitterIdentitySavePayload = {
  name: string;
  secondName: string;
  gender: string;
  birthDate: string;
  placeOfBirth: string;
  registrationAddress: string;
  passportSeries: string;
  passportNumber: string;
  passportIssueDate: string;
  passportIssuerUnit: string;
  passportIssuer: string;
  photos: File[];
  isUpdate: boolean;
};

export const fireBecomeSitterIdentitySave: TActionCreator<
  typeof FIRE_BECOME_SITTER_IDENTITY_SAVE,
  TFireBecomeSitterIdentitySavePayload
> = (payload) => ({
  type: FIRE_BECOME_SITTER_IDENTITY_SAVE,
  payload,
});
