import { TProfileState } from 'store/reducers/profile/TProfileState';

const fireSaveProfileHandler = (state: TProfileState): TProfileState => {
  return {
    ...state,
    profileSaving: true,
  };
};

export default fireSaveProfileHandler;
