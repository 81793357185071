import { TActionCreator } from 'store/TActionCreator';

export const SUCCESS_REGISTER = 'SUCCESS_REGISTER';

export const successRegister: TActionCreator<
  typeof SUCCESS_REGISTER,
  { accessToken: string }
> = (payload) => ({
  type: SUCCESS_REGISTER,
  payload,
});
