import {
  TRawIdentity,
  TRawOkayPayloadData,
  TRawProfile,
  TRawSitterOptions,
} from './types/TRawOkayPayloadData';
import { TOkayPayloadData } from './types/TOkayPayloadData';

const getBecomeSitterStatusCleaner = (
  rawPayloadData: TRawOkayPayloadData,
): TOkayPayloadData => {
  if (!rawPayloadData.current_stage && rawPayloadData.status_code === 2) {
    return {
      currentStep: 5,
      sitter: null,
      walker: null,
      identity: null,
      moderationStatus: 2,
      messageFromAdmin: null,
      profile: null,
    };
  }
  if (rawPayloadData.current_stage === 5) {
    const data = rawPayloadData.data as TRawProfile;
    return {
      currentStep: rawPayloadData.current_stage || null,
      sitter: null,
      walker: null,
      identity: null,
      profile: data
        ? {
            title: data.title,
            description: data.description,
            address: data.address,
            ordersCount: 1,
            constantCare: Boolean(data.constant_supervision),
            allowBed: Boolean(data.pets_on_the_bed),
            houseType: data.type_of_housing === 1 ? 'house' : 'flat',
            haveKids: Boolean(data.children_under_twelve_yo),
            haveCar: Boolean(data.has_car),
            experience: data.care_experience,
            medicine: Boolean(data.give_medication),
            injection: Boolean(data.give_injection),
            addressComment: '',
            instagram: '',
            vk: '',
            photos: [],
            uploadedPhotos: [],
            // uploadedPhotos: data.photos.data.map((rawPhoto) => ({
            //   id: rawPhoto.id,
            //   url: rawPhoto.path,
            // })),
          }
        : null,
      moderationStatus: Number(rawPayloadData.status_code),
      messageFromAdmin: rawPayloadData.message_from_admin || null,
    };
  }
  if (rawPayloadData.status_code) {
    const data = rawPayloadData.data as TRawIdentity;
    return {
      currentStep: rawPayloadData.current_stage || null,
      sitter: null,
      walker: null,
      profile: null,
      identity: data
        ? {
            name: data.first_name.toString(),
            secondName: data.last_name.toString(),
            gender: data.gender.toString(),
            birthDate: data.birth.toString(),
            placeOfBirth: data.city.toString(),
            registrationAddress: data.registration_address.toString(),
            passportSeries: data.passport_series.toString(),
            passportNumber: data.passport_number.toString(),
            passportIssuer: data.passport_issued_by.toString(),
            passportIssuerUnit: data.department_code.toString(),
            passportIssueDate: data.passport_created_at.toString(),
            photos: data.passportPhotos.data.map((item) => item.path),
          }
        : null,
      moderationStatus: Number(rawPayloadData.status_code),
      messageFromAdmin: rawPayloadData.message_from_admin || null,
    };
  }
  const data = rawPayloadData.data as TRawSitterOptions;
  return {
    currentStep: rawPayloadData.current_stage || null,
    sitter: data?.sitter ? Boolean(Number(data.sitter)) : null,
    walker: data?.walker ? Boolean(Number(data.walker)) : null,
    moderationStatus: null,
    messageFromAdmin: rawPayloadData.message_from_admin || null,
    identity: null,
    profile: null,
  };
};

export default getBecomeSitterStatusCleaner;
