import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import deletePhotoFetch from 'api/main/deletePhoto/deletePhotoFetch';
import { fireDeletePetPhoto } from 'store/actions/client/fireDeletePetPhoto';

function* deletePetPhoto({
  payload,
}: ReturnType<typeof fireDeletePetPhoto>): SagaIterator<void> {
  try {
    yield call(fetchSaga, {
      fetcher: deletePhotoFetch,
      payload: { id: payload.id },
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default deletePetPhoto;
