import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fetchSaga from 'store/sagas/fetchSaga';
import EResponseType from 'api/enums/EResponseType';
import { fireSearchSitter } from 'store/actions/client/fireSearchSitter';
import loadSitterFetch from 'api/client/searchSitter/searchSitterFetch';
import { successSearchSitter } from 'store/actions/client/successSearchSitter';

function* searchSitterSaga({
  payload,
}: ReturnType<typeof fireSearchSitter>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: loadSitterFetch,
      payload: {
        sitterInfo: payload.sitterInfo,
        page: payload.page,
      },
    });
    const { list, currentPage, totalPages } = response.data;
    if (response.type === EResponseType.OK) {
      yield put(successSearchSitter({ list, currentPage, totalPages }));
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default searchSitterSaga;
