import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { successLoadSitterRequests } from 'store/actions/sitter/successLoadSitterRequests';

const successLoadSitterRequestsHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof successLoadSitterRequests>,
): TSitterState => {
  return {
    ...state,
    requests: payload.requests,
  };
};

export default successLoadSitterRequestsHandler;
