import { TMainState } from 'store/reducers/main/TMainState';
import { setIsMobile } from 'store/actions/main/setIsMobile';

const setIsMobileHandler = (
  state: TMainState,
  { payload }: ReturnType<typeof setIsMobile>,
): TMainState => {
  return {
    ...state,
    isMobile: payload.isMobile,
    isMobileMenuOpened: false,
  };
};

export default setIsMobileHandler;
