import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import sendResetCodeFetch from 'api/user/sendResetCode/sendResetCodeFetch';
import fetchSaga from 'store/sagas/fetchSaga';

function* sendResetCodeSaga(): SagaIterator<void> {
  try {
    const phone: string = yield select((state) => state.user.checkPhone);
    yield call(fetchSaga, {
      fetcher: sendResetCodeFetch,
      payload: { phone },
    });
    // eslint-disable-next-line no-empty
  } catch {}
}

export default sendResetCodeSaga;
