import apiFetch from 'api/apiFetch';
import { API_URL } from 'api/api.const';

import { TLoginParameters } from './types/TLoginParameters';
import { TLoginPayload } from './types/TLoginPayload';
import loginCleaner from './loginCleaner';

const loginFetch = async ({
  user,
  password,
}: TLoginParameters): Promise<TLoginPayload | never> => {
  const url = new URL(`${API_URL}/login`);
  const body = {
    email: user,
    password,
  };

  return apiFetch(loginCleaner, url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export default loginFetch;
