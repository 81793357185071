import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import EResponseType from 'api/enums/EResponseType';
import { fireCheckResetCode } from 'store/actions/user/fireCheckResetCode';
import checkResetCodeFetch from 'api/user/checkResetCode/checkResetCodeFetch';
import { successCheckResetCode } from 'store/actions/user/successCheckResetCode';
import fetchSaga from 'store/sagas/fetchSaga';

function* checkResetCodeSaga({
  payload,
}: ReturnType<typeof fireCheckResetCode>): SagaIterator<void> {
  try {
    const phone: string = yield select((state) => state.user.checkPhone);
    const response = yield call(fetchSaga, {
      fetcher: checkResetCodeFetch,
      payload: { phone, code: payload.code },
    });
    console.log(response)
    // Используем только success для определения результата
    if (response.success) {
      yield put(successCheckResetCode({ correctCode: payload.code }));
    } else {
      yield put(successCheckResetCode({ correctCode: false }));
    }
  } catch (e) {
    // Логирование ошибок
    console.error(e);
    yield put(successCheckResetCode({ correctCode: false }));
  }
}


export default checkResetCodeSaga;
