import { TSitterState } from 'store/reducers/sitter/TSitterState';
import { setSitterSettings } from 'store/actions/sitter/setSitterSettings';

const setSitterSettingsHandler = (
  state: TSitterState,
  { payload }: ReturnType<typeof setSitterSettings>,
): TSitterState => {
  return {
    ...state,
    settings: payload.settings,
  };
};

export default setSitterSettingsHandler;
