import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import EResponseType from 'api/enums/EResponseType';
import fetchSaga from 'store/sagas/fetchSaga';
import { fireChangePassword } from 'store/actions/user/fireChangePassword';
import changePasswordFetch from 'api/user/changePassword/changePasswordFetch';
import {
  changePasswordError,
  EChangePasswordError,
} from 'store/actions/user/changePasswordError';
import { toast } from 'react-toastify';
import { getLang } from 'libs/langs/langs';
import { changePasswordSuccess } from 'store/actions/user/changePasswordSuccess';

function* changePasswordSaga({
  payload,
}: ReturnType<typeof fireChangePassword>): SagaIterator<void> {
  try {
    const response = yield call(fetchSaga, {
      fetcher: changePasswordFetch,
      payload,
    });
    if (response.type === EResponseType.OK) {
      toast.success(getLang('CHANGE_PASSWORD_SUCCESS'));
      yield put(changePasswordSuccess({ success: true }));
      yield put(changePasswordError({ error: null }));
    } else if (response.error.data.new_password_like_old_password) {
      yield put(
        changePasswordError({
          error: EChangePasswordError.SamePassword,
        }),
      );
    } else if (
      response.error.data.old_password ||
      response.error.data.invalid_old_password
    ) {
      yield put(
        changePasswordError({
          error: EChangePasswordError.InvalidOldPassword,
        }),
      );
    } else if (response.error.data.password) {
      yield put(
        changePasswordError({
          error: EChangePasswordError.ShortPassword,
        }),
      );
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default changePasswordSaga;
