import { takeLatest } from 'redux-saga/effects';

import { FIRE_CHECK_PHONE } from 'store/actions/user/fireCheckPhone';
import { FIRE_SEND_CODE } from 'store/actions/user/fireSendCode';
import { FIRE_CHECK_CODE } from 'store/actions/user/fireCheckCode';
import { FIRE_REGISTER } from 'store/actions/user/fireRegister';
import { FIRE_LOGIN } from 'store/actions/user/fireLogin';
import { FIRE_SEND_RESET_CODE } from 'store/actions/user/fireSendResetCode';
import { FIRE_CHECK_RESET_CODE } from 'store/actions/user/fireCheckResetCode';
import { FIRE_RESET_PASSWORD } from 'store/actions/user/fireResetPassword';
import { FIRE_LOGOUT } from 'store/actions/user/fireLogout';
import { FIRE_CHANGE_PASSWORD } from 'store/actions/user/fireChangePassword';

import loginSaga from './loginSaga';
import checkPhoneSaga from './checkPhoneSaga';
import sendCodeSaga from './sendCodeSaga';
import checkCodeSaga from './checkCodeSaga';
import registerSaga from './registerSaga';
import sendResetCodeSaga from './sendResetCodeSaga';
import checkResetCodeSaga from './checkResetCodeSaga';
import resetPasswordSaga from './resetPasswordSaga';
import logoutSaga from './logoutSaga';
import changePasswordSaga from './changePasswordSaga';

export default function* rootUserSaga() {
  yield takeLatest(FIRE_LOGIN, loginSaga);
  yield takeLatest(FIRE_CHECK_PHONE, checkPhoneSaga);
  yield takeLatest(FIRE_SEND_CODE, sendCodeSaga);
  yield takeLatest(FIRE_CHECK_CODE, checkCodeSaga);
  yield takeLatest(FIRE_REGISTER, registerSaga);
  yield takeLatest(FIRE_SEND_RESET_CODE, sendResetCodeSaga);
  yield takeLatest(FIRE_CHECK_RESET_CODE, checkResetCodeSaga);
  yield takeLatest(FIRE_RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(FIRE_LOGOUT, logoutSaga);
  yield takeLatest(FIRE_CHANGE_PASSWORD, changePasswordSaga);
}
