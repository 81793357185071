import { TActionCreator } from 'store/TActionCreator';
import { TSitterProfile } from 'store/reducers/client/TSitterProfile';

export const SUCCESS_LOAD_SITTER_DATA = 'SUCCESS_LOAD_SITTER_DATA';

export const successLoadSitterData: TActionCreator<
  typeof SUCCESS_LOAD_SITTER_DATA,
  { data: TSitterProfile }
> = (payload) => ({
  type: SUCCESS_LOAD_SITTER_DATA,
  payload,
});
