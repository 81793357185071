import { TUserState } from 'store/reducers/user/TUserState';

const checkPhoneHandler = (state: TUserState): TUserState => {
  return {
    ...state,
    checkPhone: null,
    exists: null,
  };
};

export default checkPhoneHandler;
