import { TChatState } from 'store/reducers/chat/TChatState';
import { loadDialogues } from 'store/actions/chat/loadDialogues';

const loadDialoguesHandler = (
  state: TChatState,
  { payload }: ReturnType<typeof loadDialogues>,
): TChatState => ({
  ...state,
  dialogues: payload.more || payload.silent ? state.dialogues : [],
  currentDialogueId:
    payload.more || payload.silent ? state.currentDialogueId : null,
});

export default loadDialoguesHandler;
