import React, { FC } from 'react';
import PawFilled from 'icons/PawFilled';
import cn from 'classnames';

import styles from './LoadingPaws.module.css';

type LoadingPawsProps = {
  className?: string;
};

const LoadingPaws: FC<LoadingPawsProps> = ({ className }) => (
  <div className={cn(styles.paws, className)}>
    <span className={styles.paw1}>
      <PawFilled />
    </span>
    <span className={styles.paw2}>
      <PawFilled />
    </span>
    <span className={styles.paw3}>
      <PawFilled />
    </span>
    <span className={styles.paw4}>
      <PawFilled />
    </span>
    <span className={styles.paw5}>
      <PawFilled />
    </span>
    <span className={styles.paw6}>
      <PawFilled />
    </span>
  </div>
);

export default LoadingPaws;
